import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";

export const FileRedirect = () => {
   const { companySelected, selectedGovernance } = useContext(GovernanceContext);
   const location = useLocation();
   if (location.pathname === `/gobierno-corporativo/${companySelected}/mi-cuenta/archivos`) {
      return <Navigate to={"mis-archivos"} />;
   }
   if (location.pathname === `/gobierno-corporativo/${companySelected}/${selectedGovernance?._id}/documentos`) {
      return <Navigate to={"inicio"} />;
   }

   return <Outlet />;
};
