import { useContext, useEffect, useMemo, useState } from "react";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { getCommitmentsByGovernanceBody } from "../../../lib/gobCorpBEClient";

interface UseGetCommitmentsInfoProps {
   setIsLoading?: Function;
}
export const useGetCommitmentsInfo = (props: UseGetCommitmentsInfoProps) => {
   const { setIsLoading = () => {} } = props;
   const { gobernanceBody, selectedGovernance, setSpecificCalendar, getSpecificCalendar, selectedSection } =
      useContext(GovernanceContext);
   const [totalCommitments, setTotalCommitments] = useState<any[]>([]);
   const [commitmentsByGovernance, setCommitmentsByGovernance] = useState<any[]>([]);

   useEffect(() => {
      setIsLoading(true);
      const fetchData = async () => {
         if (selectedGovernance?._id !== "0") {
            getSpecificCalendar(selectedGovernance?.calendar, false, true);
         } else {
            setSpecificCalendar(null);
         }
         const fetchCommitments = async () => {
            if (selectedGovernance?._id !== "0") {
               const governingBodyCommitment = await getCommitmentsByGovernanceBody(selectedGovernance?._id);
               const currentDate = new Date();
               const currentMonth = currentDate.getMonth();
               let trimesterStart: Date;
               let trimesterEnd: Date;

               if (currentMonth >= 0 && currentMonth <= 2) {
                  trimesterStart = new Date(currentDate.getFullYear(), 0, 1);
                  trimesterEnd = new Date(currentDate.getFullYear(), 3, 0);
               } else if (currentMonth >= 3 && currentMonth <= 5) {
                  trimesterStart = new Date(currentDate.getFullYear(), 3, 1);
                  trimesterEnd = new Date(currentDate.getFullYear(), 6, 0);
               } else if (currentMonth >= 6 && currentMonth <= 8) {
                  trimesterStart = new Date(currentDate.getFullYear(), 6, 1);
                  trimesterEnd = new Date(currentDate.getFullYear(), 9, 0);
               } else {
                  trimesterStart = new Date(currentDate.getFullYear(), 9, 1);
                  trimesterEnd = new Date(currentDate.getFullYear(), 12, 0);
               }
               const filteredCommitments = governingBodyCommitment.filter((commitment) => {
                  const commitmentDate = new Date(commitment.dueDate);
                  return commitmentDate >= trimesterStart && commitmentDate <= trimesterEnd;
               });

               setCommitmentsByGovernance(filteredCommitments);
            }
         };
         await fetchCommitments();
         setIsLoading(false);
      };
      if (gobernanceBody.length > 0) fetchData();
      setIsLoading(false);
   }, [gobernanceBody, selectedGovernance?._id, selectedSection]);

   const commitments = useMemo(() => {
      if (commitmentsByGovernance) {
         const commitmentsObject = {
            pending: commitmentsByGovernance.filter((commitment) => commitment.status === "PENDING"),
            process: commitmentsByGovernance.filter((commitment) => commitment.status === "PROCESS"),
            review: commitmentsByGovernance.filter((commitment) => commitment.status === "REVIEW"),
            complete: commitmentsByGovernance.filter((commitment) => commitment.status === "COMPLETE"),
            expired: commitmentsByGovernance.filter((commitment) => commitment.status === "EXPIRED"),
         };
         setTotalCommitments(commitmentsByGovernance);
         return commitmentsObject;
      }
   }, [commitmentsByGovernance, selectedGovernance, selectedSection]);
   return { commitments, totalCommitments, commitmentsByGovernance };
};
