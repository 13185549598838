import {
   mingcute_user,
   octicon_report,
   mingcute_report_fill,
   material_symbols_scan_delete,
   material_symbols_report_off,
   iconamoon_check_bold,
   round_report_problem,
   baseline_no_account,
   report_warning,
   fluent_building_multiple,
   fluent_building_home,
   ci_triangle_check,
} from "../../assets/icons/DrawerAppScreenIcons";
import dayjs, { Dayjs } from "dayjs";
import React, { useState, useRef, useContext, useMemo, useEffect } from "react";
import { UserContext } from "../../context/userContext";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
   Box,
   Grid,
   Typography,
   Select,
   MenuItem,
   Container,
   TextField,
   CircularProgress,
   Divider,
   TableContainer,
   Table,
   TableHead,
   TableCell,
   TableBody,
   TableRow,
   Paper,
   Tabs,
   Tab,
   Stack,
   IconButton,
   Collapse,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { NotificationBar } from "../../components/notificationsBar";
import { CompaniesCarousel } from "../../components/CompaniesCarousel";
import InputAdornment from "@mui/material/InputAdornment";
import { SnackBarContext } from "../../context/snackBarContext";
import { ComplaintContext } from "../../context/complaintContext";
import GetFilteredComplaints from "../../hooks/complaints/getFilteredComplaints";
import UseCreateCharts from "../../hooks/useCreateCharts";
import VerifyUserSession from "../../hooks/verifyUserSession";
import { CategoriesComparative } from "./CategoriesComparative";
import { createGroupPdf, createCompanyPDF, createBranchPDF } from "../../lib/lecosyBackendClient";
import { getCompanyColors, getGroupColors, getUrlS3 } from "../../lib/usersBEClient";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { LoadingButton } from "../../components/LoadingButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TabPanel } from "../../screens/Mi Lecosy/ClientInfoScreenNew";

interface SuggestionStatus {
   label: string;
   score: number;
   percentage: string;
}
interface StatisticsDonutCardProps {
   titleChart: string;
   titleCategoriesColumn: string;
   newRef: any;
   dataTableDonutComponent: SuggestionStatus[];
   colorValues: string[];
   labelValues: string[];
   scoreValues: number[];
   numberCenterCircle: number;
   heightValue?: string;
}

const StatisticsDonutCard: React.FC<StatisticsDonutCardProps> = ({
   titleChart,
   titleCategoriesColumn,
   newRef,
   dataTableDonutComponent,
   colorValues,
   labelValues,
   scoreValues,
   numberCenterCircle,
   heightValue,
}) => {
   return (
      <Grid
         item
         xs={2}
         maxWidth={"300px"}
         sx={{ border: "1px solid #E5E5EF", boxShadow: 2, px: 2, height: heightValue }}
      >
         <Grid pt={2}>
            <Grid>
               <Typography color={"#2d4357"} variant="h6" fontWeight={700} textAlign={"center"} fontSize={17} py={0}>
                  {titleChart}
               </Typography>
            </Grid>
            <Grid item lg={4} sx={{ py: 2 }} textAlign={"center"}>
               {UseCreateCharts({
                  type: "doughnut",
                  chartRef: newRef,
                  colors: colorValues,
                  labels: labelValues,
                  scores: scoreValues,
                  cutout: 48,
                  percentage: false,
                  totalNumberDonut: numberCenterCircle,
                  key: numberCenterCircle,
               })}
            </Grid>
         </Grid>
         <Grid container spacing={2} pb={"16px"}>
            <Grid item xs={5}>
               <Typography variant="h6" fontWeight={600} fontSize={17}>
                  {titleCategoriesColumn}
               </Typography>
            </Grid>
            <Grid item xs={4} textAlign={"end"}>
               <Typography variant="h6" fontWeight={600} fontSize={17}>
                  Totales
               </Typography>
            </Grid>
            <Grid item xs={2} textAlign={"center"}>
               <Typography variant="h6" fontWeight={600} fontSize={17}>
                  %
               </Typography>
            </Grid>
            {dataTableDonutComponent.map((item, index) => (
               <React.Fragment key={item.label}>
                  <Grid item xs={7} height={1}>
                     <Typography
                        variant="body1"
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           pt: "0 !important",
                        }}
                     >
                        <Box
                           sx={{
                              width: 10,
                              height: 10,
                              bgcolor: colorValues[index],
                              borderRadius: "50%",
                              mr: 1,
                           }}
                        />
                        {item.label}
                     </Typography>
                  </Grid>
                  <Grid item xs={2} textAlign={"end"}>
                     <Typography variant="body1" fontWeight={800}>
                        {item.score}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="body1" textAlign={"end"} fontWeight={600}>
                        {item.percentage}
                     </Typography>
                  </Grid>
               </React.Fragment>
            ))}
         </Grid>
      </Grid>
   );
};

interface InfoBoxProps {
   title: string;
   value: number | string;
   icon: string;
   widthValue?: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({ title, value, icon, widthValue }) => {
   return (
      <Box
         display={"flex"}
         sx={{
            border: 1,
            borderRadius: 2,
            borderColor: "#E0E2E7",
            width: widthValue || "100%",
            alignItems: "center",
         }}
      >
         <Box
            component={"img"}
            sx={{
               mx: 2,
               alignSelf: "center",
               width: 27,
               height: 27,
            }}
            alt="icono de canal de denuncias"
            src={icon}
         />
         <Box py={1.4}>
            <Typography color={"#667085"}>{title}</Typography>
            <Typography color={"black"} fontSize={17} fontWeight={600}>
               {value}
            </Typography>
         </Box>
      </Box>
   );
};

interface StatisticsInfoProps {
   newRef: React.RefObject<HTMLDivElement>;
   typeChartRef: React.RefObject<HTMLDivElement>;
   calificationChartRef: React.RefObject<HTMLDivElement>;
   companyInfo: any;
   selectedCompany: any;
   startdate: dayjs.Dayjs; // Parámetro startDate como Dayjs
   enddate: dayjs.Dayjs; // Parámetro endDate como Dayjs
}

const StatisticsInfoBox: React.FC<StatisticsInfoProps> = ({
   newRef,
   typeChartRef,
   calificationChartRef,
   companyInfo,
   selectedCompany,
   startdate, // Parámetro de fecha de inicio
   enddate, // Parámetro de fecha de fin
}) => {
   const today = dayjs(new Date());
   const [selectedCompany2, setSelectedCompany2] = useState<string>("");
   const [selectedBranch2, setSelectedBranch2] = useState<string>("");
   const [isLoadingReport, setIsLoadingReport] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);

   useEffect(() => {
      if (companyInfo.length === 1) {
         setSelectedCompany2(companyInfo[0].companyId);
         setSelectedBranch2("");
      }
   }, [companyInfo]);

   // Función auxiliar para obtener la fecha a partir de "createdAt" o "date"
   const getComplaintDate = (complaint) => {
      return complaint.createdAt ? dayjs(complaint.createdAt) : dayjs(complaint.date);
   };

   // Filtra companyInfo basado en las fechas
   const filterCompanyInfoByDate = (companyInfo, startDate, finishDate) => {
      return companyInfo
         .filter((company) => {
            const serviceIdToCheck = "64e784dd978b71bd4f011ee3";

            // Verificar si el servicio está habilitado
            const hasServiceEnabled = company.companyDetails?.servicesDetails?.some(
               (service: any) => service.serviceId === serviceIdToCheck && !service.disable
            );

            return hasServiceEnabled;
         })
         .map((info) => ({
            ...info,
            companyComplaints: info.companyComplaints.filter((complaint) => {
               const complaintDate = getComplaintDate(complaint);
               return complaintDate.isAfter(startDate) && complaintDate.isBefore(finishDate);
            }),
            branches: info.branches.map((branch) => ({
               ...branch,
               branchComplaints: branch.branchComplaints.filter((complaint) => {
                  const complaintDate = getComplaintDate(complaint);
                  return complaintDate.isAfter(startDate) && complaintDate.isBefore(finishDate);
               }),
            })),
            complaints: info.complaints.filter((complaint) => {
               const complaintDate = getComplaintDate(complaint);
               return complaintDate.isAfter(startDate) && complaintDate.isBefore(finishDate);
            }),
         }));
   };

   // Filtra companyInfo por fechas y luego selecciona la compañía correcta
   const filteredCompanyInfo = filterCompanyInfoByDate(companyInfo, startdate, enddate);

   const currentCompanyInfo = filteredCompanyInfo.find((info) => info.companyId === selectedCompany._id);

   const updatedCompanyInfo = currentCompanyInfo;

   const noComplaints =
      updatedCompanyInfo.companyComplaints.length === 0 &&
      updatedCompanyInfo.branches.every((branch) => branch.branchComplaints.length === 0) &&
      updatedCompanyInfo.complaints.length === 0;

   // Function to get the logo URL from S3
   const getDataS3 = async (companyId: string): Promise<string> => {
      // Retrieve the URL from S3
      const logoUrl: string = await getUrlS3("images-lecosy", { folder: companyId }, "logo.png");
      return logoUrl; // Return the logo URL
   };
   // Function to get the logo URL for a group from S3
   const getGroupLogoFromS3 = async (groupId: string): Promise<string> => {
      // Retrieve the URL from S3 for the group logo
      const logoUrl: string = await getUrlS3("images-lecosy", { folder: `group/${groupId}` }, "logo.png");
      return logoUrl; // Return the logo URL
   };

   const complaintsDivisionCompanyComplaints = () => {
      return updatedCompanyInfo?.companyComplaints?.reduce(
         (acc, complaint) => {
            const temp = acc;
            const { type } = complaint;

            if (!type || type === "denuncia") temp["denuncias"].push(complaint);
            else if (type === "queja") temp["quejas"].push(complaint);
            else if (type === "sugerencia") temp["sugerencias"].push(complaint);
            return temp;
         },
         { denuncias: [], quejas: [], sugerencias: [] }
      );
   };

   const complaintsDivisionbranch = (index) => {
      return updatedCompanyInfo?.branches[index]?.branchComplaints?.reduce(
         (object, complaint) => {
            const temp = object;
            const { type } = complaint;

            if (!type || type === "denuncia") temp["denuncias"].push(complaint);
            else if (type === "queja") temp["quejas"].push(complaint);
            else if (type === "sugerencia") temp["sugerencias"].push(complaint);
            return temp;
         },
         { denuncias: [], quejas: [], sugerencias: [] }
      );
   };

   const sumComplaints = () => {
      let totalDenuncias = 0;
      let totalQuejas = 0;
      let totalSugerencias = 0;

      const companyComplaints = complaintsDivisionCompanyComplaints();
      totalDenuncias += companyComplaints.denuncias.length;
      totalQuejas += companyComplaints.quejas.length;
      totalSugerencias += companyComplaints.sugerencias.length;

      updatedCompanyInfo?.branches.forEach((_, index) => {
         const branchComplaints = complaintsDivisionbranch(index);
         totalDenuncias += branchComplaints.denuncias.length;
         totalQuejas += branchComplaints.quejas.length;
         totalSugerencias += branchComplaints.sugerencias.length;
      });

      return { totalDenuncias, totalQuejas, totalSugerencias };
   };

   const { totalDenuncias, totalQuejas, totalSugerencias } = sumComplaints();

   const [isContentVisibleContaint, setIsContentVisibleContaint] = useState(totalDenuncias !== 0);
   const [isContentVisibleSuggestion, setIsContentVisibleSuggestion] = useState(totalSugerencias !== 0);
   const [isContentVisibleNagging, setIsContentVisibleNagging] = useState(totalQuejas !== 0);

   useEffect(() => {
      setIsContentVisibleContaint(totalDenuncias !== 0);
      setIsContentVisibleSuggestion(totalSugerencias !== 0);
      setIsContentVisibleNagging(totalQuejas !== 0);
   }, [selectedCompany, totalDenuncias, totalQuejas, totalSugerencias]);

   function createData(sucursal, denuncias, quejas, sugerencias) {
      return { sucursal, denuncias, quejas, sugerencias };
   }

   const rowsTableCompany = complaintsDivisionCompanyComplaints();

   const rowsTableBranches = updatedCompanyInfo?.branches.map((branch, index) => {
      return createData(
         branch.branchDetails.name,
         complaintsDivisionbranch(index)?.denuncias.length,
         complaintsDivisionbranch(index)?.quejas.length,
         complaintsDivisionbranch(index).sugerencias.length
      );
   });

   const complaintsChartByCompanyComplaint = {
      scores: [[], []],
      companyName: updatedCompanyInfo.person_details.comercialName,
      scoreByCompany: [[0], [0]],
      labels: [],
      admissible: 0,
      inadmissible: 0,
      anonymousComplaints: 0,
      nonAnonymousComplaints: 0,
      statusComplaints: { enviada: 0, "en proceso": 0, "en valoración": 0, resuelto: 0, cerrada: 0 },
      relatedComplaints: { Proveedor: 0, Empleado: 0, Cliente: 0, Usuario: 0, Exempleado: 0, Otros: 0 },
   };

   updatedCompanyInfo?.branches.forEach((branch) => {
      branch.branchComplaints.forEach((complaint) => {
         if (complaint.company) {
            if (complaint.status !== "improcedente" && complaint.type === "queja" && complaint.status !== "enviada") {
               complaintsChartByCompanyNagging.scoreByCompany[0][0]++; // Incrementar el valor dentro del array
            } else if (complaint.status === "improcedente" && complaint.type === "queja") {
               complaintsChartByCompanyNagging.scoreByCompany[1][0]++;
            }
         }

         if (complaint.complaintType === "anonima" && (complaint.type === "denuncia" || !complaint.type)) {
            complaintsChartByCompanyComplaint.anonymousComplaints++;
         } else if (complaint.complaintType === "no anonima" && (complaint.type === "denuncia" || !complaint.type)) {
            complaintsChartByCompanyComplaint.nonAnonymousComplaints++;
         }

         if ((complaint.type === "denuncia" || !complaint.type) && complaint.status !== "improcedente") {
            complaintsChartByCompanyComplaint.statusComplaints[complaint.status]++;
         }

         if (complaint.type === "denuncia" || !complaint.type) {
            complaintsChartByCompanyComplaint.relatedComplaints[complaint.complainerType]++;
         }

         if (!complaintsChartByCompanyComplaint.labels.includes(branch.branchDetails.name)) {
            complaintsChartByCompanyComplaint.labels.push(branch.branchDetails.name);
         }
      });
   });

   updatedCompanyInfo?.companyComplaints.forEach((complaint) => {
      if (complaint.type && complaint.type !== "denuncia") return;

      if (
         complaint.status !== "improcedente" &&
         (complaint.type === "denuncia" || !complaint.type) &&
         complaint.status !== "enviada"
      ) {
         complaintsChartByCompanyComplaint.scoreByCompany[0][0]++; // Incrementar el valor dentro del array
      } else if (
         complaint.status === "improcedente" &&
         (complaint.type === "denuncia" || !complaint.type) &&
         complaint.status !== "enviada"
      ) {
         complaintsChartByCompanyComplaint.scoreByCompany[1][0]++; // Incrementar el valor dentro del array
      }

      if (
         complaint.status !== "enviada" &&
         (complaint.type === "denuncia" || !complaint.type) &&
         complaint.status !== "improcedente"
      ) {
         complaintsChartByCompanyComplaint.admissible++;
      } else if (
         complaint.status === "improcedente" &&
         (complaint.type === "denuncia" || !complaint.type) &&
         complaint.status !== "enviada"
      ) {
         complaintsChartByCompanyComplaint.inadmissible++;
      }

      if (complaint.complaintType === "anonima" && (complaint.type === "denuncia" || !complaint.type)) {
         complaintsChartByCompanyComplaint.anonymousComplaints++;
      } else if (complaint.complaintType === "no anonima" && (complaint.type === "denuncia" || !complaint.type)) {
         complaintsChartByCompanyComplaint.nonAnonymousComplaints++;
      }

      if ((complaint.type === "denuncia" || !complaint.type) && complaint.status !== "improcedente") {
         complaintsChartByCompanyComplaint.statusComplaints[complaint.status]++;
      }

      if (complaint.type === "denuncia" || !complaint.type) {
         complaintsChartByCompanyComplaint.relatedComplaints[complaint.complainerType]++;
      }
   });

   updatedCompanyInfo?.branches.forEach((branch) => {
      let admissible = 0;
      let inadmissible = 0;

      branch.branchComplaints.forEach((complaint) => {
         if (
            complaint.status !== "improcedente" &&
            (complaint.type === "denuncia" || !complaint.type) &&
            complaint.status !== "enviada"
         ) {
            admissible++;
         } else if (
            complaint.status === "improcedente" &&
            (complaint.type === "denuncia" || !complaint.type) &&
            complaint.status !== "enviada"
         ) {
            inadmissible++;
         }
      });

      complaintsChartByCompanyComplaint.scores[0].push(admissible);
      complaintsChartByCompanyComplaint.scores[1].push(inadmissible);
   });

   // Eliminación de manejo por años
   let totalStatusComplaints = 0;
   const statusComplaint = complaintsChartByCompanyComplaint?.statusComplaints;

   if (statusComplaint) {
      totalStatusComplaints = Object.keys(complaintsChartByCompanyComplaint.statusComplaints).reduce((acc, key) => {
         acc += complaintsChartByCompanyComplaint.statusComplaints[key];
         return acc;
      }, 0);
   }

   const getpercentage = (value) => (value * 100 === 100 ? value * 100 : (value * 100).toFixed(1));

   let totalBrachesAdmissibleComplaint = complaintsChartByCompanyComplaint.scores[0].reduce((acc, num) => {
      return acc + num;
   }, 0);

   let totalBrachesInadmissibleComplaint = complaintsChartByCompanyComplaint.scores[1].reduce((acc, num) => {
      return acc + num;
   }, 0);

   const generateStatusData = (complaintsChart, totalStatus) => [
      {
         label: "Interpuestas",
         score: complaintsChart.statusComplaints.enviada || 0,
         percentage: `${getpercentage(
            totalStatus === 0 ? 0 : (complaintsChart.statusComplaints.enviada || 0) / totalStatus
         )}%`,
      },
      {
         label: "En investigación",
         score: complaintsChart.statusComplaints["en proceso"] || 0,
         percentage: `${getpercentage(
            totalStatus === 0 ? 0 : (complaintsChart.statusComplaints["en proceso"] || 0) / totalStatus
         )}%`,
      },
      {
         label: "En resolución",
         score: complaintsChart.statusComplaints["en valoración"] || 0,
         percentage: `${getpercentage(
            totalStatus === 0 ? 0 : (complaintsChart.statusComplaints["en valoración"] || 0) / totalStatus
         )}%`,
      },
      {
         label: "Por finalizar",
         score: complaintsChart.statusComplaints.resuelto || 0,
         percentage: `${getpercentage(
            totalStatus === 0 ? 0 : (complaintsChart.statusComplaints.resuelto || 0) / totalStatus
         )}%`,
      },
      {
         label: "Finalizadas",
         score: complaintsChart.statusComplaints.cerrada || 0,
         percentage: `${getpercentage(
            totalStatus === 0 ? 0 : (complaintsChart.statusComplaints.cerrada || 0) / totalStatus
         )}%`,
      },
   ];

   const dataComplaintsStatus = generateStatusData(complaintsChartByCompanyComplaint, totalStatusComplaints);

   let totalReleatedComplaints = 0;
   const relatedComplaintsStatus = complaintsChartByCompanyComplaint?.relatedComplaints;

   if (relatedComplaintsStatus) {
      totalReleatedComplaints = Object.keys(relatedComplaintsStatus).reduce((acc, key) => {
         const value = relatedComplaintsStatus[key];
         if (typeof value === "number" && !isNaN(value)) {
            acc += value;
         }
         return acc;
      }, 0);
   }

   const generateDataInterposition = (complaintsChart, totalRelated) => [
      {
         label: "Proveedor",
         score: complaintsChart.relatedComplaints.Proveedor,
         percentage: `${getpercentage(
            totalRelated === 0 ? 0 : complaintsChart.relatedComplaints.Proveedor / totalRelated
         )}%`,
      },
      {
         label: "Empleado",
         score: complaintsChart.relatedComplaints.Empleado,
         percentage: `${getpercentage(
            totalRelated === 0 ? 0 : complaintsChart.relatedComplaints.Empleado / totalRelated
         )}%`,
      },
      {
         label: "Cliente",
         score: complaintsChart.relatedComplaints.Cliente,
         percentage: `${getpercentage(
            totalRelated === 0 ? 0 : complaintsChart.relatedComplaints.Cliente / totalRelated
         )}%`,
      },
      {
         label: "Usuario",
         score: complaintsChart.relatedComplaints.Usuario,
         percentage: `${getpercentage(
            totalRelated === 0 ? 0 : complaintsChart.relatedComplaints.Usuario / totalRelated
         )}%`,
      },
      {
         label: "Exempleado",
         score: complaintsChart.relatedComplaints.Exempleado,
         percentage: `${getpercentage(
            totalRelated === 0 ? 0 : complaintsChart.relatedComplaints.Exempleado / totalRelated
         )}%`,
      },
      {
         label: "Otros",
         score: complaintsChart.relatedComplaints.Otros,
         percentage: `${getpercentage(
            totalRelated === 0 ? 0 : complaintsChart.relatedComplaints.Otros / totalRelated
         )}%`,
      },
   ];

   const dataInterpositionComplaint = generateDataInterposition(
      complaintsChartByCompanyComplaint,
      totalReleatedComplaints
   );

   const filterDenunciasByCategory = (branches, companyComplaints) => {
      const initialCategories = {
         "Acoso sexual": 0,
         "Represalias a denuncias": 0,
         "Lavado de dinero": 0,
         Fraude: 0,
         "Incumplimiento a leyes o regulación de autoridades": 0,
         Discriminación: 0,
         Robos: 0,
         Otros: 0,
      };

      const allCategoriesCount = { ...initialCategories };

      companyComplaints.forEach((complaint) => {
         if (!complaint.type || complaint.type === "denuncia") {
            const category = complaint.category;
            if (allCategoriesCount.hasOwnProperty(category)) {
               allCategoriesCount[category]++;
            } else {
               allCategoriesCount["Otros"]++;
            }
         }
      });

      const branchesCategoriesCount = branches.map((branch) => {
         const branchCategoriesCount = { ...initialCategories };
         const { branchComplaints } = branch;

         branchComplaints.forEach((complaint) => {
            if (!complaint.type || complaint.type === "denuncia") {
               const category = complaint.category;
               if (branchCategoriesCount.hasOwnProperty(category)) {
                  branchCategoriesCount[category]++;
                  allCategoriesCount[category]++;
               } else {
                  branchCategoriesCount["Otros"]++;
                  allCategoriesCount["Otros"]++;
               }
            }
         });

         return {
            branchId: branch.branchId,
            branchName: branch.branchDetails.name,
            categories: branchCategoriesCount,
         };
      });

      return { allCategoriesCount, branchesCategoriesCount };
   };

   const { allCategoriesCount, branchesCategoriesCount } = filterDenunciasByCategory(
      updatedCompanyInfo?.branches || [],
      updatedCompanyInfo?.companyComplaints || []
   );

   const branches = updatedCompanyInfo?.branches || [];
   const [selectedBranch, setSelectedBranch] = useState("Todas las categorias");

   useEffect(() => {
      // Cuando selectedCompany cambie, resetear selectedBranch
      setSelectedBranch("Todas las categorias");
      setSelectedBranchSuggestion("Todas las categorias");
   }, [selectedCompany]);

   const handleBranchChange = (event) => {
      setSelectedBranch(event.target.value);
   };

   let categoriesData;

   if (selectedBranch === "Todas las categorias") {
      categoriesData = allCategoriesCount;
   } else {
      const selectedBranchData = branchesCategoriesCount.find((branch) => branch.branchId === selectedBranch);
      // Verificar que selectedBranchData no sea undefined
      categoriesData = selectedBranchData ? selectedBranchData.categories : [];
   }

   let allCategoriesNum = Object.keys(categoriesData).reduce((acc, key) => {
      return acc + categoriesData[key];
   }, 0);

   if (allCategoriesNum === 0) allCategoriesNum = 1;

   const complaintsCategories = [
      { name: "Acoso sexual", data: [categoriesData["Acoso sexual"] / allCategoriesNum] },
      { name: "Represalias a denuncias", data: [categoriesData["Represalias a denuncias"] / allCategoriesNum] },
      { name: "Lavado de dinero", data: [categoriesData["Lavado de dinero"] / allCategoriesNum] },
      { name: "Fraude", data: [categoriesData["Fraude"] / allCategoriesNum] },
      {
         name: "Incumplimiento de leyes o regulación de autoridades",
         data: [categoriesData["Incumplimiento a leyes o regulación de autoridades"] / allCategoriesNum],
      },
      { name: "Discriminación", data: [categoriesData["Discriminación"] / allCategoriesNum] },
      { name: "Robos", data: [categoriesData["Robos"] / allCategoriesNum] },
      { name: "Otros", data: [categoriesData["Otros"] / allCategoriesNum] },
   ];

   const sumNestedArrays = (nestedArrays) => {
      return nestedArrays.reduce((totalSum, currentArray) => {
         const arraySum = currentArray.reduce((sum, num) => sum + num, 0);
         return totalSum + arraySum;
      }, 0);
   };

   const procedenteInprocedenteComplainBranches = sumNestedArrays(complaintsChartByCompanyComplaint.scores);

   // <------------------------------------------------------------------ Interposición de quejas --------------------------------------------------------------------->
   const complaintsChartByCompanyNagging = {
      scores: [[], []],
      companyName: updatedCompanyInfo.person_details.comercialName,
      scoreByCompany: [[0], [0]],
      labels: [],
      admissible: 0,
      inadmissible: 0,
      anonymousComplaints: 0,
      nonAnonymousComplaints: 0,
      statusComplaints: { enviada: 0, "en proceso": 0, "en valoración": 0, resuelto: 0, cerrada: 0 },
      relatedComplaints: { Proveedor: 0, Empleado: 0, Cliente: 0, Usuario: 0, Exempleado: 0, Otros: 0 },
   };

   const processComplaints = (complaints, complaintsChartByCompanyNagging) => {
      complaints.forEach((complaint) => {
         if (complaint.type !== "queja") return;

         if (complaint.company) {
            if (complaint.status !== "improcedente" && complaint.type === "queja" && complaint.status !== "enviada") {
               complaintsChartByCompanyNagging.scoreByCompany[0][0]++; // Incrementar el valor dentro del array
            } else if (complaint.status === "improcedente" && complaint.type === "queja") {
               complaintsChartByCompanyNagging.scoreByCompany[1][0]++;
            }
         }

         if (
            complaint.status &&
            complaint.status !== "enviada" &&
            complaint.status !== "improcedente" &&
            complaint.type === "queja"
         ) {
            complaintsChartByCompanyNagging.admissible++;
         } else if (
            complaint.status === "improcedente" &&
            complaint.type === "queja" &&
            complaint.status !== "enviada"
         ) {
            complaintsChartByCompanyNagging.inadmissible++;
         }

         if (complaint.complaintType === "anonima" && complaint.type === "queja") {
            complaintsChartByCompanyNagging.anonymousComplaints++;
         } else if (complaint.complaintType === "no anonima" && complaint.type === "queja") {
            complaintsChartByCompanyNagging.nonAnonymousComplaints++;
         }

         if (
            complaint.type === "queja" &&
            complaint.status !== "improcedente" &&
            complaintsChartByCompanyNagging.statusComplaints[complaint.status] !== undefined
         ) {
            complaintsChartByCompanyNagging.statusComplaints[complaint.status]++;
         }

         if (
            complaint.type === "queja" &&
            complaintsChartByCompanyNagging.relatedComplaints[complaint.complainerType] !== undefined
         ) {
            complaintsChartByCompanyNagging.relatedComplaints[complaint.complainerType]++;
         }

         if (
            complaint.branchDetails &&
            !complaintsChartByCompanyNagging.labels.includes(complaint.branchDetails.name)
         ) {
            complaintsChartByCompanyNagging.labels.push(complaint.branchDetails.name);
         }
      });
   };

   updatedCompanyInfo?.branches.forEach((branch) => {
      processComplaints(branch.branchComplaints, complaintsChartByCompanyNagging);
   });

   updatedCompanyInfo?.companyComplaints.forEach((complaint) => {
      processComplaints([complaint], complaintsChartByCompanyNagging);
   });

   updatedCompanyInfo?.branches.forEach((branch) => {
      let admissible = 0;
      let inadmissible = 0;
      branch.branchComplaints.forEach((complaint) => {
         if (complaint.status !== "improcedente" && complaint.type === "queja" && complaint.status !== "enviada") {
            admissible++;
         } else if (
            complaint.status === "improcedente" &&
            complaint.type === "queja" &&
            complaint.status !== "enviada"
         ) {
            inadmissible++;
         }
      });
      complaintsChartByCompanyNagging.scores[0].push(admissible);
      complaintsChartByCompanyNagging.scores[1].push(inadmissible);
   });

   let totalBrachesAdmissibleNagging = complaintsChartByCompanyNagging.scores[0].reduce((acc, num) => {
      return acc + num;
   }, 0);

   let totalBrachesInadmissibleNagging = complaintsChartByCompanyNagging.scores[1].reduce((acc, num) => {
      return acc + num;
   }, 0);

   const procedenteInprocedenteNaggingBranches = sumNestedArrays(complaintsChartByCompanyNagging.scores);

   let totalStatusNagging = 0;
   const statusComplaints = complaintsChartByCompanyNagging?.statusComplaints;

   if (statusComplaints) {
      totalStatusNagging = Object.keys(statusComplaints).reduce((acc, key) => {
         acc += statusComplaints[key];
         return acc;
      }, 0);
   }

   const dataNaggingStatus = generateStatusData(complaintsChartByCompanyNagging, totalStatusNagging);

   let totalReleatedNagging = 0;
   const relatedComplaints = complaintsChartByCompanyNagging?.relatedComplaints;

   if (relatedComplaints) {
      totalReleatedNagging = Object.keys(relatedComplaints).reduce((acc, key) => {
         acc += relatedComplaints[key];
         return acc;
      }, 0);
   }

   const dataInterpositionNagging = generateDataInterposition(complaintsChartByCompanyNagging, totalReleatedNagging);

   let totalAnonimusOrNonAnonimusNagging =
      complaintsChartByCompanyNagging.anonymousComplaints + complaintsChartByCompanyNagging.nonAnonymousComplaints;

   const generateNonAndAnonimusDataNagging = [
      {
         label: "Anónimas",
         score: complaintsChartByCompanyNagging.anonymousComplaints || 0,
         percentage: `${getpercentage(
            totalAnonimusOrNonAnonimusNagging === 0
               ? 0
               : (complaintsChartByCompanyNagging.anonymousComplaints || 0) / totalAnonimusOrNonAnonimusNagging
         )}%`,
      },
      {
         label: "No anónimas",
         score: complaintsChartByCompanyNagging.nonAnonymousComplaints || 0,
         percentage: `${getpercentage(
            totalAnonimusOrNonAnonimusNagging === 0
               ? 0
               : (complaintsChartByCompanyNagging.nonAnonymousComplaints || 0) / totalAnonimusOrNonAnonimusNagging
         )}%`,
      },
   ];

   // <------------------------------------------------------------------ Interposición de sugerencias --------------------------------------------------------------------->

   const complaintsChartByCompanySuggestions = {
      scores: [[], []],
      companyName: updatedCompanyInfo.person_details.comercialName,
      scoreByCompany: [[0], [0]],
      labels: [],
      admissible: 0,
      inadmissible: 0,
      anonymousComplaints: 0,
      nonAnonymousComplaints: 0,
      statusComplaints: { enviada: 0, "en proceso": 0, "en valoración": 0, resuelto: 0, cerrada: 0 },
      relatedComplaints: { Proveedor: 0, Empleado: 0, Cliente: 0, Usuario: 0, Exempleado: 0, Otros: 0 },
   };

   const processSuggestions = (complaints, complaintsChartByCompanySuggestions) => {
      complaints.forEach((complaint) => {
         if (complaint.type !== "sugerencia") return;

         if (complaint.company) {
            if (
               complaint.status !== "improcedente" &&
               complaint.type === "sugerencia" &&
               complaint.status !== "enviada"
            ) {
               complaintsChartByCompanySuggestions.scoreByCompany[0][0]++; // Incrementar el valor dentro del array
            } else if (complaint.status === "improcedente" && complaint.type === "sugerencia") {
               complaintsChartByCompanySuggestions.scoreByCompany[1][0]++;
            }
         }

         if (
            complaint.status &&
            complaint.status !== "enviada" &&
            complaint.status !== "improcedente" &&
            complaint.type === "sugerencia"
         ) {
            complaintsChartByCompanySuggestions.admissible++;
         } else if (
            complaint.status === "improcedente" &&
            complaint.type === "sugerencia" &&
            complaint.status !== "enviada"
         ) {
            complaintsChartByCompanySuggestions.inadmissible++;
         }

         if (complaint.complaintType === "anonima" && complaint.type === "sugerencia") {
            complaintsChartByCompanySuggestions.anonymousComplaints++;
         } else if (complaint.complaintType === "no anonima" && complaint.type === "sugerencia") {
            complaintsChartByCompanySuggestions.nonAnonymousComplaints++;
         }

         if (
            complaint.type === "sugerencia" &&
            complaint.status !== "improcedente" &&
            complaintsChartByCompanySuggestions.statusComplaints[complaint.status] !== undefined
         ) {
            complaintsChartByCompanySuggestions.statusComplaints[complaint.status]++;
         }

         if (
            complaint.type === "sugerencia" &&
            complaintsChartByCompanySuggestions.relatedComplaints[complaint.complainerType] !== undefined
         ) {
            complaintsChartByCompanySuggestions.relatedComplaints[complaint.complainerType]++;
         }
      });
   };

   updatedCompanyInfo?.branches.forEach((branch) => {
      processSuggestions(branch.branchComplaints, complaintsChartByCompanySuggestions);

      const branchName = branch.branchDetails.name;

      if (!complaintsChartByCompanySuggestions.labels.includes(branchName)) {
         complaintsChartByCompanySuggestions.labels.push(branchName);
      }
   });

   updatedCompanyInfo?.companyComplaints.forEach((complaint) => {
      processSuggestions([complaint], complaintsChartByCompanySuggestions);
   });

   updatedCompanyInfo?.branches.forEach((branch) => {
      let admissible = 0;
      let inadmissible = 0;
      branch.branchComplaints.forEach((complaint) => {
         if (complaint.status !== "improcedente" && complaint.type === "sugerencia" && complaint.status !== "enviada") {
            admissible++;
         } else if (complaint.status === "improcedente" && complaint.type === "sugerencia") {
            inadmissible++;
         }
      });
      complaintsChartByCompanySuggestions.scores[0].push(admissible);
      complaintsChartByCompanySuggestions.scores[1].push(inadmissible);
   });

   // Total status suggestions
   let totalStatusSuggestions = 0;
   const statusSuggestions = complaintsChartByCompanySuggestions?.statusComplaints;

   if (statusSuggestions) {
      totalStatusSuggestions = Object.keys(statusSuggestions).reduce((acc, key) => {
         acc += statusSuggestions[key];
         return acc;
      }, 0);
   }

   const dataSuggestionsStatus = generateStatusData(complaintsChartByCompanySuggestions, totalStatusSuggestions);

   // Total related suggestions
   let totalReleatedSuggestions = 0;
   const relatedSuggestions = complaintsChartByCompanySuggestions?.relatedComplaints;

   if (relatedSuggestions) {
      totalReleatedSuggestions = Object.keys(relatedSuggestions).reduce((acc, key) => {
         acc += relatedSuggestions[key];
         return acc;
      }, 0);
   }

   const dataInterpositionSuggestions = generateDataInterposition(
      complaintsChartByCompanySuggestions,
      totalReleatedSuggestions
   );

   // Anonymous and non-anonymous suggestions
   let totalAnonimusOrNonAnonimusSuggestions =
      complaintsChartByCompanySuggestions.anonymousComplaints +
      complaintsChartByCompanySuggestions.nonAnonymousComplaints;

   const generateNonAndAnonimusDataSuggestion = [
      {
         label: "Anónimas",
         score: complaintsChartByCompanySuggestions.anonymousComplaints || 0,
         percentage: `${getpercentage(
            totalAnonimusOrNonAnonimusSuggestions === 0
               ? 0
               : (complaintsChartByCompanySuggestions.anonymousComplaints || 0) / totalAnonimusOrNonAnonimusSuggestions
         )}%`,
      },
      {
         label: "No anónimas",
         score: complaintsChartByCompanySuggestions.nonAnonymousComplaints || 0,
         percentage: `${getpercentage(
            totalAnonimusOrNonAnonimusSuggestions === 0
               ? 0
               : (complaintsChartByCompanySuggestions.nonAnonymousComplaints || 0) /
                    totalAnonimusOrNonAnonimusSuggestions
         )}%`,
      },
   ];

   const complaintsSuggestions = updatedCompanyInfo.complaints?.filter((complaint) => {
      return complaint.type === "sugerencia" && complaint;
   });

   const allSuggestions = complaintsSuggestions?.reduce((acc, complaint) => {
      acc[complaint.category] = (acc[complaint.category] || 0) + 1;
      return acc;
   }, {});

   const allCategories = allSuggestions
      ? Object.keys(allSuggestions)?.reduce((acc, category) => {
           acc[category] = 0;
           return acc;
        }, {})
      : {};

   const branchesSuggestions = updatedCompanyInfo.branches?.reduce((acc, branch) => {
      const categoriesCount = { ...allCategories };

      const branchSuggestions = complaintsSuggestions?.filter(
         (complaint) => complaint.companyBranch === branch.branchId
      );

      branchSuggestions?.forEach((complaint) => {
         categoriesCount[complaint.category] = (categoriesCount[complaint.category] || 0) + 1;
      });

      acc[branch.branchId] = {
         idBranch: branch.branchId,
         branchName: branch.branchDetails.name,
         categories: categoriesCount,
      };

      return acc;
   }, {});

   const procedenteInprocedenteSuggestionBranches = sumNestedArrays(complaintsChartByCompanySuggestions.scores);

   const [selectedBranchSuggestion, setSelectedBranchSuggestion] = useState("Todas las categorias");

   const handleBranchChangeSuggestions = (event) => {
      setSelectedBranchSuggestion(event.target.value);
   };

   let categoriesDataSuggestions;

   if (selectedBranchSuggestion === "Todas las categorias") {
      categoriesDataSuggestions = allSuggestions;
   } else {
      const selectedBranchData = Object.keys(branchesSuggestions).find((key) => key === selectedBranchSuggestion);
      if (selectedBranchData) {
         categoriesDataSuggestions = branchesSuggestions[selectedBranchData].categories;
      }
   }

   let allCategoriesSuggestionNum = 0;

   if (categoriesDataSuggestions) {
      allCategoriesSuggestionNum = Object.keys(categoriesDataSuggestions).reduce((acc, key) => {
         acc += categoriesDataSuggestions[key];
         return acc;
      }, 0);
   }

   if (allCategoriesSuggestionNum === 0) allCategoriesSuggestionNum = 1;

   const complaintsCategoriesSuggestions = categoriesDataSuggestions
      ? Object.keys(categoriesDataSuggestions).map((key) => {
           return { name: key, data: [categoriesDataSuggestions[key] / allCategoriesSuggestionNum] };
        })
      : [];

   // Validar si `selectedCompany` está definido
   if (!selectedCompany) {
      return <div>No hay compañía seleccionada.</div>;
   }
   // Define la interfaz para companyInfoData
   // Define la interfaz para los colores e información de la compañía
   interface CompanyInfo {
      businessName: string;
      primaryColor: string;
      secondaryColor: string;
   }

   // Define la interfaz para una queja
   interface Complaint {
      status: string;
      type: string;
      complaintType: string;
      category?: string;
      complainerType?: string;
      companyBranch?: string;
   }

   // Define la interfaz para una sucursal
   interface Branch {
      branchId: string;
      branchDetails: {
         name: string;
      };
   }

   // Define la interfaz para los datos de la empresa
   interface CompanyData {
      companyId: string;
      group?: string;
      complaints: Complaint[];
      branches: Branch[];
   }
   const createReport = async (reportType) => {
      try {
         setIsLoadingReport(true);

         let logoUrl, primaryColor, secondaryColor, name, data, response;

         const startDate = startdate?.toDate() ? new Date(startdate.toDate()) : null;
         const finishDate = enddate?.toDate() ? new Date(enddate.toDate()) : null;

         // En todos los casos usamos filteredCompanyInfo como data
         data = filteredCompanyInfo;

         if (reportType === "group") {
            // Obtener el ID del grupo
            const groupID = data[0]?.group;

            // Obtener el logo y los colores del grupo
            logoUrl = await getGroupLogoFromS3(groupID);
            const groupInfo = await getGroupColors(groupID);
            name = groupInfo.groupName;
            primaryColor = groupInfo.primaryColor;
            secondaryColor = groupInfo.secondaryColor;

            // Filtrar solo los datos relevantes para enviar al backend
            const filteredGroupData = data.map((company) => ({
               businessName: company?.person_details?.businessName || "Nombre desconocido",
               complaints:
                  company?.complaints?.map((complaint) => ({
                     type: complaint.type,
                     category: complaint.category,
                     status: complaint.status,
                     complainerType: complaint.complainerType,
                     clasification: complaint.newClasification || complaint.clasification || "Sin clasificación", // Agregar clasificación
                  })) || [],
            }));

            // Preparar el payload para el reporte de grupo
            const payload = {
               startDate,
               finishDate,
               monthValue: null,
               logoUrl,
               primaryColor,
               secondaryColor,
               groupName: name,
               groupData: filteredGroupData, // Solo enviamos los datos filtrados
            };

            // Generar el PDF de grupo
            response = await createGroupPdf(groupID, payload);
         } else if (reportType === "company") {
            if (!selectedCompany2) {
               showSnackBar("Debe seleccionar una empresa.", true);
               setIsLoadingReport(false);
               return;
            }

            const companyId = selectedCompany2;
            try {
               // Obtener el logo de la compañía
               const logoUrl: string = (await getDataS3(companyId)) || ""; // Asigna una cadena vacía si no se encuentra el logo

               // Obtener los colores y el nombre de la compañía
               const fetchedCompanyInfo = await getCompanyColors(companyId);
               const companyInfoData: CompanyInfo = fetchedCompanyInfo || {
                  businessName: "Desconocido",
                  primaryColor: "#000000",
                  secondaryColor: "#FFFFFF",
               };

               primaryColor = companyInfoData.primaryColor;
               secondaryColor = companyInfoData.secondaryColor;

               // Filtrar la empresa seleccionada dentro de data
               const companyData: CompanyData | undefined = data.find(
                  (company: CompanyData) => company.companyId === companyId
               );

               // Si no se encuentra la empresa, lanzar error
               if (!companyData) throw new Error("No se encontró información para la empresa seleccionada.");

               const groupID: string = data[0]?.group || "Sin grupo";

               // Calcular las cantidades y estadísticas en el front-end
               // Calcular las cantidades y estadísticas en el front-end
               const totalComplaints: number = companyData.complaints?.length ?? 0;

               // Variables predeterminadas con nombres traducidos
               let complaintStatusCounts = {
                  Interpuesta: 0, // Enviada
                  "En investigación": 0, // En proceso
                  "En resolución": 0, // En valoración
                  "Por finalizar": 0, // Resuelto
                  Finalizadas: 0, // Cerrado
               };
               let denunciasResueltas: number = 0;
               let denunciasEnProceso: number = 0;
               let totalSuggestions: number = 0;
               let totalDenuncias2: number = 0;
               let totalQuejas: number = 0;

               let anonymousComplaintsPercentage: string = "0%";
               let categoryPercentages: { category: string; count: number; percentage: string }[] = [];
               let complaintStatus1;
               const complainerTypes: string[] = ["proveedor", "empleado", "cliente", "exempleado", "otro", "usuario"];
               let complaintStatus2: Record<string, number> = complainerTypes.reduce((acc, type) => {
                  acc[type] = 0;
                  return acc;
               }, {} as Record<string, number>);
               let sucursalComplaintStats: { sucursal: string; improcedente: number; procedente: number }[] = [];
               let branchNames: string[] = [];

               // Si hay quejas, calcular valores reales
               if (totalComplaints > 0) {
                  // Calcular el número de denuncias por cada estado con nombres traducidos
                  complaintStatusCounts = {
                     Interpuesta: companyData.complaints.filter((c: Complaint) => c.status === "enviada").length,
                     "En investigación": companyData.complaints.filter((c: Complaint) => c.status === "en proceso")
                        .length,
                     "En resolución": companyData.complaints.filter((c: Complaint) => c.status === "en valoración")
                        .length,
                     "Por finalizar": companyData.complaints.filter((c: Complaint) => c.status === "resuelto").length,
                     Finalizadas: companyData.complaints.filter((c: Complaint) => c.status === "cerrada").length,
                  };

                  // Filtrar las que tienen type "denuncia"
                  const denuncias = companyData.complaints.filter((c: Complaint) => c.type === "denuncia");
                  // Contar las denuncias por estado
                  const complaintStatusCounts3 = {
                     Interpuesta: denuncias.filter((c: Complaint) => c.status === "enviada").length,
                     "En investigación": denuncias.filter((c: Complaint) => c.status === "en proceso").length,
                     "En resolución": denuncias.filter((c: Complaint) => c.status === "en valoración").length,
                     "Por finalizar": denuncias.filter((c: Complaint) => c.status === "resuelto").length,
                     Finalizadas: denuncias.filter((c: Complaint) => c.status === "cerrada").length,
                  };

                  // Filtrar las denuncias que son anónimas
                  const denunciasAnonimas = denuncias.filter((c: Complaint) => c.complaintType === "anonima");

                  // Calcular el número de quejas anónimas
                  const numeroDenunciasAnonimas = denunciasAnonimas.length;
                  // Calcular el número de quejas anónimas
                  totalDenuncias2 = denuncias.length;

                  // Calcular denuncias resueltas y en proceso
                  denunciasResueltas = complaintStatusCounts3["Finalizadas"] || 0;
                  denunciasEnProceso = complaintStatusCounts3["En investigación"] || 0;

                  // Calcular sugerencias totales
                  totalSuggestions = companyData.complaints.filter((c: Complaint) => c.type === "sugerencia").length;
                  totalQuejas = companyData.complaints.filter((c: Complaint) => c.type === "queja").length;

                  if (totalComplaints > 0 && totalDenuncias > 0) {
                     // Verificar que totalDenuncias sea mayor que 0 para evitar Infinity
                     const percentage = (numeroDenunciasAnonimas / totalDenuncias2) * 100;

                     // Verificar si el porcentaje es un número entero
                     anonymousComplaintsPercentage = Number.isInteger(percentage)
                        ? `${percentage}%`
                        : `${percentage.toFixed(2)}%`;
                  }

                  // Filtrar y agrupar por categorías, omitiendo las que tienen type "queja"
                  const filteredComplaints = companyData.complaints.filter(
                     (complaint: Complaint) => complaint.type !== "queja"
                  ); // Omitir las quejas de tipo "queja"
                  const categoryTotals = filteredComplaints.reduce(
                     (acc: Record<string, number>, complaint: Complaint) => {
                        if (complaint.type === "denuncia") {
                           const category = complaint.category || "Sin categoría";
                           acc[category] = (acc[category] || 0) + 1;
                        }
                        return acc;
                     },
                     {}
                  );

                  // Crear un array con los porcentajes por categoría usando el total filtrado
                  categoryPercentages = Object.entries(categoryTotals).map(([category, count]) => {
                     const percentage =
                        totalComplaints > 0 ? ((Number(count) / totalComplaints) * 100).toFixed(2) + "%" : "0%";
                     return { category, count: Number(count), percentage };
                  });

                  // Asegurar que categoryPercentages no esté vacío
                  if (categoryPercentages.length === 0) {
                     categoryPercentages.push({
                        category: "Sin información",
                        count: 0,
                        percentage: "0%",
                     });
                  }

                  // Recorrer las quejas y contar por complainerType
                  companyData.complaints.forEach((complaint: Complaint) => {
                     const type = (complaint.complainerType || "otro").toLowerCase();
                     if (complainerTypes.includes(type)) {
                        complaintStatus2[type] += 1;
                     } else {
                        // Si el tipo no está en la lista, clasificarlo como 'otro'
                        complaintStatus2["otro"] += 1;
                     }
                  });

                  // Extraer nombres de sucursales
                  branchNames = companyData.branches?.map((branch: Branch) => branch.branchDetails.name) || [];

                  // Calcular denuncias improcedentes y procedentes por sucursal
                  sucursalComplaintStats =
                     companyData.branches?.length > 0
                        ? companyData.branches.map((branch: Branch) => {
                             const sucursalComplaints = companyData.complaints.filter(
                                (c: Complaint) => c.companyBranch === branch.branchId
                             );

                             const improcedenteCount: number = sucursalComplaints.filter(
                                (c: Complaint) => c.status === "enviada" || c.status === "improcedente"
                             ).length;

                             const procedenteCount: number = sucursalComplaints.filter(
                                (c: Complaint) => c.status !== "enviada" && c.status !== "improcedente"
                             ).length;

                             return {
                                sucursal: branch.branchDetails.name || "Sucursal Desconocida",
                                improcedente: improcedenteCount || 0,
                                procedente: procedenteCount || 0,
                             };
                          })
                        : [
                             {
                                sucursal: "Sin sucursales",
                                improcedente: 0,
                                procedente: 0,
                             },
                          ];
               } else {
                  // Si no hay quejas, asignar valores predeterminados
                  categoryPercentages = [
                     {
                        category: "Sin información",
                        count: 0,
                        percentage: "0%",
                     },
                  ];
                  complaintStatus1 = {
                     "en investigación": 0,
                     "por finalizar": 0,
                  };
                  complaintStatus2 = complainerTypes.reduce((acc, type) => {
                     acc[type] = 0;
                     return acc;
                  }, {} as Record<string, number>);
                  branchNames = companyData.branches?.map((branch: Branch) => branch.branchDetails.name) || [];
                  sucursalComplaintStats =
                     companyData.branches?.length > 0
                        ? companyData.branches.map((branch: Branch) => ({
                             sucursal: branch.branchDetails.name || "Sucursal Desconocida",
                             improcedente: 0,
                             procedente: 0,
                          }))
                        : [
                             {
                                sucursal: "Sin sucursales",
                                improcedente: 0,
                                procedente: 0,
                             },
                          ];
               }

               if (!Array.isArray(categoryPercentages) || categoryPercentages.length === 0) {
                  categoryPercentages = [
                     {
                        category: "Sin información",
                        count: 0,
                        percentage: "0%",
                     },
                  ];
               }

               // Generar el PDF de empresa
               response = await createCompanyPDF(
                  groupID,
                  companyId,
                  startDate || "Fecha inicio no proporcionada",
                  finishDate || "Fecha fin no proporcionada",
                  logoUrl,
                  primaryColor,
                  secondaryColor,
                  companyInfoData.businessName,
                  totalComplaints,
                  denunciasResueltas, // Número de quejas resueltas
                  denunciasEnProceso, // Número de quejas en proceso
                  totalSuggestions, // Número total de sugerencias
                  anonymousComplaintsPercentage,
                  categoryPercentages,
                  complaintStatus1,
                  complaintStatus2,
                  totalComplaints, // totalComplaints2
                  complaintStatusCounts, // complaintClassifications
                  totalComplaints, // totalClassifications
                  branchNames, // nombresSucursales
                  sucursalComplaintStats, // Estadísticas de denuncias por sucursal
                  totalDenuncias2,
                  totalQuejas
               );
            } catch (error) {
               // Asignar valores predeterminados en caso de error
               response = 0;
            }
         } else if (reportType === "branch") {
            if (!selectedCompany2 || !selectedBranch2) {
               showSnackBar("Debe seleccionar una empresa y una sucursal.", true);
               setIsLoadingReport(false);
               return;
            }

            const companyId = selectedCompany2;
            const branchId = selectedBranch2;

            // Obtener el logo y los colores de la compañía
            logoUrl = await getDataS3(companyId); // Función para obtener el logo de la compañía
            const companyInfoData = await getCompanyColors(companyId);
            primaryColor = companyInfoData.primaryColor;
            secondaryColor = companyInfoData.secondaryColor;

            const groupID = data[0]?.group;

            // Verificar que groupID esté definido
            if (!groupID) {
               throw new Error("No se encontró el ID del grupo asociado a la empresa.");
            }

            // Filtrar la empresa y sucursal seleccionadas dentro de data
            const companyData = data.find((company) => company.companyId === companyId);

            if (!companyData) {
               throw new Error("No se encontró información para la empresa seleccionada.");
            }

            // Encontrar la sucursal dentro de la empresa seleccionada
            const branchData = companyData.branches?.find((branch) => branch.branchId === branchId);

            if (!branchData) {
               throw new Error("No se encontró información para la sucursal seleccionada.");
            }
            const branchName = branchData.branchDetails.name;

            // Filtrar los datos relevantes de branchData antes de enviarlos
            const filteredBranchData = {
               branchComplaints: branchData.branchComplaints.map((complaint) => ({
                  status: complaint.status,
                  type: complaint.type,
                  category: complaint.category,
                  complaintType: complaint.complaintType,
                  complainerType: complaint.complainerType,
               })),
            };

            // Preparar el payload para el reporte de sucursal
            const payload = {
               startDate,
               finishDate,
               monthValue: null,
               logoUrl,
               primaryColor,
               secondaryColor,
               businessName: branchName,
               branchData: filteredBranchData, // Datos filtrados de la sucursal seleccionada
            };

            // **Llamar a la función para generar el PDF de sucursal y asignar a `response`**
            try {
               response = await createBranchPDF(groupID, companyId, branchId, payload);
            } catch (error) {
               response = null; // O asigna un valor por defecto adecuado
            }
         }

         // Verificar si el PDF fue generado exitosamente
         if (response) {
            const reportUrl = response; // Asumimos que response es la URL directamente
            setIsLoadingReport(false);

            setTimeout(() => {
               window.open(reportUrl, "_blank", "noopener,noreferrer");
            }, 1000); // Retraso de 1 segundo
         } else {
            throw new Error("La generación del PDF falló.");
         }
      } catch (error) {
         showSnackBar(`No es posible crear el PDF en este momento.`, true);
         setIsLoadingReport(false);
      }
   };

   let totalScoreComapanySuggestion =
      complaintsChartByCompanySuggestions?.scoreByCompany[0][0] +
      complaintsChartByCompanySuggestions?.scoreByCompany[1][0];

   let totalScoreComapanyComplaint =
      complaintsChartByCompanyComplaint?.scoreByCompany[0][0] + complaintsChartByCompanyComplaint?.scoreByCompany[1][0];

   let totalScoreComapanyNagging =
      complaintsChartByCompanyNagging?.scoreByCompany[0][0] + complaintsChartByCompanyNagging?.scoreByCompany[1][0];

   const toggleContentVisibilityContaint = () => {
      setIsContentVisibleContaint(!isContentVisibleContaint);
   };

   const toggleContentVisibilitySuggestion = () => {
      setIsContentVisibleSuggestion(!isContentVisibleSuggestion);
   };

   const toggleContentVisibilityNagging = () => {
      setIsContentVisibleNagging(!isContentVisibleNagging);
   };

   return (
      <Box>
         <Box bgcolor={"#f5f5f5"} mb={3}>
            {noComplaints ? (
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     padding: "20px",
                     backgroundColor: "#1F2937", // Fondo opcional para mejorar la visibilidad del texto
                     height: "100%", // Asegura que el contenedor ocupe todo el espacio disponible
                     width: "1417.4px",
                  }}
               >
                  <Typography fontWeight={600} color={"#E0E2E7"} textAlign="center">
                     No existen interposiciones para esta empresa en el periodo seleccionado
                  </Typography>
               </Box>
            ) : (
               <Box width={"1417.4px"}>
                  <Grid
                     container
                     display={"flex"}
                     px={4}
                     py={2}
                     justifyContent={"space-between"}
                     bgcolor={"#FFFFFF"}
                     mb={4}
                  >
                     <Box>
                        <Box display={"flex"} alignItems={"center"} sx={{ width: "1045.15px", mb: "23px" }}>
                           <TableContainer component={Paper}>
                              <Table aria-label="simple table">
                                 <TableHead>
                                    <TableRow style={{ backgroundColor: "#162c44" }}>
                                       <TableCell style={{ color: "white", textAlign: "center" }}>
                                          Interposiciones de la empresa
                                       </TableCell>
                                       <TableCell align="center" style={{ color: "white", textAlign: "center" }}>
                                          Denuncias
                                       </TableCell>
                                       <TableCell align="center" style={{ color: "white", textAlign: "center" }}>
                                          Quejas
                                       </TableCell>
                                       <TableCell align="center" style={{ color: "white", textAlign: "center" }}>
                                          Sugerencias
                                       </TableCell>
                                    </TableRow>
                                 </TableHead>
                                 <TableBody>
                                    <TableRow>
                                       <TableCell component="th" scope="row" sx={{ textAlign: "center" }}>
                                          {updatedCompanyInfo?.person_details.comercialName}
                                       </TableCell>
                                       <TableCell align="center">{rowsTableCompany.denuncias.length}</TableCell>
                                       <TableCell align="center">{rowsTableCompany.quejas.length}</TableCell>
                                       <TableCell align="center">{rowsTableCompany.sugerencias.length}</TableCell>
                                    </TableRow>
                                 </TableBody>
                              </Table>
                           </TableContainer>
                        </Box>
                        <Box width={"1045.15px"} height={"340px"} display={"flex"}>
                           <TableContainer component={Paper} sx={{ maxHeight: "340px", overflowY: "auto" }}>
                              <Table aria-label="simple table" stickyHeader>
                                 <TableHead sx={{ backgroundColor: "#162c44" }}>
                                    <TableRow>
                                       <TableCell
                                          sx={{
                                             color: "white",
                                             textAlign: "center",
                                             bgcolor: "#162c44",
                                             width: "448.23px",
                                          }}
                                       >
                                          Sucursal
                                       </TableCell>
                                       <TableCell
                                          align="right"
                                          sx={{ color: "white", textAlign: "center", bgcolor: "#162c44" }}
                                       >
                                          Denuncias
                                       </TableCell>
                                       <TableCell
                                          align="right"
                                          sx={{ color: "white", textAlign: "center", bgcolor: "#162c44" }}
                                       >
                                          Quejas
                                       </TableCell>
                                       <TableCell
                                          align="right"
                                          sx={{ color: "white", textAlign: "center", bgcolor: "#162c44" }}
                                       >
                                          Sugerencias
                                       </TableCell>
                                    </TableRow>
                                 </TableHead>

                                 <TableBody>
                                    {rowsTableBranches.map((row) => (
                                       <TableRow key={row.sucursal}>
                                          <TableCell component="th" scope="row" sx={{ textAlign: "center" }}>
                                             {row.sucursal}
                                          </TableCell>
                                          <TableCell align="center">{row.denuncias}</TableCell>
                                          <TableCell align="center">{row.quejas}</TableCell>
                                          <TableCell align="center">{row.sugerencias}</TableCell>
                                       </TableRow>
                                    ))}
                                 </TableBody>
                              </Table>
                              {rowsTableBranches.length === 0 && (
                                 <Typography
                                    sx={{
                                       display: "flex",
                                       justifyContent: "center",
                                       alignItems: "center",
                                       bgcolor: "#fff",
                                       width: "100%",
                                       height: "281px",
                                       fontWeight: 600,
                                       fontSize: "17px",
                                    }}
                                 >
                                    No se encontraron sucursales
                                 </Typography>
                              )}
                           </TableContainer>
                        </Box>
                     </Box>
                     <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <Box>
                           <Box
                              sx={{
                                 width: "240.6px",
                                 display: "flex",
                                 alignItems: "start",
                                 justifyContent: "center",
                                 mt: "15px",
                              }}
                           >
                              <Typography sx={{ fontWeight: 600, textAlign: "center", fontSize: "18px" }}>
                                 INFORMACIÓN
                                 <br />
                                 TOTAL
                              </Typography>
                           </Box>
                        </Box>
                        <Box sx={{ width: "240.6px", height: "375px" }}>
                           <Box
                              sx={{
                                 width: "100%",
                                 height: "100%",
                                 display: "flex",
                                 justifyContent: "space-between",
                                 alignItems: "start",
                                 flexDirection: "column",
                                 gap: 2,
                              }}
                           >
                              <InfoBox
                                 title="SUCURSALES"
                                 value={updatedCompanyInfo.branches.length}
                                 icon={fluent_building_home}
                              />
                              <InfoBox title="DENUNCIAS" value={totalDenuncias} icon={mingcute_report_fill} />
                              <InfoBox title="QUEJAS" value={totalQuejas} icon={round_report_problem} />
                              <InfoBox title="SUGERENCIAS" value={totalSugerencias} icon={octicon_report} />
                           </Box>
                        </Box>
                     </Box>
                  </Grid>
                  {/* <----------------------------------------------------- Interposición de denuncias -----------------------------------------------------> */}
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#E0E2E7",
                        px: "30px",
                        mt: 4,
                        height: "35px",
                        width: "1417.4px",
                     }}
                     onClick={toggleContentVisibilityContaint}
                  >
                     <Typography sx={{ fontWeight: 600, color: "#2D4357" }}>Interposición de denuncia</Typography>
                     <IconButton onClick={toggleContentVisibilityContaint} style={{ height: "27px", width: "27px" }}>
                        {isContentVisibleContaint ? (
                           <KeyboardArrowDownIcon fontSize="large" />
                        ) : (
                           <KeyboardArrowUpIcon fontSize="large" />
                        )}
                     </IconButton>
                  </Box>
                  <Collapse in={isContentVisibleContaint} unmountOnExit>
                     {totalDenuncias === 0 ? (
                        <Typography
                           sx={{
                              width: "1417.4px",
                              height: "200px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: 700,
                              fontSize: "20px",
                              backgroundColor: "#ffffff",
                           }}
                        >
                           Sin información para mostrar
                        </Typography>
                     ) : (
                        <Box width={"1417.4px"}>
                           <Box flex={2} sx={{ backgroundColor: "#ffffff" }}>
                              <Box
                                 sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    px: 2,
                                    minHeight: "100%",
                                 }}
                              >
                                 <Grid
                                    container
                                    item
                                    xs={9.15}
                                    sx={{
                                       boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                       border: "1px solid #E5E5EF",
                                       borderTop: "none",
                                       mt: 2,
                                    }}
                                 >
                                    <Grid width={"100%"} mb="16px">
                                       <Box display="flex" justifyContent="space-between" alignItems="center">
                                          <Typography
                                             color="#2d4357"
                                             variant="h6"
                                             fontWeight={700}
                                             sx={{ px: 4, py: 1, fontSize: "17px" }}
                                          >
                                             {`Calificación de denuncias${
                                                procedenteInprocedenteComplainBranches > 0
                                                   ? " por sucursal"
                                                   : totalScoreComapanyComplaint > 0
                                                   ? " por compañia"
                                                   : ""
                                             }`}
                                          </Typography>
                                       </Box>
                                       <Divider />
                                    </Grid>
                                    {procedenteInprocedenteComplainBranches + totalScoreComapanyComplaint > 0 ? (
                                       <Grid item xs={12} minHeight={320}>
                                          <Box width={"99%"} height={"100%"}>
                                             {UseCreateCharts({
                                                type:
                                                   complaintsChartByCompanyComplaint.labels.length < 2 ? "bar" : "line",
                                                scores:
                                                   procedenteInprocedenteComplainBranches === 0
                                                      ? complaintsChartByCompanyComplaint?.scoreByCompany //scoresCompany complaintsChartByCompanyNagging?.scoresCompany
                                                      : complaintsChartByCompanyComplaint?.scores,
                                                labels:
                                                   procedenteInprocedenteComplainBranches === 0
                                                      ? [complaintsChartByCompanyComplaint?.companyName]
                                                      : complaintsChartByCompanyComplaint?.labels,
                                                labelNames: ["Procedente", "Improcedente"],
                                                colors: ["#64748B", "#B5B5B5"],
                                                chartRef: calificationChartRef,
                                                tension: 0.4,
                                                barThickness: 100,
                                                borderRadius: true,
                                                transparedBgBarChart: true,
                                             })}
                                          </Box>
                                       </Grid>
                                    ) : (
                                       <Grid
                                          item
                                          xs={12}
                                          minHeight={320}
                                          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                       >
                                          <Typography fontWeight={600} fontSize={"17px"}>
                                             No se encontro información
                                          </Typography>
                                       </Grid>
                                    )}
                                    <Grid display="flex" justifyContent="center" width="100%" mb={2}>
                                       <Typography fontWeight={600} display="flex" alignItems="center" marginRight={8}>
                                          <Box
                                             sx={{
                                                width: 10,
                                                height: 10,
                                                bgcolor: "#64748B",
                                                borderRadius: "50%",
                                                marginRight: 1,
                                             }}
                                          />
                                          Procedentes
                                       </Typography>
                                       <Typography fontWeight={600} display="flex" alignItems="center">
                                          <Box
                                             sx={{
                                                width: 10,
                                                height: 10,
                                                bgcolor: "#B5B5B5",
                                                borderRadius: "50%",
                                                marginRight: 1,
                                             }}
                                          />
                                          Improcedentes
                                       </Typography>
                                    </Grid>
                                 </Grid>
                                 <Grid
                                    item
                                    xs={3}
                                    display="flex"
                                    justifyContent="space-between"
                                    flexDirection="column"
                                    mt="16px"
                                 >
                                    <InfoBox
                                       title="Denuncias procedentes"
                                       value={
                                          complaintsChartByCompanyComplaint.admissible + totalBrachesAdmissibleComplaint
                                       }
                                       icon={mingcute_report_fill}
                                       widthValue="270px"
                                    />
                                    <InfoBox
                                       title="Denuncias improcedentes"
                                       value={
                                          complaintsChartByCompanyComplaint.inadmissible +
                                          totalBrachesInadmissibleComplaint
                                       }
                                       icon={material_symbols_report_off}
                                       widthValue="270px"
                                    />
                                    <InfoBox
                                       title="Denuncias anónimas"
                                       value={complaintsChartByCompanyComplaint.anonymousComplaints}
                                       icon={baseline_no_account}
                                       widthValue="270px"
                                    />
                                    <InfoBox
                                       title="Denuncias no anónimas"
                                       value={complaintsChartByCompanyComplaint.nonAnonymousComplaints}
                                       icon={mingcute_user}
                                       widthValue="270px"
                                    />
                                 </Grid>
                              </Box>
                              <Box display="flex" justifyContent="space-between" mt={3} p={2}>
                                 <StatisticsDonutCard
                                    titleChart="Estatus de gestión"
                                    titleCategoriesColumn="Estado"
                                    newRef={newRef}
                                    dataTableDonutComponent={dataComplaintsStatus}
                                    colorValues={["#757575", "#2D4357", "#5D6D7C", "#748190", "#000000"]}
                                    labelValues={dataComplaintsStatus.map((item) => item.label)}
                                    scoreValues={dataComplaintsStatus.map((item) => item.score)}
                                    numberCenterCircle={totalStatusComplaints}
                                 />
                                 <Grid item xs={5} minWidth="600px">
                                    <CategoriesComparative
                                       filteredSubjects={complaintsCategories}
                                       mainTitle="Categorias de denuncias"
                                       minWidthValue="700px"
                                       branches={branches}
                                       selectedBranch={selectedBranch}
                                       handleBranchChange={handleBranchChange}
                                    />
                                 </Grid>
                                 <StatisticsDonutCard
                                    titleChart="Relacionados de las interposiciones"
                                    titleCategoriesColumn="Relacionado"
                                    newRef={newRef}
                                    dataTableDonutComponent={dataInterpositionComplaint}
                                    colorValues={["#757575", "#2D4357", "#5D6D7C", "#748190", "#000000", "#AFB7BF"]}
                                    labelValues={dataInterpositionComplaint.map((item) => item.label)}
                                    scoreValues={dataInterpositionComplaint.map((item) => item.score)}
                                    numberCenterCircle={totalReleatedComplaints}
                                 />
                              </Box>
                           </Box>
                        </Box>
                     )}
                  </Collapse>

                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#E0E2E7",
                        px: "30px",
                        mt: 4,
                        height: "35px",
                     }}
                     onClick={toggleContentVisibilitySuggestion}
                  >
                     <Typography sx={{ fontWeight: 600, color: "#2D4357" }}>Interposición de sugerencias</Typography>
                     <IconButton onClick={toggleContentVisibilitySuggestion} style={{ height: "27px", width: "27px" }}>
                        {isContentVisibleSuggestion ? (
                           <KeyboardArrowDownIcon fontSize="large" />
                        ) : (
                           <KeyboardArrowUpIcon fontSize="large" />
                        )}
                     </IconButton>
                  </Box>
                  <Collapse in={isContentVisibleSuggestion} unmountOnExit>
                     {totalSugerencias === 0 ? (
                        <Typography
                           sx={{
                              width: "100%",
                              height: "200px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: 700,
                              fontSize: "20px",
                              backgroundColor: "#ffffff",
                           }}
                        >
                           Sin información para mostrar
                        </Typography>
                     ) : (
                        <Box>
                           <Box flex={2} sx={{ backgroundColor: "#ffffff" }}>
                              <Box
                                 sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    px: 2,
                                    minHeight: "100%",
                                 }}
                              >
                                 <Grid
                                    container
                                    item
                                    xs={6}
                                    sx={{
                                       boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                       border: "1px solid #E5E5EF",
                                       borderTop: "none",
                                       mt: 2,
                                    }}
                                 >
                                    <Grid item mb="16px" width="100%">
                                       <Box display="flex" justifyContent="space-between" alignItems="center">
                                          <Typography
                                             color="#2d4357"
                                             variant="h6"
                                             fontWeight={700}
                                             sx={{ px: 4, py: 1, fontSize: "17px" }}
                                          >
                                             {`Calificación de sugerencias${
                                                procedenteInprocedenteSuggestionBranches > 0
                                                   ? " por sucursal"
                                                   : totalScoreComapanySuggestion > 0
                                                   ? " por compañia"
                                                   : ""
                                             }`}
                                          </Typography>
                                       </Box>
                                       <Divider />
                                    </Grid>
                                    {procedenteInprocedenteSuggestionBranches + totalScoreComapanySuggestion > 0 ? (
                                       <Grid item minHeight={320} xs={12}>
                                          <Box width={"99%"} height={"100%"}>
                                             {UseCreateCharts({
                                                type: "stack",
                                                scores:
                                                   procedenteInprocedenteSuggestionBranches === 0
                                                      ? complaintsChartByCompanySuggestions?.scoreByCompany //scoresCompany complaintsChartByCompanyNagging?.scoresCompany
                                                      : complaintsChartByCompanySuggestions?.scores,
                                                labels:
                                                   procedenteInprocedenteSuggestionBranches === 0
                                                      ? [complaintsChartByCompanySuggestions?.companyName]
                                                      : complaintsChartByCompanySuggestions?.labels,
                                                labelNames: ["Procedentes", "Improcedentes"],
                                                colors: ["#64748B", "#B5B5B5"],
                                                chartRef: typeChartRef,
                                                borderRadius: true,
                                                barThickness:
                                                   procedenteInprocedenteSuggestionBranches === 0
                                                      ? 100
                                                      : branches.length > 9 && branches.length < 26
                                                      ? 50 - (branches.length - 9) * 4
                                                      : branches.length === 25
                                                      ? 50 - (branches.length - 9) * 4
                                                      : 50,
                                                showTicks: true,
                                             })}
                                          </Box>
                                       </Grid>
                                    ) : (
                                       <Grid
                                          item
                                          xs={12}
                                          minHeight={320}
                                          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                       >
                                          <Typography fontWeight={600} fontSize={"17px"}>
                                             No se encontro información
                                          </Typography>
                                       </Grid>
                                    )}
                                    <Grid display="flex" justifyContent="center" width="100%" mb={2}>
                                       <Typography fontWeight={600} display="flex" alignItems="center" marginRight={8}>
                                          <Box
                                             sx={{
                                                width: 10,
                                                height: 10,
                                                bgcolor: "#64748B",
                                                borderRadius: "50%",
                                                marginRight: 1,
                                             }}
                                          />
                                          Procedentes
                                       </Typography>
                                       <Typography fontWeight={600} display="flex" alignItems="center">
                                          <Box
                                             sx={{
                                                width: 10,
                                                height: 10,
                                                bgcolor: "#B5B5B5",
                                                borderRadius: "50%",
                                                marginRight: 1,
                                             }}
                                          />
                                          Improcedentes
                                       </Typography>
                                    </Grid>
                                 </Grid>
                                 <Grid
                                    item
                                    xs={3}
                                    display="flex"
                                    justifyContent="space-evenly"
                                    flexDirection="column"
                                    mt="16px"
                                 >
                                    <StatisticsDonutCard
                                       titleChart="Estatus de gestión"
                                       titleCategoriesColumn="Estado"
                                       newRef={newRef}
                                       dataTableDonutComponent={dataSuggestionsStatus}
                                       colorValues={["#757575", "#2D4357", "#5D6D7C", "#748190", "#000000", "#AFB7BF"]}
                                       labelValues={dataSuggestionsStatus.map((item) => item.label)}
                                       scoreValues={dataSuggestionsStatus.map((item) => item.score)}
                                       numberCenterCircle={totalStatusSuggestions}
                                       heightValue="536.8px"
                                    />
                                 </Grid>
                                 <Grid
                                    item
                                    display="flex"
                                    justifyContent="space-evenly"
                                    flexDirection="column"
                                    mt="16px"
                                 >
                                    <StatisticsDonutCard
                                       titleChart="Tipo de sugerencia"
                                       titleCategoriesColumn="Tipo"
                                       newRef={newRef}
                                       dataTableDonutComponent={generateNonAndAnonimusDataSuggestion}
                                       colorValues={["#757575", "#2D4357"]}
                                       labelValues={generateNonAndAnonimusDataSuggestion.map((item) => item.label)}
                                       scoreValues={generateNonAndAnonimusDataSuggestion.map((item) => item.score)}
                                       numberCenterCircle={totalAnonimusOrNonAnonimusSuggestions}
                                       key={totalAnonimusOrNonAnonimusSuggestions}
                                       heightValue="536.8px"
                                    />
                                 </Grid>
                              </Box>
                              <Box display="flex" justifyContent="space-between" mt={3} p={2}>
                                 <Box>
                                    <CategoriesComparative
                                       filteredSubjects={complaintsCategoriesSuggestions}
                                       mainTitle="Categorias de sugerencias"
                                       minWidthValue="1037px"
                                       branches={branches}
                                       selectedBranch={selectedBranchSuggestion}
                                       handleBranchChange={handleBranchChangeSuggestions}
                                       heightValue="536.8px"
                                    />
                                 </Box>
                                 <StatisticsDonutCard
                                    titleChart="Relacionados de las interposiciones"
                                    titleCategoriesColumn="Relacionado"
                                    newRef={newRef}
                                    dataTableDonutComponent={dataInterpositionSuggestions}
                                    colorValues={["#757575", "#2D4357", "#5D6D7C", "#748190", "#000000", "#AFB7BF"]}
                                    labelValues={dataInterpositionSuggestions.map((item) => item.label)}
                                    scoreValues={dataInterpositionSuggestions.map((item) => item.score)}
                                    numberCenterCircle={totalReleatedSuggestions}
                                 />
                              </Box>
                           </Box>
                        </Box>
                     )}
                  </Collapse>

                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#E0E2E7",
                        px: "30px",
                        mt: 4,
                        height: "35px",
                     }}
                     onClick={toggleContentVisibilityNagging}
                  >
                     <Typography sx={{ fontWeight: 600, color: "#2D4357" }}>Interposición de quejas</Typography>
                     <IconButton onClick={toggleContentVisibilityNagging} style={{ height: "27px", width: "27px" }}>
                        {isContentVisibleNagging ? (
                           <KeyboardArrowDownIcon fontSize="large" />
                        ) : (
                           <KeyboardArrowUpIcon fontSize="large" />
                        )}
                     </IconButton>
                  </Box>
                  <Collapse in={isContentVisibleNagging} unmountOnExit>
                     {totalQuejas === 0 ? (
                        <Typography
                           sx={{
                              width: "100%",
                              height: "200px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: 700,
                              fontSize: "20px",
                              backgroundColor: "#ffffff",
                              mb: 4,
                           }}
                        >
                           Sin información para mostrar
                        </Typography>
                     ) : (
                        <Box>
                           <Box flex={2} sx={{ backgroundColor: "#ffffff" }}>
                              <Box
                                 sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    px: 2,
                                    minHeight: "100%",
                                 }}
                              >
                                 <Grid
                                    item
                                    xs={3}
                                    display="flex"
                                    justifyContent="space-between"
                                    flexDirection="column"
                                    mt="16px"
                                 >
                                    <InfoBox
                                       title="TOTALES"
                                       value={totalQuejas}
                                       icon={round_report_problem}
                                       widthValue="270px"
                                    />
                                    <InfoBox
                                       title="PROCEDENTES"
                                       value={
                                          complaintsChartByCompanyNagging.admissible + totalBrachesAdmissibleNagging
                                       }
                                       icon={iconamoon_check_bold}
                                       widthValue="270px"
                                    />
                                    <InfoBox
                                       title="IMPROCEDENTES"
                                       value={
                                          complaintsChartByCompanyNagging.inadmissible + totalBrachesInadmissibleNagging
                                       }
                                       icon={material_symbols_scan_delete}
                                       widthValue="270px"
                                    />
                                    <InfoBox
                                       title="EN PROCESO"
                                       value={
                                          complaintsChartByCompanyNagging?.statusComplaints["en proceso"] +
                                             complaintsChartByCompanyNagging?.statusComplaints["en valoración"] +
                                             complaintsChartByCompanyNagging?.statusComplaints.resuelto || 0
                                       }
                                       icon={report_warning}
                                       widthValue="270px"
                                    />
                                    <InfoBox
                                       title="FINALIZADAS"
                                       value={complaintsChartByCompanyNagging.statusComplaints["cerrada"] || 0}
                                       icon={ci_triangle_check}
                                       widthValue="270px"
                                    />
                                 </Grid>
                                 <Grid
                                    container
                                    item
                                    xs={9}
                                    sx={{
                                       boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                       border: "1px solid #E5E5EF",
                                       borderTop: "none",
                                       mt: 2,
                                    }}
                                 >
                                    <Grid item mb="16px" width="100%">
                                       <Box display="flex" justifyContent="space-between" alignItems="center">
                                          <Typography
                                             color="#2d4357"
                                             variant="h6"
                                             fontWeight={700}
                                             sx={{ px: 4, py: 1, fontSize: "17px" }}
                                          >
                                             {`Calificación de quejas${
                                                procedenteInprocedenteNaggingBranches > 0
                                                   ? " por sucursal"
                                                   : totalScoreComapanyNagging > 0
                                                   ? " por compañia"
                                                   : ""
                                             }`}
                                          </Typography>
                                       </Box>
                                       <Divider />
                                    </Grid>
                                    {procedenteInprocedenteNaggingBranches + totalScoreComapanyNagging > 0 ? (
                                       <Grid item xs={12} minHeight={320}>
                                          <Box width={"99%"} height={"100%"}>
                                             {UseCreateCharts({
                                                type: "stack",
                                                scores:
                                                   procedenteInprocedenteNaggingBranches === 0
                                                      ? complaintsChartByCompanyNagging?.scoreByCompany //complaintsChartByCompanyNagging?.scoreByCompany
                                                      : complaintsChartByCompanyNagging?.scores,
                                                labels:
                                                   procedenteInprocedenteNaggingBranches === 0
                                                      ? [complaintsChartByCompanyNagging?.companyName]
                                                      : complaintsChartByCompanyNagging?.labels,
                                                labelNames: ["Procedentes", "Improcedentes"],
                                                colors: ["#64748B", "#B5B5B5"],
                                                chartRef: typeChartRef,
                                                borderRadius: true,
                                                barThickness:
                                                   procedenteInprocedenteNaggingBranches === 0
                                                      ? 100
                                                      : branches.length > 9 && branches.length < 26
                                                      ? 50 - (branches.length - 9) * 4
                                                      : branches.length === 25
                                                      ? 50 - (branches.length - 9) * 4
                                                      : 50,
                                                showTicks: true,
                                             })}
                                          </Box>
                                       </Grid>
                                    ) : (
                                       <Grid
                                          item
                                          xs={12}
                                          minHeight={320}
                                          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                       >
                                          <Typography fontWeight={600} fontSize={"17px"}>
                                             No se encontro información
                                          </Typography>
                                       </Grid>
                                    )}
                                    <Grid display="flex" justifyContent="center" width="100%" mb={2}>
                                       <Typography fontWeight={600} display="flex" alignItems="center" marginRight={8}>
                                          <Box
                                             sx={{
                                                width: 10,
                                                height: 10,
                                                bgcolor: "#64748B",
                                                borderRadius: "50%",
                                                marginRight: 1,
                                             }}
                                          />
                                          Procedentes
                                       </Typography>
                                       <Typography fontWeight={600} display="flex" alignItems="center">
                                          <Box
                                             sx={{
                                                width: 10,
                                                height: 10,
                                                bgcolor: "#B5B5B5",
                                                borderRadius: "50%",
                                                marginRight: 1,
                                             }}
                                          />
                                          Improcedentes
                                       </Typography>
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box display="flex" justifyContent="space-between" my={3} p={2}>
                                 <Grid item xs={4}>
                                    <StatisticsDonutCard
                                       titleChart="Estatus de gestión"
                                       titleCategoriesColumn="Estado"
                                       newRef={newRef}
                                       dataTableDonutComponent={dataNaggingStatus}
                                       colorValues={["#757575", "#2D4357", "#5D6D7C", "#748190", "#000000", "#AFB7BF"]}
                                       labelValues={dataNaggingStatus.map((item) => item.label)}
                                       scoreValues={dataNaggingStatus.map((item) => item.score)}
                                       numberCenterCircle={totalStatusNagging}
                                       heightValue="536.8px"
                                    />
                                 </Grid>
                                 <Grid item xs={4}>
                                    <StatisticsDonutCard
                                       titleChart="Tipo de queja"
                                       titleCategoriesColumn="Tipo"
                                       newRef={newRef}
                                       dataTableDonutComponent={generateNonAndAnonimusDataNagging}
                                       colorValues={["#757575", "#2D4357"]}
                                       labelValues={generateNonAndAnonimusDataNagging.map((item) => item.label)}
                                       scoreValues={generateNonAndAnonimusDataNagging.map((item) => item.score)}
                                       numberCenterCircle={totalAnonimusOrNonAnonimusNagging}
                                       key={totalAnonimusOrNonAnonimusNagging}
                                       heightValue="536.8px"
                                    />
                                 </Grid>
                                 <Grid item xs={4}>
                                    <StatisticsDonutCard
                                       titleChart="Relacionados de las interposiciones"
                                       titleCategoriesColumn="Relacionado"
                                       newRef={newRef}
                                       dataTableDonutComponent={dataInterpositionNagging}
                                       colorValues={["#757575", "#2D4357", "#5D6D7C", "#748190", "#000000", "#AFB7BF"]}
                                       labelValues={dataInterpositionNagging.map((item) => item.label)}
                                       scoreValues={dataInterpositionNagging.map((item) => item.score)}
                                       numberCenterCircle={totalReleatedNagging}
                                    />
                                 </Grid>
                              </Box>
                           </Box>
                        </Box>
                     )}
                  </Collapse>
                  <Box
                     bgcolor={"white"}
                     borderColor={"#B5B5B5"}
                     borderRadius={3}
                     width={"100%"}
                     mt={!isContentVisibleNagging ? 4 : 0}
                  >
                     <Tabs value={"1"} textColor="primary">
                        <Tab label="Generar reportes" value={"1"} />
                     </Tabs>
                     <Divider sx={{ borderBottomWidth: 2 }} />
                     <TabPanel index={1} value={1}>
                        <Stack direction={"row"}>
                           {/* Columna izquierda: Selectores de empresa y sucursal */}
                           <Box flex={5} alignSelf={"center"}>
                              <Stack width={"100%"} py={2} px={3} spacing={3}>
                                 <Typography>
                                    <strong>Personalizado:</strong>
                                 </Typography>

                                 <Stack direction={"row"} spacing={3}>
                                    {/* Primera columna: Selectores de empresa y sucursal */}
                                    <Box flex={1}>
                                       <Stack spacing={2}>
                                          <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                             <Typography>Empresa:</Typography>
                                             <Select
                                                value={selectedCompany2}
                                                onChange={(event) => {
                                                   setSelectedCompany2(event.target.value);
                                                   setSelectedBranch2(""); // Limpiar sucursal al cambiar de empresa
                                                }}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Empresa" }}
                                                sx={{
                                                   backgroundColor: "#E6E7E7", // Cambiar el color de fondo
                                                }}
                                                disabled={companyInfo.length === 1}
                                             >
                                                {companyInfo.length > 1 ? (
                                                   <MenuItem value="">
                                                      <em>Seleccione una empresa</em>
                                                   </MenuItem>
                                                ) : (
                                                   <MenuItem value={companyInfo[0].companyId}>
                                                      <em>{companyInfo[0].person_details.comercialName}</em>
                                                   </MenuItem>
                                                )}
                                                {companyInfo.length > 1 &&
                                                   companyInfo
                                                      .filter((company) => {
                                                         const serviceIdToCheck = "64e784dd978b71bd4f011ee3";

                                                         // Verificar si el servicio está habilitado
                                                         const hasServiceEnabled =
                                                            company.companyDetails?.servicesDetails?.some(
                                                               (service: any) =>
                                                                  service.serviceId === serviceIdToCheck &&
                                                                  !service.disable
                                                            );

                                                         return hasServiceEnabled;
                                                      })
                                                      .map((company) => (
                                                         <MenuItem key={company.companyId} value={company.companyId}>
                                                            {company.person_details.comercialName}
                                                         </MenuItem>
                                                      ))}
                                             </Select>
                                          </Stack>

                                          <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                             <Typography>Sucursal:</Typography>
                                             <Select
                                                value={selectedBranch2}
                                                onChange={(event) => setSelectedBranch2(event.target.value)}
                                                disabled={!selectedCompany2} // Deshabilitar si no hay empresa seleccionada
                                                displayEmpty
                                                inputProps={{ "aria-label": "Sucursal" }}
                                                sx={{
                                                   backgroundColor: "#E6E7E7", // Cambiar el color de fondo
                                                }}
                                             >
                                                <MenuItem value="">
                                                   <em>Seleccione una sucursal</em>
                                                </MenuItem>
                                                {companyInfo
                                                   .find((company) => company.companyId === selectedCompany2)
                                                   ?.branches.map((branch) => (
                                                      <MenuItem key={branch.branchId} value={branch.branchId}>
                                                         {branch.branchDetails.name}
                                                      </MenuItem>
                                                   ))}
                                             </Select>
                                          </Stack>
                                       </Stack>
                                    </Box>

                                    {/* Segunda columna: Selectores de fecha */}
                                    <Box flex={1}>
                                       <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                                          <Stack spacing={2}>
                                             <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
                                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                   <Typography>Desde:</Typography>
                                                   <DatePicker
                                                      disabled
                                                      maxDate={enddate}
                                                      label={"Fecha de inicio"}
                                                      format="DD/MM/YYYY"
                                                      value={startdate}
                                                      slotProps={{
                                                         textField: {
                                                            variant: "outlined",
                                                            size: "small",
                                                            error: false,
                                                         },
                                                      }} // Remover estado de error
                                                      sx={{
                                                         backgroundColor: "#E6E7E7", // Cambiar el color de fondo
                                                      }}
                                                   />
                                                </Stack>
                                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                   <Typography>Hasta:</Typography>
                                                   <DatePicker
                                                      disabled
                                                      minDate={startdate}
                                                      maxDate={today}
                                                      label={"Fecha de fin"}
                                                      format="DD/MM/YYYY"
                                                      value={enddate}
                                                      slotProps={{
                                                         textField: {
                                                            variant: "outlined",
                                                            size: "small",
                                                            error: false,
                                                         },
                                                      }} // Remover estado de error
                                                      sx={{
                                                         backgroundColor: "#E6E7E7", // Cambiar el color de fondo
                                                      }}
                                                   />
                                                </Stack>
                                             </Stack>
                                          </Stack>
                                       </LocalizationProvider>
                                    </Box>

                                    {/* Tercera columna: Botón para generar el reporte */}
                                    <Box flex={1} display="flex" justifyContent="center" alignItems="center">
                                       <InsertDriveFileIcon sx={{ color: "#ffffff", mr: 1 }} />
                                       <LoadingButton
                                          onClick={() => {
                                             if (selectedCompany2 && selectedBranch2) {
                                                createReport("branch"); // Generar reporte de sucursal
                                             } else if (selectedCompany2) {
                                                createReport("company"); // Generar reporte de empresa
                                             } else {
                                                createReport("group"); // Generar reporte de grupo
                                             }
                                          }}
                                          isLoading={isLoadingReport}
                                          label="Generar reporte en PDF"
                                       />
                                    </Box>
                                 </Stack>
                              </Stack>
                           </Box>
                        </Stack>
                     </TabPanel>
                  </Box>
               </Box>
            )}
         </Box>
      </Box>
   );
};

export const StatisticsScreenNew = () => {
   VerifyUserSession();
   const typeChartRef: any = useRef();
   const calificationChartRef: any = useRef();

   const newRef: any = useRef();

   const { user, companies } = useContext(UserContext);
   const { complaints, branches, loadingComplaints } = useContext(ComplaintContext);
   const [seed, setSeed] = useState(0);
   const [companyNameFilter, setCompanyNameFilter] = useState<string>("");
   const complaintsByStatus: any = useMemo(() => GetFilteredComplaints(complaints, "todos"), [complaints]);

   const firstDayOfMonth = dayjs().startOf("month");
   const lastDayOfMonth = dayjs().endOf("month");

   // Estados para el modo mensual
   const [monthlyStartDate, setMonthlyStartDate] = useState<Dayjs | null>(firstDayOfMonth);
   const [monthlyFinishDate, setMonthlyFinishDate] = useState<Dayjs | null>(lastDayOfMonth);

   // Estados para el modo período
   const [periodStartDate, setPeriodStartDate] = useState<Dayjs | null>(firstDayOfMonth);
   const [periodFinishDate, setPeriodFinishDate] = useState<Dayjs | null>(lastDayOfMonth);

   // Estado para controlar el modo (mensual o período)
   const [isMonthly, setIsMonthly] = useState(true); // Modo mensual por defecto
   // Variables para mantener la fecha seleccionada según el modo
   const startDate = isMonthly ? monthlyStartDate : periodStartDate;
   const finishDate = isMonthly ? monthlyFinishDate : periodFinishDate;

   const [selectedCompany, setSelectedCompany] = useState<any>();

   const companyInfo = useMemo(() => {
      const groupedCompanies = [];

      complaintsByStatus?.forEach((item) => {
         const companyId = item.company?._id || item.branch.company?._id;
         let company = groupedCompanies.find((c) => c.companyId === companyId);
         if (!company) {
            company = {
               companyId: companyId,
               companyDetails: item.company || item.branch.company,
               complaints: [],
               branches: [],
               companyComplaints: [],
               group: item.company?.group || item.branch.company?.group,
            };
            groupedCompanies.push(company);
         }

         company.complaints.push(item.complaint);

         if (item.branch) {
            let branch = company.branches.find((b) => b.branchId === item.branch._id);

            if (!branch) {
               branch = {
                  branchId: item.branch._id,
                  branchDetails: item.branch,
                  branchComplaints: [],
               };
               company.branches.push(branch);
            }

            if (item.complaint.companyBranch) {
               branch.branchComplaints.push(item.complaint);
            }
         }

         if (item.company) {
            company.companyComplaints.push(item.complaint);
         }
      });

      branches.forEach((branch) => {
         const companyId = branch.company._id;
         let company = groupedCompanies.find((c) => c.companyId === companyId);
         if (!company) {
            company = {
               companyId: companyId,
               companyDetails: branch.company,
               complaints: [],
               branches: [],
               group: branch.company.group,
            };
            groupedCompanies.push(company);
         }

         let existingBranch = company.branches.find((b) => b.branchId === branch._id);
         if (!existingBranch) {
            existingBranch = {
               branchId: branch._id,
               branchDetails: branch,
               branchComplaints: [],
            };
            company.branches.push(existingBranch);
         }
      });

      companies.forEach((company) => {
         const companyId = company._id;
         let existingCompany = groupedCompanies.find((c) => c.companyId === companyId);
         if (!existingCompany) {
            existingCompany = {
               companyId: companyId,
               companyDetails: company.company_details,
               complaints: [],
               branches: [],
               companyComplaints: [],
               group: company.group,
               person_details: company.person_details,
            };
            groupedCompanies.push(existingCompany);
         } else {
            existingCompany.companyDetails = company.company_details;
            existingCompany.group = company.group;
            existingCompany.person_details = company.person_details;
         }
      });

      return groupedCompanies;
   }, [complaintsByStatus, branches, companies]);

   // Función auxiliar para obtener la fecha a partir de "createdAt" o "date"
   const getComplaintDate = (complaint) => {
      return complaint?.complaint?.createdAt ? dayjs(complaint.complaint.createdAt) : dayjs(complaint.complaint.date);
   };

   // Filtra quejas en un array basado en las fechas
   const filterComplaintsByDate = (complaintsArray, startDate, finishDate) => {
      // Verificamos que complaintsArray sea un array válido
      if (!Array.isArray(complaintsArray)) {
         return []; // Retornamos un array vacío si no es un array válido
      }

      return complaintsArray?.filter((item) => {
         const complaintDate = getComplaintDate(item);

         return complaintDate.isAfter(startDate) && complaintDate.isBefore(finishDate);
      });
   };

   // Filtramos complaintsByStatus por las fechas cada vez que cambian startDate o finishDate
   const filteredComplaints = filterComplaintsByDate(complaintsByStatus, startDate, finishDate);

   const isNotClosedOrUnfair = (status) => status !== "improcedente";

   const complaintsDivisionGroup = filteredComplaints?.reduce(
      (object, complaint) => {
         const temp = object;
         const { type, status } = complaint.complaint;

         if ((!type || type === "denuncia") && isNotClosedOrUnfair(status)) temp["denuncias"].push(complaint);
         else if (type === "queja" && isNotClosedOrUnfair(status)) temp["quejas"].push(complaint);
         else if (type === "sugerencia" && isNotClosedOrUnfair(status)) temp["sugerencias"].push(complaint);
         else if (status === "improcedente") temp["improcedentes"].push(complaint);
         return temp;
      },
      { denuncias: [], quejas: [], sugerencias: [], improcedentes: [] }
   );

   let TotalComplaints = useMemo(() => {
      return (
         complaintsDivisionGroup?.denuncias.length +
         complaintsDivisionGroup?.quejas.length +
         complaintsDivisionGroup?.sugerencias.length +
         complaintsDivisionGroup?.improcedentes.length
      );
   }, [complaintsDivisionGroup]);

   let filteredTotalComplain = complaintsDivisionGroup?.denuncias.length;
   let filteredTotalGrievance = complaintsDivisionGroup?.quejas.length;
   let filteredTotalSuggestions = complaintsDivisionGroup?.sugerencias.length;
   let filteredTotalUnfair = complaintsDivisionGroup?.improcedentes.length;

   const getpercentage = (value) => (value * 100 === 100 ? value * 100 : (value * 100).toFixed(1));

   const data = [
      {
         criterion: "Denuncias",
         interpositions: filteredTotalComplain,
         percentage: `${getpercentage(filteredTotalComplain / TotalComplaints)}%`,
      },
      {
         criterion: "Quejas",
         interpositions: filteredTotalGrievance,
         percentage: `${getpercentage(filteredTotalGrievance / TotalComplaints)}%`,
      },
      {
         criterion: "Sugerencias",
         interpositions: filteredTotalSuggestions,
         percentage: `${getpercentage(filteredTotalSuggestions / TotalComplaints)}%`,
      },
      {
         criterion: "Improcedentes",
         interpositions: filteredTotalUnfair,
         percentage: `${getpercentage(filteredTotalUnfair / TotalComplaints)}%`,
      },
   ];

   if (loadingComplaints)
      return (
         <Box py={2}>
            <NotificationBar head="Reportes y estadísticas" content={<></>} />
            <Container maxWidth="xl">
               <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                  <CircularProgress size={26} />
               </Box>
            </Container>
         </Box>
      );

   return (
      <Box sx={{ py: 2 }}>
         <NotificationBar head="Reportes y estadísticas" content={<></>} />

         <Container maxWidth="xl">
            <>
               {user.group && (
                  <Typography variant="h5" fontWeight={"700"} sx={{ p: 2, color: "#2d4357" }}>
                     Información general del grupo empresarial
                  </Typography>
               )}

               <Grid container spacing={2} sx={{ px: 2, mt: 2 }}>
                  {/* Fecha de inicio (Desde) */}
                  <Grid
                     item
                     xs={3}
                     sx={{
                        p: 2,
                        borderRadius: 1,
                     }}
                  >
                     <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                        <DatePicker
                           maxDate={finishDate}
                           label={"Desde"}
                           format="DD/MM/YYYY"
                           value={periodStartDate}
                           onChange={(newValue) => {
                              if (newValue) {
                                 setPeriodStartDate(newValue);
                                 setIsMonthly(false); // Cambia a modo período
                                 setMonthlyStartDate(null); // Limpia el valor del modo mensual
                                 setMonthlyFinishDate(null); // Limpia el valor del modo mensual
                              }
                           }}
                           slotProps={{ textField: { variant: "standard", sx: { px: 2 } } }}
                        />
                     </LocalizationProvider>
                  </Grid>

                  {/* Fecha de fin (Hasta) */}
                  <Grid
                     item
                     xs={3}
                     sx={{
                        p: 2,
                        borderRadius: 1,
                     }}
                  >
                     <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                        <DatePicker
                           minDate={periodStartDate}
                           label={"Hasta"}
                           format="DD/MM/YYYY"
                           value={periodFinishDate}
                           onChange={(newValue) => {
                              if (newValue) {
                                 setPeriodFinishDate(newValue);
                                 setIsMonthly(false); // Cambia a modo período
                                 setMonthlyStartDate(null); // Limpia el valor del modo mensual
                                 setMonthlyFinishDate(null); // Limpia el valor del modo mensual
                              }
                           }}
                           slotProps={{ textField: { variant: "standard", sx: { px: 2 } } }}
                        />
                     </LocalizationProvider>
                  </Grid>

                  {/* Selector Mensual (Mes y Año) */}
                  <Grid
                     item
                     xs={3}
                     sx={{
                        p: 2,
                        borderRadius: 1,
                     }}
                  >
                     <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                        <DatePicker
                           views={["month", "year"]}
                           label="Mensual"
                           maxDate={dayjs().endOf("month")}
                           value={monthlyStartDate}
                           onChange={(newValue) => {
                              if (newValue) {
                                 // Cuando seleccionamos un mes, establecemos el primer y último día del mes
                                 const startOfMonth = dayjs(newValue).startOf("month");
                                 const endOfMonth = dayjs(newValue).endOf("month");

                                 // Establecemos las fechas de inicio y fin con el mes seleccionado
                                 setMonthlyStartDate(startOfMonth);
                                 setMonthlyFinishDate(endOfMonth);

                                 // Cambia a modo mensual
                                 setIsMonthly(true);

                                 // Limpia los valores de período
                                 setPeriodStartDate(null);
                                 setPeriodFinishDate(null);
                              }
                           }}
                           slotProps={{ textField: { variant: "standard", sx: { px: 0 } } }}
                        />
                     </LocalizationProvider>
                  </Grid>
               </Grid>
               {filteredComplaints?.length > 0 ? (
                  <Box>
                     {companies.length > 1 && (
                        <Box sx={{ display: "flex", height: "auto", pt: 1, pb: 4 }}>
                           <Box flex={3} py={1}>
                              <Box
                                 sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                    height: "100%",
                                    backgroundColor: "#ffffff",
                                    borderRadius: 3,
                                    px: 2,
                                    boxShadow: 2,
                                    gap: 11,
                                    width: "1417.4px",
                                 }}
                              >
                                 <Grid container item xs={3} direction="column" pt={5}>
                                    <Grid>
                                       <Typography
                                          color={"#2d4357"}
                                          variant="h6"
                                          fontWeight={700}
                                          textAlign={"center"}
                                          fontSize={17}
                                          py={0}
                                       >
                                          Resumen de las interposiciones
                                       </Typography>
                                    </Grid>
                                    <Grid item lg={4} sx={{ py: 2 }} textAlign={"center"}>
                                       {UseCreateCharts({
                                          type: "doughnut",
                                          chartRef: newRef,
                                          colors: ["#152C44", "#486685", "#CBD5E0", "#A3A3A3"],
                                          labels: ["Denuncias", "Quejas", "Sugerencias", "Improcedentes"],
                                          scores: [
                                             filteredTotalComplain,
                                             filteredTotalGrievance,
                                             filteredTotalSuggestions,
                                             filteredTotalUnfair,
                                          ],
                                          cutout: 48,
                                          percentage: false,
                                          totalNumberDonut: TotalComplaints,
                                          key: TotalComplaints,
                                       })}
                                    </Grid>
                                 </Grid>
                                 <Grid
                                    container
                                    item
                                    lg={4}
                                    sx={{
                                       maxWidth: "280px",
                                       minWidth: "350px",
                                       display: "flex",
                                       justifyContent: "center",
                                    }}
                                 >
                                    <Box py={2}>
                                       <Grid container spacing={2}>
                                          <Grid item xs={4}>
                                             <Typography variant="h6" fontWeight={600} fontSize={17}>
                                                Criterios
                                             </Typography>
                                          </Grid>
                                          <Grid item xs={5} textAlign={"end"}>
                                             <Typography variant="h6" fontWeight={600} fontSize={17}>
                                                Interposiciones
                                             </Typography>
                                          </Grid>
                                          <Grid item xs={3} textAlign={"center"}>
                                             <Typography variant="h6" fontWeight={600} fontSize={17}>
                                                %
                                             </Typography>
                                          </Grid>
                                          {data.map((item, index) => (
                                             <React.Fragment key={`${item.criterion}`}>
                                                <Grid item xs={6}>
                                                   <Typography
                                                      variant="body1"
                                                      sx={{
                                                         display: "flex",
                                                         alignItems: "center",
                                                         gap: 1,
                                                      }}
                                                   >
                                                      <Box
                                                         sx={{
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor:
                                                               index === 0
                                                                  ? "#152C44"
                                                                  : index === 1
                                                                  ? "#486685"
                                                                  : index === 2
                                                                  ? "#CBD5E0"
                                                                  : "#A3A3A3",
                                                            borderRadius: "50%",
                                                         }}
                                                      />
                                                      {item.criterion}
                                                   </Typography>
                                                </Grid>
                                                <Grid item xs={3} textAlign={"end"}>
                                                   <Typography variant="body1" fontWeight={800}>
                                                      {item.interpositions}
                                                   </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                   <Typography variant="body1" textAlign={"end"} fontWeight={600}>
                                                      {item.percentage}
                                                   </Typography>
                                                </Grid>
                                             </React.Fragment>
                                          ))}
                                       </Grid>
                                    </Box>
                                    <Box>
                                       <Typography textAlign={"center"} pb={2} fontSize={12} maxWidth={"379px"}>
                                          *El porcentaje de interposiciones se obtendrá, tomando como 100% las
                                          denuncias, quejas y sugerencias de todas las empresas y sucursales
                                          registradas.
                                       </Typography>
                                    </Box>
                                 </Grid>
                                 <Grid container item lg={3} maxWidth={"300px"} gap={2} py={2.5}>
                                    <Box
                                       sx={{
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "start",
                                          flexDirection: "column",
                                          gap: 2,
                                       }}
                                    >
                                       <InfoBox
                                          title="EMPRESAS"
                                          value={filterComplaintsByDate.length}
                                          icon={fluent_building_multiple}
                                       />
                                       <InfoBox
                                          title="SUCURSALES"
                                          value={branches.length}
                                          icon={fluent_building_home}
                                       />
                                    </Box>
                                    <Box
                                       sx={{
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                       }}
                                    ></Box>
                                 </Grid>
                              </Box>
                           </Box>
                        </Box>
                     )}
                     <Box sx={{ marginTop: companies.length === 1 ? "70px" : "0", width: "1417.4px" }}>
                        <Typography variant="h5" fontWeight={"700"} sx={{ p: 2, color: "#2d4357" }}>
                           Información estadística específica por empresa
                        </Typography>
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              py: 1,
                              px: 7,
                           }}
                        >
                           <Typography variant="h6" sx={{ color: "#2d4357", fontWeight: 700 }}>
                              {"Selecciona una empresa"}
                           </Typography>
                           <TextField
                              label={"Buscar"}
                              variant="outlined"
                              size="small"
                              value={companyNameFilter}
                              InputProps={{
                                 endAdornment: (
                                    <InputAdornment position="end">
                                       <SearchIcon />
                                    </InputAdornment>
                                 ),
                              }}
                              onChange={(e) => {
                                 setCompanyNameFilter(e.target.value);
                                 setSeed(seed + 1);
                              }}
                           />
                        </Box>
                        <Box sx={{ width: "100%" }}>
                           <Box
                              sx={{
                                 maxWidth: "100%",
                                 display: "flex",
                                 justifyContent: "center",
                                 py: 2,
                              }}
                           >
                              <CompaniesCarousel
                                 key={seed}
                                 selectedCompany={setSelectedCompany}
                                 filterName={companyNameFilter}
                                 orientation={false}
                                 maxComponentNumber={5}
                              />
                           </Box>
                        </Box>
                     </Box>
                     {selectedCompany ? (
                        <StatisticsInfoBox
                           newRef={newRef}
                           typeChartRef={typeChartRef}
                           calificationChartRef={calificationChartRef}
                           companyInfo={companyInfo}
                           selectedCompany={selectedCompany}
                           startdate={startDate}
                           enddate={finishDate}
                        />
                     ) : (
                        <Box
                           sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "20px",
                              backgroundColor: "#1F2937", // Fondo opcional para mejorar la visibilidad del texto
                              height: "100%", // Asegura que el contenedor ocupe todo el espacio disponible
                           }}
                        >
                           <Typography fontWeight={600} color={"#E0E2E7"} textAlign="center">
                              No se ha seleccionado ninguna empresa
                           </Typography>
                        </Box>
                     )}
                  </Box>
               ) : (
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px",
                        backgroundColor: "#1F2937",
                        height: "100%",
                        marginTop: "5%",
                     }}
                  >
                     <Typography
                        variant="h6"
                        textAlign="center"
                        sx={{
                           fontWeight: "bold",
                           color: "#E0E2E7",
                           py: 3,
                        }}
                     >
                        No existen denuncias interpuestas para el periodo seleccionado
                     </Typography>
                  </Box>
               )}
            </>
         </Container>
      </Box>
   );
};
