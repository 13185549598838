import { Box, Button, CircularProgress, Grid, Modal, Typography } from "@mui/material";
import { Formik, FormikProps } from "formik";
import { Form } from "react-router-dom";
import { InputTextField } from "../../Inputs/InputTextField";
import { StudyItemComponent } from "./SubComponents/StudyItemComponent";
import { ModalAddStudyComponent } from "./SubComponents/ModalAddStudyComponent";
import { useContext, useEffect, useState } from "react";
import { PersonalInformationSchema } from "../../../lib/validations/inputSchemas";
import {
   getProfesionalDataById,
   getUserDetailsByUserId,
   updateProfesionalDataById,
} from "../../../lib/gobCorpBEClient";
import { UserContext } from "../../../context/userContext";
import { SnackBarContext } from "../../../context/snackBarContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import _ from "lodash";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";

const gridRow = {
   display: "flex",
   alignItems: "center",
};

const useGetStudys = (studyArray: any[]) => {
   const master: any[] = [];
   const career: any[] = [];
   const courses: any[] = [];
   for (const iterator of studyArray) {
      if (iterator.degree === "Estudios profesionales") career.push(iterator);
      if (iterator.degree === "Posgrado") master.push(iterator);
      if (iterator.degree === "Diplomados, cursos y certificaciones") courses.push(iterator);
   }
   return { master, courses, career };
};

const useGetProfesionalInfo = (id: string, seed, setStartDate) => {
   const [profesionalData, setProfesionalData] = useState(null);

   useEffect(() => {
      const fetch = async () => {
         try {
            const userDet = await getUserDetailsByUserId(id);
            const profData = await getProfesionalDataById(userDet?.profesionalData);
            setStartDate(dayjs(new Date(profData.positions?.startTime)));
            setProfesionalData(profData);
         } catch (error) {
            console.log(error);
         }
      };
      fetch();
   }, [seed]);

   return { profesionalData };
};

export const ProfesionalInformation = ({ setSeedProfesionalInformation }) => {
   const { user } = useContext(UserContext);
   const [seed, setSeed] = useState<number>(0);
   const [startDate, setStartDate] = useState<dayjs.Dayjs>();
   const { profesionalData } = useGetProfesionalInfo(user.id, seed, setStartDate);
   const { career, courses, master } = useGetStudys(profesionalData ? profesionalData.studyRecord : []);
   const [open, setOpen] = useState<boolean>(false);
   const [degree, setDegree] = useState<string>("");
   const [edit, setEdit] = useState<boolean>(false);
   const [data, setData] = useState(null);
   const [experienceRecord, setExperienceRecord] = useState<boolean>(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoading, setIsLoading] = useState<boolean>(false);

   const handleSubmit = async (values) => {
      setIsLoading(true);
      if (
         values.company === profesionalData.company &&
         values.actualPosition === profesionalData.positions?.name &&
         _.isEqual(startDate, dayjs(new Date(profesionalData.positions?.startTime)))
      )
         return setIsLoading(false);
      try {
         values.positions = { name: values.actualPosition, startTime: startDate };
         delete values.actualPosition;
         await updateProfesionalDataById(profesionalData._id, values).then(() => {
            showSnackBar("Datos actualizados correctamente", false);
            setIsLoading(false);
            setSeedProfesionalInformation(Math.random);
         });
      } catch (error) {
         console.log(error);
         setIsLoading(false);
         showSnackBar("Error al actualizar los datos", true);
      }
   };
   return (
      <>
         {profesionalData ? (
            <Box key={seed}>
               <Formik
                  initialValues={{
                     company: profesionalData.company || "",
                     actualPosition: profesionalData.positions?.name || "",
                     startDate: profesionalData.positions?.startTime || dayjs(new Date()),
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={PersonalInformationSchema}
               >
                  {(formProps: FormikProps<any>) => (
                     <Form>
                        <Grid container rowSpacing={6}>
                           <Grid item xs={4} sx={gridRow}>
                              Empresa
                           </Grid>
                           <Grid item xs={7}>
                              <InputTextField
                                 id="company"
                                 label="Empresa"
                                 name="company"
                                 type="string"
                                 size={"small"}
                                 value={formProps.values.company}
                                 fullWidth
                              />
                           </Grid>
                           <Grid item xs={4} sx={gridRow}>
                              Puesto actual
                           </Grid>
                           <Grid item container xs={7} sx={{ display: "flex", columnGap: 3 }}>
                              <Box sx={{ width: "60%" }}>
                                 <InputTextField
                                    id="actualPosition"
                                    name="actualPosition"
                                    type="string"
                                    size={"small"}
                                    fullWidth
                                 />
                              </Box>
                              <Box sx={{ width: "35%" }}>
                                 <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                                    <DatePicker
                                       maxDate={dayjs(new Date())}
                                       label={"Fecha de inicio"}
                                       format="DD/MM/YYYY"
                                       value={dayjs(new Date(profesionalData.positions?.startTime))}
                                       onChange={(event: any) => setStartDate(dayjs(event.$d))}
                                       slotProps={{ textField: { variant: "outlined", size: "small" } }}
                                       disableFuture
                                    />
                                 </LocalizationProvider>
                              </Box>
                           </Grid>
                           <Grid item xs={4} sx={gridRow}>
                              Experiencia profesional
                           </Grid>
                           <Grid item xs={8}>
                              <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
                                 {profesionalData.experienceRecord.map((e, index) => (
                                    <StudyItemComponent
                                       data={e}
                                       key={index}
                                       setOpen={setOpen}
                                       setEdit={setEdit}
                                       setData={setData}
                                       experienceRecord={true}
                                       setExperienceRecord={setExperienceRecord}
                                    />
                                 ))}
                              </Box>
                              <Button
                                 onClick={() => {
                                    setEdit(false);
                                    setOpen(true);
                                    setDegree("Experiencia profesional");
                                    setExperienceRecord(true);
                                 }}
                              >
                                 <Typography fontSize={12} color="#969696">
                                    Agregar nuevo
                                 </Typography>
                              </Button>
                           </Grid>
                           <Grid item xs={4} sx={gridRow}>
                              Estudios profesionales
                           </Grid>
                           <Grid item xs={8}>
                              <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
                                 {career?.map((e, index) => (
                                    <StudyItemComponent
                                       data={e}
                                       key={index}
                                       setOpen={setOpen}
                                       setEdit={setEdit}
                                       setData={setData}
                                       experienceRecord={false}
                                       setExperienceRecord={setExperienceRecord}
                                    />
                                 ))}
                              </Box>
                              <Button
                                 onClick={() => {
                                    setEdit(false);
                                    setOpen(true);
                                    setExperienceRecord(false);
                                    setDegree("Estudios profesionales");
                                 }}
                              >
                                 <Typography fontSize={12} color="#969696">
                                    Agregar nuevo
                                 </Typography>
                              </Button>
                           </Grid>
                           <Grid item xs={4} sx={gridRow}>
                              Posgrado
                           </Grid>
                           <Grid item xs={8}>
                              <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
                                 {master?.map((e, index) => (
                                    <StudyItemComponent
                                       data={e}
                                       key={index}
                                       setOpen={setOpen}
                                       setEdit={setEdit}
                                       setData={setData}
                                       experienceRecord={false}
                                       setExperienceRecord={setExperienceRecord}
                                    />
                                 ))}
                              </Box>
                              <Grid item xs={8}>
                                 <Button
                                    onClick={() => {
                                       setOpen(true);
                                       setDegree("Posgrado");
                                       setEdit(false);
                                       setExperienceRecord(false);
                                    }}
                                 >
                                    <Typography fontSize={12} color="#969696">
                                       Agregar nuevo
                                    </Typography>
                                 </Button>
                              </Grid>
                           </Grid>

                           <Grid item xs={4} sx={gridRow}>
                              Diplomados, cursos y certificaciones
                           </Grid>
                           <Grid item xs={8}>
                              <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
                                 {courses?.map((e, index) => (
                                    <StudyItemComponent
                                       data={e}
                                       key={index}
                                       setOpen={setOpen}
                                       setEdit={setEdit}
                                       setData={setData}
                                       experienceRecord={false}
                                       setExperienceRecord={setExperienceRecord}
                                    />
                                 ))}
                              </Box>
                              <Grid item xs={8}>
                                 <Button
                                    onClick={() => {
                                       setOpen(true);
                                       setEdit(false);
                                       setDegree("Diplomados, cursos y certificaciones");
                                       setExperienceRecord(false);
                                    }}
                                 >
                                    <Typography fontSize={12} color="#969696">
                                       Agregar nuevo
                                    </Typography>
                                 </Button>
                              </Grid>
                           </Grid>
                           <Box sx={{ justifyContent: "flex-end", display: "flex", flex: 1, mt: 4 }}>
                              {isLoading ? (
                                 <CircularProgress />
                              ) : (
                                 <Button
                                    variant="contained"
                                    disabled={isLoading}
                                    onClick={() => {
                                       formProps.submitForm();
                                    }}
                                 >
                                    Guardar cambios
                                 </Button>
                              )}
                           </Box>
                        </Grid>
                     </Form>
                  )}
               </Formik>
            </Box>
         ) : (
            <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
               <CircularProgress sx={{ size: 24 }} />
            </Box>
         )}
         {profesionalData ? (
            <Modal open={open} onClose={() => setOpen(false)}>
               <>
                  <ModalAddStudyComponent
                     open={open}
                     setOpen={setOpen}
                     degree={degree}
                     edit={edit}
                     data={data}
                     experienceRecord={experienceRecord}
                     profDataId={profesionalData._id}
                     setSeed={setSeed}
                  />
               </>
            </Modal>
         ) : (
            <></>
         )}
      </>
   );
};
