import { Badge, Typography } from "@mui/material";
import {
   CampaignOutlined,
   ContentPasteSearch,
   Email,
   InsertChartOutlinedSharp,
   DrawOutlined,
} from "@mui/icons-material";
import { useContext } from "react";
import { UserContext } from "../context/userContext";
import { ListItemComponent } from "./ListItemComponent";
import { ComplaintContext } from "../context/complaintContext";

const menuOptions = [
   {
      lable: "Búzon de entrada",
      icon: <Email sx={{ color: "#162c44" }} />,
      submenu: [
         {
            lable: "Denuncias en proceso",
            path: "/canal-denuncias/recepcion-denuncias/denuncias-proceso",
         },
         {
            lable: "Denuncias finalizadas",
            path: "/canal-denuncias/recepcion-denuncias/denuncias-finalizadas",
         },
      ],
   },
   {
      lable: "Firmas pendientes",
      path: "/canal-denuncias/firmas",
      icon: <DrawOutlined sx={{ color: "#162c44" }} />,
   },
   {
      lable: "Campañas y difusión",
      path: "/canal-denuncias/difusion/plan-difusion",
      icon: <CampaignOutlined sx={{ color: "#162c44" }} />,
   },
   {
      lable: "Reportes y estadísticas",
      path: "/canal-denuncias/estadisticas-reportes/estadisticas",
      icon: <InsertChartOutlinedSharp sx={{ color: "#162c44" }} />,
   },
   {
      lable: "Banco de consultas",
      path: "/canal-denuncias/banco-consultas",
      icon: <ContentPasteSearch sx={{ color: "#162c44" }} />,
   },
];

interface ComplaintChannelMenuProps {
   open: boolean;
   setOpen: Function;
}

export const ComplaintChannelMenu = (props: ComplaintChannelMenuProps) => {
   const { verifyAccess } = useContext(UserContext);
   const { NewComplaints } = useContext(ComplaintContext);

   const firmasPermission = verifyAccess("Firmas");
   const difusionPermission = verifyAccess("Difusión-Reportes-Banco");
   const denunciasPermission = verifyAccess("Denuncias externas");

   const hasPermission = (index) => {
      if (index === 0) return denunciasPermission;
      if (index === 1) return firmasPermission;
      if (index >= 2) return difusionPermission;
      return true;
   };

   return (
      <>
         <Typography sx={{ px: 3, fontWeight: 520, color: "gray", fontSize: 14, pt: 2, pb: 1 }}>
            {props.open ? "CANAL DE DENUNCIAS" : "C"}
         </Typography>
         {menuOptions.map((item, index) => {
            if (!hasPermission(index)) return null;
            const listItem = (
               <ListItemComponent item={item} index={index} open={props.open} setOpen={props.setOpen} key={item.path} />
            );
            return index === 0 && NewComplaints > 0 ? (
               <Badge badgeContent={NewComplaints} color="warning" key={item.path}>
                  {listItem}
               </Badge>
            ) : (
               listItem
            );
         })}
      </>
   );
};
