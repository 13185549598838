import { createRoutesFromElements, createBrowserRouter, RouterProvider } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useExternManagementRoutes } from "./routes/externManagementRoutes";
import { useEffect, useState } from "react";

export default function App() {
   const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

   useEffect(() => {
      const checkForUpdate = async () => {
         try {
            const response = await fetch("/version.json", { cache: "no-store" });
            const data = await response.json();
            const currentVersion = process.env.REACT_APP_VERSION;
            console.log(currentVersion);

            if (data.version !== currentVersion) {
               setIsNewVersionAvailable(true);
            }
         } catch (error) {
            console.error("Error checking for updates:", error);
         }
      };

      const validation =
         window.location.hostname === "test.web.lecosy.com.mx" ||
         window.location.hostname === "www.test.web.lecosy.com.mx"
            ? true
            : process.env.NODE_ENV === "production"
            ? true
            : false;

      if (validation) {
         const interval = setInterval(checkForUpdate, 60000); // Check every minute
         return () => clearInterval(interval);
      }
   }, []);

   const reloadPage = () => {
      window.location.reload();
   };

   const externManagementRoutes = useExternManagementRoutes();

   const router = createBrowserRouter(createRoutesFromElements([externManagementRoutes]));

   return (
      <>
         <RouterProvider
            router={router}
            fallbackElement={
               <Box
                  sx={{
                     height: "100vh",
                     width: "100vw",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     flexDirection: "column",
                  }}
               >
                  <Typography>Cargando, espere un momento...</Typography>
                  <CircularProgress sx={{ color: "#162c44" }} />
               </Box>
            }
         />
         <Modal
            open={isNewVersionAvailable}
            onClose={null}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            disableEscapeKeyDown
            sx={{ display: "flex" }}
         >
            <Box sx={{ m: "auto", borderRadius: 2 }}>
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     flexDirection: "column",
                     bgcolor: "#162c44",
                     p: 2,
                     borderTopLeftRadius: 5,
                     borderTopRightRadius: 5,
                  }}
               >
                  <Typography variant="h5" color={"white"} fontWeight={600}>
                     Actualización requerida
                  </Typography>
               </Box>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     justifyContent: "center",
                     alignItems: "center",
                     // border: "solid #EBEBEB",
                     borderWidth: "thin 0px",
                     bgcolor: "white",
                     borderBottomLeftRadius: 5,
                     borderBottomRightRadius: 5,
                     p: 4,
                     rowGap: 2,
                  }}
               >
                  <Typography id="modal-description">Una versión nueva se encuentra disponible.</Typography>
                  <Typography id="modal-description">
                     Para evitar problemas de incompatibilidades, favor de reiniciar la pagina.
                  </Typography>
                  <Button
                     onClick={reloadPage}
                     variant="contained"
                     sx={{
                        bgcolor: "#162c44",
                        color: "white",
                        "&:hover": {
                           bgcolor: "#162c44",
                           color: "white",
                        },
                     }}
                  >
                     Reiniciar página
                  </Button>
               </Box>
            </Box>
         </Modal>
      </>
   );
}
