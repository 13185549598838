import { Grid, IconButton, Stack, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useContext, useState } from "react";
import { BillCommitmentForm } from "./BillCommitmentForm";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";
import { useFormikContext } from "formik";

export const BillCommitment = ({ commitment, index }) => {
   const { hasPermissions, session, valuesFromBill } = useContext(GovernanceSessionContext);
   const [modifyCommitment, setModifyCommitment] = useState(false);
   const { setFieldValue } = useFormikContext();

   const gridWidthConst = hasPermissions ? (session.group ? 11.3 / 4 : 11.3 / 3) : session.group ? 3 : 4;

   const handleEdit = () => {
      setFieldValue("responsableNameEdit" + index, commitment["users"]);
      setFieldValue("activityEdit" + index, commitment["activity"]);
      setFieldValue("commitmentCompaniesEdit" + index, commitment["companies"]);
      setFieldValue("limitDateEdit" + index, commitment["limitDate"]);
      if (
         valuesFromBill["responsableNameEdit" + index] &&
         valuesFromBill["activityEdit" + index] &&
         valuesFromBill["commitmentCompaniesEdit" + index] &&
         valuesFromBill["limitDateEdit" + index]
      )
         setModifyCommitment(!modifyCommitment);
   };

   return (
      <>
         {modifyCommitment ? (
            <BillCommitmentForm
               isEdit={true}
               setModifyCommitment={setModifyCommitment}
               modifyCommitment={modifyCommitment}
               commitmentsValues={commitment}
               index={index}
            />
         ) : (
            <Grid
               item
               container
               xs={12}
               columnSpacing={0.2}
               sx={{
                  "&:hover": {
                     "& .iconButton": {
                        opacity: 1,
                     },
                  },
               }}
            >
               <Grid
                  item
                  xs={gridWidthConst}
                  sx={{
                     display: "flex",
                     bgcolor: "#EFEFEF",
                     height: "100%",
                     alignItems: "center",
                     justifyContent: "center",
                  }}
               >
                  <Typography
                     sx={{
                        fontSize: 12,
                        textAlign: "center",
                        whiteSpace: "pre-line",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                     }}
                  >
                     {commitment?.users?.map((u) => `${u?.firstName || u?.email} ${u?.lastName || ""}`)?.join(", ")}
                  </Typography>
               </Grid>
               <Grid
                  item
                  xs={gridWidthConst}
                  sx={{
                     display: "flex",
                     bgcolor: "#EFEFEF",
                     height: "100%",
                     borderLeft: 1,
                     borderColor: "white",
                     alignItems: "center",
                     justifyContent: "center",
                  }}
               >
                  <Typography sx={{ bgcolor: "#EFEFEF", fontSize: 12, textAlign: "center" }}>
                     {commitment?.activity}
                  </Typography>
               </Grid>
               {session.group && (
                  <Grid
                     item
                     xs={hasPermissions ? 11.3 / 4 : 3}
                     sx={{
                        display: "flex",
                        bgcolor: "#EFEFEF",
                        height: "100%",
                        borderLeft: 1,
                        borderColor: "white",
                        alignItems: "center",
                        justifyContent: "center",
                     }}
                  >
                     <Stack>
                        {commitment?.companies?.map((company, index) => (
                           <Typography
                              key={`${index}-${company?.person_details?.comercialName}`}
                              sx={{ fontSize: 12, textAlign: "center" }}
                           >
                              {company?.person_details?.comercialName || ""}
                           </Typography>
                        ))}
                     </Stack>
                  </Grid>
               )}

               <Grid
                  item
                  xs={gridWidthConst}
                  sx={{
                     display: "flex",
                     bgcolor: "#EFEFEF",
                     height: "100%",
                     borderLeft: 1,
                     borderColor: "white",
                     alignItems: "center",
                     justifyContent: "center",
                  }}
               >
                  <Typography sx={{ bgcolor: "#EFEFEF", fontSize: 12, textAlign: "center" }}>
                     {Number(new Date(commitment?.limitDate).toLocaleDateString("es-mx").split("/")[2]) >= 3000
                        ? "Indefinido"
                        : commitment?.limitDate?.split("T")[0] || "Indefinido"}
                  </Typography>
               </Grid>
               {hasPermissions && (
                  <Grid
                     item
                     xs={2 / 3}
                     sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                     }}
                  >
                     <IconButton
                        className="iconButton"
                        onClick={handleEdit}
                        sx={{
                           p: 0,
                           m: 0,
                           ml: 0.2,
                           opacity: 0,
                        }}
                     >
                        <EditIcon sx={{ fontSize: 16 }} />
                     </IconButton>
                  </Grid>
               )}
            </Grid>
         )}
      </>
   );
};
