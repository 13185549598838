import { ISubMenuOptions } from "../../screens/Gobierno corporativo/SubMenu";

export const generalOptions: ISubMenuOptions[] = [
   {
      label: "Calendario",
      path: "calendario",
   },
   // {
   //    label: "Tablero de avisos",
   //    path: "tablero",
   // },
   {
      label: "Gestión documental",
      path: "documentos",
   },
   {
      label: "Acuerdos y compromisos",
      path: "acuerdos",
   },
   {
      label: "Reportes",
      path: "reportes",
   },
];

export const subMenuMyAccountOptions: ISubMenuOptions[] = [
   {
      label: "Calendario",
      path: "calendario",
   },
   {
      label: "Mis notas",
      path: "notas",
   },
   {
      label: "Mis archivos",
      path: "archivos",
   },
   {
      label: "Acuerdos y compromisos",
      path: "acuerdos",
   },
   {
      label: "Reportes",
      path: "reportes",
   },
];
