import { Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography, Button } from "@mui/material";
import StringToHTMLOutput from "../../Inputs/TextEditor/StringToHTMLOutput";
import { getDateFromISODate } from "../../../const/globalConst";
import { UserContext } from "../../../context/userContext";
import { useContext, useState } from "react";
import { Delete, Edit, MoreHoriz } from "@mui/icons-material";
import { ConfirmationModal } from "../../ConfirmationModal";
import { deleteAdviceById } from "../../../lib/gobCorpBEClient";
import { SnackBarContext } from "../../../context/snackBarContext";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { getAllResources } from "../../../lib/usersBEClient";
import { CustomModalComponent } from "../../CustomModalComponent";
import { ModalAddAdviceComponent } from "./AddAdviceModal";

interface AdviceCardComponentProps {
   advice: any;
   author: any;
   expanded: boolean;
   onHover: () => void;
}

export const AdviceCardComponent = (props: AdviceCardComponentProps) => {
   const { user } = useContext(UserContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { refetchTasks, setRefetchTasks, companySelected } = useContext(GovernanceContext);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [openDeleteModal, setOpenDeleteModal] = useState(false);
   const [openModifyModal, setOpenModifyModal] = useState(false);
   const open = Boolean(anchorEl);
   const [isExpanded, setIsExpanded] = useState(false);

   const descriptionText = props.advice.description;

   const getTruncatedText = (text: string, limit: number) => {
      if (text.length <= limit) {
         return text;
      }
      return text.substring(0, limit) + "...";
   };

   const displayText = isExpanded ? descriptionText : getTruncatedText(descriptionText, 100);

   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleDelete = async () => {
      const resourceResponse = await getAllResources();
      const resourceId = resourceResponse.find((r) => r.name === "Tablero de avisos")._id;
      if (resourceId.trim().length === 0) return showSnackBar("Error al eliminar aviso", true);
      if (props.author.user !== user.id) return;
      try {
         await deleteAdviceById(props.advice._id, resourceId, companySelected);
      } catch (error) {
         console.log(error);
         showSnackBar("Error al eliminar el aviso", true);
      } finally {
         setOpenDeleteModal(false);
         showSnackBar("Aviso eliminado con exito", false);
         setRefetchTasks(!refetchTasks);
      }
   };

   const toggleExpanded = () => {
      setIsExpanded(!isExpanded);
   };

   return (
      <Box
         sx={{
            borderRadius: "2px",
            bgcolor: "white",
            display: "flex",
            flexDirection: "column",
            height: props.expanded ? "auto" : 90,
            width: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
               width: 0,
            },
            "&:hover": {
               color: "primary.main",
               height: "auto",
               borderBottom: "0px solid",
            },
         }}
         onMouseEnter={props.onHover}
      >
         <Box
            sx={{
               width: "93%",
               height: 15,
               minHeight: 15,
               borderTopRightRadius: 10,
               borderTopLeftRadius: 10,
               bgcolor: "#162C44",
               mx: "8px",
            }}
         />
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               overflowY: "auto",
               px: 2,
               py: 3,
               "&::-webkit-scrollbar": {
                  display: "none",
               },
               "-ms-overflow-style": "none",
               "scrollbar-width": "none",
            }}
         >
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "90%" }}>
               <Typography
                  fontWeight="bold"
                  mb={2}
                  px={1}
                  sx={{
                     wordWrap: "break-word",
                     overflow: "hidden",
                  }}
               >
                  {props.advice.title}
               </Typography>
               {
                  <Box>
                     {props.author?.user === user.id ? (
                        <>
                           <IconButton
                              aria-label="more"
                              id="more-button"
                              aria-controls={open ? "more-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={handleClick}
                              sx={{ p: 0 }}
                           >
                              <MoreHoriz />
                           </IconButton>
                           <Menu
                              id="more-menu"
                              MenuListProps={{
                                 "aria-labelledby": "more-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                           >
                              <MenuItem
                                 onClick={(e) => {
                                    setAnchorEl(null);
                                    setOpenDeleteModal(true);
                                 }}
                              >
                                 <ListItemIcon>
                                    <Delete />
                                 </ListItemIcon>
                                 <Typography>Eliminar</Typography>
                              </MenuItem>
                              <MenuItem
                                 onClick={(e) => {
                                    setAnchorEl(null);
                                    setOpenModifyModal(true);
                                 }}
                              >
                                 <ListItemIcon>
                                    <Edit />
                                 </ListItemIcon>
                                 <Typography>Editar</Typography>
                              </MenuItem>
                           </Menu>
                           <Box>
                              <ConfirmationModal
                                 open={openDeleteModal}
                                 setOpen={setOpenDeleteModal}
                                 onConfirm={handleDelete}
                                 title={"Eliminar aviso"}
                                 body={
                                    <>
                                       <Box sx={{ textAlign: "center" }}>
                                          <Typography>¿Está seguro que desea eliminar este aviso?</Typography>
                                          <Typography
                                             sx={{ display: "flex", justifyContent: "left" }}
                                             fontStyle={"italic"}
                                             color={"red"}
                                             fontSize={13}
                                          >
                                             Una vez confirmado, la información no se podrá recuperar
                                          </Typography>
                                       </Box>
                                    </>
                                 }
                              />
                           </Box>
                        </>
                     ) : null}
                  </Box>
               }
            </Box>
            <Box sx={{ width: "100%" }}>
               <StringToHTMLOutput text={displayText} sx={{ px: 1, py: 2, fontSize: 12, textAlign: "justify" }} />
               {descriptionText.length > 100 && (
                  <Button onClick={toggleExpanded} size="small" sx={{}}>
                     {isExpanded ? "Leer menos" : "Leer más"}
                  </Button>
               )}
            </Box>
            <Divider sx={{ py: 2 }} />
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 1,
               }}
            >
               <Typography fontSize={12}>
                  {props.author?.firstName} {props.author?.lastName}
               </Typography>
               <Typography fontSize={12}>{getDateFromISODate(props.advice.createdAt)} </Typography>
            </Box>
         </Box>
         <CustomModalComponent open={openModifyModal} setOpen={setOpenModifyModal} title="Modificar aviso" timeStamp>
            <ModalAddAdviceComponent open={openModifyModal} setOpen={setOpenModifyModal} adviceData={props.advice} />
         </CustomModalComponent>
      </Box>
   );
};
