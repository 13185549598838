import { CustomModalComponent } from "../../CustomModalComponent";
import {
   Box,
   Button,
   CircularProgress,
   Collapse,
   FormGroup,
   Grid,
   ListItemText,
   MenuItem,
   Typography,
} from "@mui/material";
import { InputSelectField } from "../../Inputs/InputSelectField";
import { Formik, Form } from "formik";
import { shareHolderUpdateRequestTemplate } from "../../../lib/documentsTemplates/beneficiary/shareHolderUpdateRequestTemplate";
import { UserContext } from "../../../context/userContext";
import { useContext, useState } from "react";
import {
   createMultipleFileAsync,
   createMultipleRequestChangesBeneficiary,
   createRequestChangesBeneficiary,
   getFoldersAndFilesById,
   getFoldersById,
   getFoldersByIdsExpedient,
   getManyCompaniesById,
   getUrlS3,
   getUserByRoleAndEntity,
   sendFilesByMailAsync,
} from "../../../lib/usersBEClient";
import { pdf } from "@react-pdf/renderer";
import { uploadFileToS3 } from "../../../lib/s3Client";
import { SnackBarContext } from "../../../context/snackBarContext";
import { beneficiaryUpdateRequestTemplate } from "../../../lib/documentsTemplates/beneficiary/beneficiaryUpdateRequestTemplate";
import _ from "lodash";
import { BeneficiaryControllerContext } from "../../../context/beneficiaryContext/beneficiaryContollerContext";
import { cs } from "date-fns/locale";

interface UpdateModalProps {
   state: boolean;
   setState: (open: boolean) => void;
   onConfirm: () => void;
   timeStamp?: boolean;
   companyId: string;
   members: any[];
   beneficiaries: any[];
}

const options = ["Todos", "Documentos rechazados", "Documentos específicos"];

export const getFormattedDataTime = (timestamp: Date) => {
   const fecha = new Date();
   const day = fecha.getDate();
   const month = fecha.toLocaleString("es-ES", { month: "long" });
   const year = fecha.getFullYear();
   const hours24 = fecha.getHours();
   const hours12 = hours24 % 12 || 12;
   const minutes = fecha.getMinutes().toString().padStart(2, "0");
   const seconds = fecha.setSeconds(fecha.getSeconds());

   const amPm = hours24 >= 12 ? "PM" : "AM";

   return {
      dateString: `${day} de ${month} del ${year}`,
      timeString: `${hours12}:${minutes}:${seconds} ${amPm}`,
      fullString: `Solicitud de actualización de información - ${day} de ${month} del ${year} ${hours12}:${minutes}:${seconds}${amPm}.pdf`,
   };
};

export const UpdateModal = (props: UpdateModalProps) => {
   const { state, setState } = props;
   const { companySelected, user } = useContext(UserContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { configurationData } = useContext(BeneficiaryControllerContext);
   const [isLoading, setIsLoading] = useState(false);

   const optionsFiles = configurationData?.files.map((file) => file.fileName) || [];

   const handleSubmit = async (values: any) => {
      if (!values.selectedMembersArray || values.selectedMembersArray.length === 0) {
         showSnackBar("Favor de seleccionar usuario", true);
         return;
      }
      if (!values.selectedDocuments) {
         showSnackBar("Favor de seleccionar documentos", true);
         return;
      }
      if (
         values.selectedDocuments === "Documentos específicos" &&
         (!values.specificDocuments || values.specificDocuments.length === 0)
      ) {
         showSnackBar("Favor de seleccionar al menos un documento", true);
         return;
      }
      setIsLoading(true);
      try {
         switch (values.selectedDocuments) {
            case "Documentos específicos":
               await handleCreateFiles(values, values.specificDocuments);
               break;
            case "Documentos rechazados":
               await handleFilesRejected(values);
               break;
            case "Todos":
               await handleCreateFiles(values, optionsFiles);
               break;
            default:
               showSnackBar("Error, intente de nuevo.", true);
               break;
         }
         setIsLoading(false);
         setState(false);
      } catch (error) {
         setIsLoading(false);
         console.log(error);
         showSnackBar("Error al enviar solicitudes.", true);
      }
   };

   const handleFilesRejected = async (values) => {
      try {
         const users = values.selectedMembersArray.filter((member) => member.nameOg);

         const companyIds: string[] = _.uniq(users.flatMap((u) => u.companyId));
         let companies = await getManyCompaniesById(companyIds);
         companies = await Promise.all(
            companies.map(async (c) => {
               const president = await getUserByRoleAndEntity("651b06f1f721833c5e8724d9", c._id);
               let presidentName = "";
               if (president && president.length > 0) {
                  presidentName = `${president[0].user.firstName} ${president[0].user.lastName}`;
               }
               return {
                  ...c,
                  president: presidentName,
               };
            })
         );

         const usersRequired = await Promise.all(
            users.map(async (user) => {
               const filesNeeded = await Promise.all(
                  user.files.map(async (file) => {
                     const response = await getFoldersAndFilesById([file.folderId], companySelected._id);
                     const childrenResponse = response?.folder?.children?.[0]
                        ? await getFoldersAndFilesById([response.folder.children[0]._id], companySelected._id)
                        : null;
                     return childrenResponse?.files
                        ?.filter((f) => f.beneficiary?.needUpdate && !f.beneficiary.verified)
                        .map((f) => f.name.split(" - ")[0]);
                  })
               );
               const uniqueFiles = [...new Set(filesNeeded.flat().filter(Boolean))];
               if (uniqueFiles.length > 0) return { user, files: uniqueFiles };
            })
         );
         const filteredUsersRequired = usersRequired.filter(Boolean);

         const getFormattedDataTime = new Date().toLocaleDateString("es-MX", {
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            month: "long",
            year: "numeric",
         });

         for (const beneficiary of filteredUsersRequired) {
            const filteredCompanies = companies.filter((c) => beneficiary.user.companyId.includes(c.companyId));

            const requestsCreation = await createRequestChangesBeneficiary({
               beneficiary: [{ beneficiary: beneficiary.user._id, finished: false }],
               files: beneficiary.files,
            });

            const userfiles = await Promise.all(
               filteredCompanies.map(async (c) => {
                  const folderControl = await getFoldersByIdsExpedient(beneficiary.user.folderId, c._id);
                  return {
                     name: `Solicitud de actualización de información - ${getFormattedDataTime}pdf`,
                     owner: user.id,
                     size: 1,
                     type: "application/pdf",
                     folder: folderControl,
                     fileDirection: `beneficiaries/${c._id}/${folderControl}`,
                  };
               })
            );
            const files = await createMultipleFileAsync(userfiles);

            await Promise.all(
               files.map(async (element, index) => {
                  const template =
                     beneficiary.user.legalPerson === "Accionista"
                        ? shareHolderUpdateRequestTemplate
                        : beneficiaryUpdateRequestTemplate;

                  const blob = await pdf(
                     template(
                        filteredCompanies[index].logo,
                        filteredCompanies[index].company_details.primaryColor,
                        beneficiary.user.nameOg,
                        filteredCompanies[index].president,
                        "Hermosillo",
                        "Sonora",
                        beneficiary.files
                     )
                  ).toBlob();

                  await uploadFileToS3(element.urlToUpload, blob);
               })
            );

            const fileDataArray = files.map((file) => ({
               file: file.file,
               user: {
                  extern: !beneficiary.user.user,
                  beneficiary: beneficiary.user._id,
                  email: beneficiary.user.user ? beneficiary.user.user.email : beneficiary.user.email,
               },
               requestChangesId: requestsCreation.id,
            }));
            await sendFilesByMailAsync(fileDataArray);
         }
         showSnackBar("Solicitudes enviadas correctamente", false);
      } catch (error) {
         showSnackBar("Error al enviar solicitudes.", true);
      }
   };

   const handleCreateFiles = async (values, options) => {
      const users = values.selectedMembersArray.filter((member) => member.nameOg);
      const companyIds: string[] = _.uniq(users.flatMap((u) => u.companyId));
      let companies = await getManyCompaniesById(companyIds);
      companies = await Promise.all(
         companies.map(async (c) => {
            const president = await getUserByRoleAndEntity("651b06f1f721833c5e8724d9", c._id);
            let presidentName = "";
            if (president && president.length > 0) {
               presidentName = `${president[0].user.firstName} ${president[0].user.lastName}`;
            }
            return {
               ...c,
               president: presidentName,
            };
         })
      );

      const requestChangesCreation = await createRequestChangesBeneficiary({
         beneficiary: users.map((benef) => ({
            beneficiary: benef._id,
            finished: false,
         })),
         files: options,
      });

      const getFormattedDataTime = new Date().toLocaleDateString("es-MX", {
         day: "2-digit",
         hour: "2-digit",
         minute: "2-digit",
         second: "2-digit",
         month: "long",
         year: "numeric",
      });

      for (const company of companies) {
         const usersInCompany = users.filter((u) => u.companyId.includes(company._id));

         const userfiles = await Promise.all(
            usersInCompany.map(async (userV) => {
               const folderControl = await getFoldersByIdsExpedient(userV.folderId, company._id);
               return {
                  name: `Solicitud de actualización de información - ${getFormattedDataTime}pdf`,
                  owner: user.id,
                  size: 1,
                  type: "application/pdf",
                  folder: folderControl,
                  fileDirection: `beneficiaries/${company._id}/${folderControl}`,
               };
            })
         );

         const files = await createMultipleFileAsync(userfiles);

         await Promise.all(
            files.map(async (element, index) => {
               const template =
                  usersInCompany[index].legalPerson === "Accionista"
                     ? shareHolderUpdateRequestTemplate
                     : beneficiaryUpdateRequestTemplate;

               const blob = await pdf(
                  template(
                     company.logo,
                     company.company_details.primaryColor,
                     usersInCompany[index].nameOg,
                     company.president || "",
                     "Hermosillo",
                     "Sonora",
                     options
                  )
               ).toBlob();

               await uploadFileToS3(element.urlToUpload, blob);
            })
         );

         const fileDataArray = usersInCompany.map((userV, index) => ({
            file: files[index].file,
            user: {
               extern: !userV.user,
               beneficiary: userV._id,
               email: userV.user ? userV.user.email : userV.email,
               additionalEmail: userV.additionalEmail || "",
            },
            requestChangesId: requestChangesCreation.id,
         }));

         await sendFilesByMailAsync(fileDataArray);
      }
      showSnackBar("Solicitudes enviadas correctamente", false);
   };

   const getAllExternalUsers = () => {
      return props.beneficiaries.filter((benef) => benef.email && benef.type !== "Persona moral");
   };

   const getAllBeneficiaries = () => {
      return props.beneficiaries.filter((benef) => benef.type !== "Persona moral" && !benef.email);
   };

   return (
      <CustomModalComponent
         open={state}
         setOpen={setState}
         timeStamp={props.timeStamp}
         onClose={() => setState(false)}
         title="Solicitar Actualización de Documentos"
      >
         <Box sx={{ width: 600 }}>
            <Formik
               initialValues={{
                  selectedMembersArray: [],
                  selectedDocuments: null,
                  specificDocuments: [],
               }}
               onSubmit={handleSubmit}
            >
               {({ setFieldValue, values }) => (
                  <Box sx={{ m: 4 }}>
                     <Form>
                        <Grid container spacing={2}>
                           <Grid item xs={12}>
                              <Typography color={"#787486"}>Seleccionar Usuarios</Typography>
                           </Grid>
                           <Grid item xs={12}>
                              <FormGroup>
                                 <InputSelectField
                                    name="selectedMembersArray"
                                    multiple
                                    labelId="select-members-label"
                                    fullWidth
                                    id="select-members"
                                    onChange={(e: any) => {
                                       const selectedValues = e.target.value as any[];
                                       const newItem = selectedValues.pop();
                                       if (newItem === "Todos") {
                                          setFieldValue("selectedMembersArray", [
                                             ...getAllBeneficiaries(),
                                             ...getAllExternalUsers(),
                                          ]);
                                       } else {
                                          setFieldValue(
                                             "selectedMembersArray",
                                             [...selectedValues, newItem].filter(Boolean)
                                          );
                                       }
                                    }}
                                    renderValue={(selected) => selected.map((member: any) => member?.nameOg).join(", ")}
                                 >
                                    <MenuItem value={"Todos"}>
                                       <ListItemText primary={"Todos"} sx={{ my: -0.1 }} />
                                    </MenuItem>
                                    {getAllBeneficiaries().map((member, index) => (
                                       <MenuItem key={index} value={member}>
                                          <Typography>{`${member.nameOg}`}</Typography>
                                       </MenuItem>
                                    ))}
                                    {getAllExternalUsers().map((member, index) => (
                                       <MenuItem key={index} value={member}>
                                          <Typography>{`${member.nameOg}`}</Typography>
                                       </MenuItem>
                                    ))}
                                 </InputSelectField>
                              </FormGroup>
                           </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                           <Grid item xs={12}>
                              <Typography color={"#787486"}>Seleccionar Documentos</Typography>
                           </Grid>
                           <Grid item xs={12}>
                              <FormGroup>
                                 <InputSelectField
                                    name="selectedDocuments"
                                    sx={{
                                       maxWidth: "100%",
                                       minWidth: "100%",
                                    }}
                                    id="selectedDocuments"
                                    labelId="selectedDocuments"
                                 >
                                    {options.map((opt, index) => (
                                       <MenuItem value={opt} key={index}>
                                          <ListItemText primary={opt} sx={{ my: -0.1 }} />
                                       </MenuItem>
                                    ))}
                                 </InputSelectField>
                              </FormGroup>
                           </Grid>
                           <Grid item xs={12}>
                              <Collapse in={values.selectedDocuments === "Documentos específicos"}>
                                 <Typography color={"#787486"}>Documentos específicos</Typography>
                                 <FormGroup>
                                    <InputSelectField
                                       name="specificDocuments"
                                       multiple
                                       labelId="select-members-label"
                                       fullWidth
                                       id="specificDocuments"
                                       onChange={(e: any) => {
                                          const selectedValues = e.target.value as any[];
                                          setFieldValue("specificDocuments", selectedValues);
                                       }}
                                       renderValue={(selected) => selected.map((opt: any) => opt).join(", ")}
                                    >
                                       {optionsFiles.map((opt, index) => (
                                          <MenuItem key={index} value={opt}>
                                             <ListItemText primary={opt} sx={{ maxWidth: 100 }} />
                                          </MenuItem>
                                       ))}
                                    </InputSelectField>
                                 </FormGroup>
                              </Collapse>
                           </Grid>
                        </Grid>
                        <Grid container spacing={2} justifyContent="flex-end" marginTop={2}>
                           <Grid item>
                              <Button variant="outlined" color="secondary" onClick={() => setState(false)}>
                                 Cancelar
                              </Button>
                           </Grid>
                           <Grid item>
                              <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
                                 {isLoading ? <CircularProgress size={25} sx={{ color: "white" }} /> : "Enviar"}
                              </Button>
                           </Grid>
                        </Grid>
                     </Form>
                  </Box>
               )}
            </Formik>
         </Box>
      </CustomModalComponent>
   );
};
