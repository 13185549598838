import React, { useContext, useEffect, useMemo, useState } from "react";
import { CreateProfileContext } from "../../../../context/beneficiaryContext/createProfileContext";
import { Box, Button, CircularProgress, FormHelperText, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { beneficiaryCreateSchema2 } from "../../../../lib/validations/GobCop/inputSchemas";
import { AttachFile } from "@mui/icons-material";
import { FileSelectedCollapse } from "../../../MiLecosy/ModalsGovernanceModule/ControllingBeneficiaryModal";
import { NewExternBeneficiary } from "../../../../screens/Beneficiary controller/BeneficiaryControllerExternal";
import {
   createFileAsync,
   createMultipleFileAsync,
   createMultipleFolderBC,
   finishRequestChangesBeneficiary,
   getCompanyById,
   getFoldersByIdsExpedient,
   getUrlS3,
   getUserByRoleAndEntity,
   getYearlyParentFoldersByIds,
   updateBeneficiary,
   updateBeneficiaryDetails,
} from "../../../../lib/usersBEClient";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { uploadFileToS3 } from "../../../../lib/s3Client";
import { pdf } from "@react-pdf/renderer";
import { beneficiaryUpdatedTemplate } from "../../../../lib/documentsTemplates/beneficiary/beneficiaryUpdatedTemplate";
import { useParams } from "react-router-dom";
import { shareHolderUpdatedTemplate } from "../../../../lib/documentsTemplates/beneficiary/shareHolderUpdatedTemplate";
import { UserContext } from "../../../../context/userContext";

const ShareHolderDocs = {
   moral: [
      "Identificación Oficial Vigente del Representante Legal",
      "Poder notarial del Representante legal",
      "Acta constitutiva",
      "Constancia de situación fiscal",
      "Documento que soporta",
   ],
   physical: [
      "Identificación Oficial Vigente",
      "Acta de nacimiento / certificado de doble nacionalidad",
      "Comprobante de domicilio",
      "Constancia de inexistencia de matrimonio / Acta de matrimonio",
      "Identificación Oficial Vigente del Cónyuge o concubino",
      "Documento que soporta",
      "Constancia de situación fiscal",
   ],
};

const BeneficiaryForm = (props: { isNew?: boolean }) => {
   const {
      beneficiaryDetails,
      disableButton,
      setDisableButton,
      setOpenProfileModal,
      handleSubmitCreate,
      pendingRequests,
      setRequestModal,
   } = useContext(CreateProfileContext);
   const [initialValues, setInitialValues] = useState(null);
   const { showSnackBar } = useContext(SnackBarContext);
   const { companySelected } = useContext(UserContext);
   const [isLoadingInfo, setIsLoadingInfo] = useState(true);
   const { companyId } = useParams();

   const docsArray = useMemo(
      () =>
         beneficiaryDetails
            ? beneficiaryDetails.shareHolderType === "Persona fisica"
               ? ShareHolderDocs.physical
               : ShareHolderDocs.moral
            : [],
      [beneficiaryDetails]
   );

   useEffect(() => {
      handleInitialValues(props.isNew ? docsArray : pendingRequests.files);
      setIsLoadingInfo(false);
   }, []);

   const handleInitialValues = (files) => {
      let init = {};
      for (const element of files) {
         init[element] = null;
      }
      // if (props.isNew) {
      //    init["alias"] = "";
      //    init["place"] = "";
      //    init["relation"] = "";
      //    init["participation"] = "";
      // }
      // init["alias_value"] = "";

      setInitialValues(init);
   };

   const uploadFile = async (file, fileName, user, companyId, folder) => {
      try {
         const responseFile = await createFileAsync({
            name: fileName,
            owner: user,
            size: file.size,
            type: file.type,
            folder: folder,
            fileDirection: `beneficiaries/${companyId}/${folder._id}`,
         });
         await uploadFileToS3(responseFile.urlToUpload, file);
         return responseFile.createFile;
      } catch (error) {
         showSnackBar("Error al subir archivo", false);
      }
   };

   const handleSubmitChanges = async (values) => {
      setDisableButton(true);
      let newObjectFilesOnly = {};
      // let newObject = {};
      const headersOnlyFiles = Object.keys(initialValues).filter((key) => typeof initialValues[key] !== "string");
      // const headersOnlyStrings = Object.keys(initialValues).filter((key) => typeof initialValues[key] === "string");
      // for (const key of headersOnlyStrings) {
      //    newObject[key] = values[key];
      // }
      for (const key of headersOnlyFiles) {
         newObjectFilesOnly[key] = values[key];
      }
      if (props.isNew) {
         const responseInfo = await handleAddInfoNew(values);
         if (responseInfo === "error") {
            showSnackBar("Error al procesar información, vuelva a intentar.", true);
            return;
         }
      }
      await handleCreateFiles(newObjectFilesOnly);
   };

   const handleAddInfoNew = async (values) => {
      try {
         await updateBeneficiaryDetails(beneficiaryDetails._id, companyId, {
            informationLegalRepresentative: {
               alias: values[`${companyId}-alias_value`],
            },
            relationMoralPerson: {
               relationWithPerson: values[`${companyId}-relation`],
               porcentajeParticipation: values[`${companyId}-participation`],
               placeDeposited: values[`${companyId}-place`],
            },
            company: companyId,
         });
         return "ok";
      } catch (error) {
         return "error";
      }
   };

   const handleCreateFiles = async (filesToUpload) => {
      try {
         const filesKey = Object.keys(filesToUpload);
         const ids = [];
         for (const element of filesKey) {
            const folder = beneficiaryDetails.files.find((file) => file.fileName === element);
            if (!folder) {
               const { main } = await createMultipleFolderBC({
                  folders: [{ name: element, children: [], fileNumber: 0 }],
                  parent: beneficiaryDetails.folderId,
               });
               const files = [...beneficiaryDetails.files, { fileName: element, folderId: main[0] }];
               await updateBeneficiary(beneficiaryDetails._id, files);
               ids.push(main[0]);
            } else ids.push(folder.folderId);
         }
         const yearlyFolders = await getYearlyParentFoldersByIds(ids);
         const date = new Date();
         const files = await createMultipleFileAsync(
            filesKey.map((key, index) => {
               return {
                  name: `${key.includes("/") ? key.replace("/", "|") : key} - ${date.toLocaleDateString("es-MX", {
                     day: "2-digit",
                     month: "long",
                     year: "numeric",
                     hour: "2-digit",
                     minute: "2-digit",
                  })}.pdf`,
                  owner: null,
                  size: filesToUpload[key].size,
                  type: filesToUpload[key].type,
                  folder: yearlyFolders[index],
                  beneficiary: {
                     needUpdate: false,
                     verified: undefined,
                  },
                  fileDirection: `beneficiaries/${beneficiaryDetails.companyId[0]}/${beneficiaryDetails.folderId}`,
               };
            })
         );

         const [companyFound, logoUrl, presidentes, folderControl] = await Promise.all([
            getCompanyById(companyId),
            getUrlS3("images-lecosy", { folder: companyId }, "logo.png"),
            getUserByRoleAndEntity("651b06f1f721833c5e8724d9", companyId),
            getFoldersByIdsExpedient(beneficiaryDetails.folderId, companyId),
            filesKey.map((element, index) => uploadFileToS3(files[index].urlToUpload, filesToUpload[element])),
         ]);

         const template =
            beneficiaryDetails.legalPerson === "Accionista" ? shareHolderUpdatedTemplate : beneficiaryUpdatedTemplate;
         const fullName = `${presidentes[0].user.firstName} ${presidentes[0].user.lastName}`;
         const fileKeys = Object.keys(filesToUpload);

         const blob = await pdf(
            template(
               logoUrl,
               companyFound.data.company_details.primaryColor,
               beneficiaryDetails.name,
               fullName,
               "Hermosillo",
               "Sonora",
               fileKeys
            )
         ).toBlob();

         await uploadFile(
            blob,
            `Acuse de actualización de información - ${date.toLocaleDateString("es-MX", {
               day: "2-digit",
               month: "long",
               year: "numeric",
               hour: "2-digit",
               minute: "2-digit",
            })}.pdf`,
            null,
            beneficiaryDetails.companyId[0],
            { _id: folderControl }
         );

         await Promise.all(
            pendingRequests.requestsIds.map((requestId) =>
               finishRequestChangesBeneficiary(requestId, beneficiaryDetails._id)
            )
         );

         if (props.isNew) {
            handleSubmitCreate();
            setOpenProfileModal(false);
         }
         setRequestModal(false);
         setDisableButton(false);
         showSnackBar("Documentación actualizada correctamente", false);
      } catch (error) {
         console.log(error);
         setDisableButton(false);
         showSnackBar("Error al subir archivos", true);
      }
   };

   return isLoadingInfo ? (
      <CircularProgress />
   ) : (
      <Formik
         initialValues={initialValues}
         onSubmit={handleSubmitChanges}
         validationSchema={() =>
            beneficiaryCreateSchema2(
               Object.keys(initialValues).filter((key) => typeof initialValues[key] !== "string"),
               props.isNew
            )
         }
      >
         {({ setFieldValue, values, errors }) => (
            <Form>
               <Stack
                  sx={{
                     display: "flex",
                     px: 6,
                     py: 2,
                     overflowY: "auto",
                     height: props.isNew ? "60vh" : "70vh",
                  }}
               >
                  {props.isNew && (
                     <Stack>
                        <NewExternBeneficiary
                           setFieldValue={setFieldValue}
                           values={values}
                           companyId={companySelected._id}
                           companyName={companySelected.person_details.businessName}
                           shareHolder={beneficiaryDetails.legalPerson === "Accionista"}
                        />
                     </Stack>
                  )}
                  <Typography>Documentación</Typography>
                  {(props.isNew ? docsArray : pendingRequests.files).map((file, index) => {
                     return (
                        <Grid item xs={12} key={index}>
                           <Box
                              sx={{
                                 borderColor: !!errors[file] ? "red" : "#D9D9D9",
                                 borderWidth: 1,
                                 borderStyle: "solid",
                                 padding: 1,
                                 marginTop: 2,
                                 borderRadius: 1,
                                 width: "100%",
                              }}
                           >
                              <Grid container alignItems="center" justifyContent="space-between">
                                 <Grid item xs={6}>
                                    <Typography>{file}</Typography>
                                 </Grid>
                                 <Grid item xs={6} container alignItems="center" justifyContent="flex-end">
                                    <Button
                                       variant="contained"
                                       size="small"
                                       endIcon={<AttachFile sx={{ color: "#707070" }} />}
                                       sx={{
                                          backgroundColor: "#F4F4F4",
                                          borderRadius: "20px",
                                          textTransform: "none",
                                          color: "#707070",
                                          paddingLeft: "12px",
                                          paddingRight: "12px",
                                          "&:hover": {
                                             backgroundColor: "#E0E0E0",
                                          },
                                       }}
                                       onClick={() => {
                                          document.getElementById(`file-input-${index}`).click();
                                       }} // Trigger the file input
                                    >
                                       Subir archivo
                                    </Button>
                                    <input
                                       type="file"
                                       id={`file-input-${index}`}
                                       hidden
                                       onChange={(event) => {
                                          const fileAd = event.currentTarget.files[0];

                                          if (fileAd) {
                                             if (fileAd.type !== "application/pdf") {
                                                showSnackBar("Solo se permiten archivos PDF.", true);
                                                return;
                                             }
                                             if (fileAd.size > 50 * 1024 * 1024) {
                                                showSnackBar("El archivo debe ser menor de 50 MB.", true);
                                                return;
                                             }

                                             setFieldValue(file, fileAd);
                                          }
                                       }}
                                    />
                                 </Grid>
                              </Grid>
                           </Box>
                           {errors[file] && (
                              <FormHelperText error={!!errors[file]}>{errors[file].toString()}</FormHelperText>
                           )}

                           <Stack>
                              <FileSelectedCollapse
                                 file={values[file]}
                                 onClick={() => {
                                    setFieldValue(file, null);
                                 }}
                              />
                           </Stack>
                        </Grid>
                     );
                  })}
               </Stack>
               <Stack
                  sx={{
                     alignItems: "center",
                     py: 2,
                  }}
               >
                  <Button variant="contained" type="submit" disabled={disableButton} sx={{ width: 300 }}>
                     {disableButton ? <CircularProgress size={24} /> : "Guardar"}
                  </Button>
               </Stack>
            </Form>
         )}
      </Formik>
   );
};

export default BeneficiaryForm;
