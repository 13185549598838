import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getFoldersAndFilesById } from "../../lib/usersBEClient";
import {
   Box,
   List,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Typography,
   Button,
   Stack,
   Menu,
   MenuItem,
} from "@mui/material";
import useContextMenu from "../../components/Gobierno corporativo/MyFilesScreen/Hooks/useContextMenu";
import { MyFiles } from "../../components/Gobierno corporativo/MyFilesScreen/MyFiles";
import { Filters } from "../../components/Gobierno corporativo/MyFilesScreen/SubComponents/Filters";
import { CreateFolderModal } from "../../components/Gobierno corporativo/MyFilesScreen/SubComponents/CreateFolderModal";
import {
   AudioFile,
   FileUpload,
   Folder,
   FolderSharp,
   InsertDriveFile,
   InsertPhoto,
   Movie,
   PictureAsPdf,
   Link,
   FolderZip,
} from "@mui/icons-material";
import { ClientTypeModal } from "../../components/ClientTypeModal";
import { UploadFile } from "../../components/Gobierno corporativo/Modal/UploadFile";
import { formatFileSize, getDateAndTimeFromISODate } from "../../const/globalConst";
import { FilesContext } from "../../context/governanceContext/filesContext";
import dayjs from "dayjs";
import { GovernanceContext } from "../../context/governanceContext/governanceContext";
import { UserContext } from "../../context/userContext";

export const getIconByType = (type) => {
   switch (type) {
      case "application/pdf":
         return <PictureAsPdf sx={{ color: "red" }} />;
      case "image/png":
      case "image/jpeg":
         return <InsertPhoto />;
      case "audio/ogg":
      case "audio/mpeg":
         return <AudioFile />;
      case "video/mp4":
         return <Movie />;
      case "application/zip":
         return <FolderZip />;
      case "application/url":
         return <Link />;
      default:
         return <InsertDriveFile sx={{ color: "black", fontSize: 25 }} />;
   }
};

const useGetFiles = () => {
   const { folderId, id, companyId } = useParams();
   const [isLoading, setIsLoading] = useState(true);
   const [files, setFiles] = useState([]);
   const [reload, setReload] = useState(null);
   const { startDate, endDate, confirmModal } = useContext(FilesContext);
   const { setDocumentSelected } = useContext(GovernanceContext);
    const { user, groups, companies } = useContext(UserContext);

   useEffect(() => {
      const fetchFolders = async () => {
         if (!folderId || !companyId) return;
         setIsLoading(true);
         try {
            // Check if user is in group or company
            let userEntity = null;

            const groupFound = groups.find((g) => g._id === companyId);
            const companyFound = companies.find((c) => c._id === companyId);

            if (groupFound) {
               const isUserInGroup = groupFound.users.some((u) => u._id === user.id);
               if (isUserInGroup) {
                  userEntity = groupFound;
               } else {
                  userEntity = companies
                     .filter((c) => c.group === groupFound._id)
                     .find((company) => company.company_details.users.includes(user.id));
               }
            } else if (companyFound) {
               userEntity = companyFound;
            }

            const response = await getFoldersAndFilesById([folderId], userEntity?._id);
            setDocumentSelected(response.folder);
            setFiles([...response.folder.children, ...response.files]);
         } catch (error) {
            console.error("Error fetching folders and files:", error);
         }
         setIsLoading(false);
      };
      if (folderId === "archivosPorVerificar") return setDocumentSelected("ARCHIVOS POR VERIFICAR");
      if (folderId === "documentosVerificados") return setDocumentSelected("DOCUMENTOS VERIFICADOS");
      if (folderId) fetchFolders();
   }, [folderId, reload, id, companyId, setDocumentSelected]);

   const filteredData = useMemo(() => {
      return files.map((iterator) => {
         if (iterator.size) {
            const name = iterator.name.split(".");
            name.pop();

            return {
               ...iterator,
               name: (
                  <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                     {getIconByType(iterator.type)}
                     <Typography variant="subtitle2">{iterator.name}</Typography>
                  </Box>
               ),
               originalName: name.join("."),
               updatedAt: getDateAndTimeFromISODate(iterator.updatedAt),
               owner: iterator.owner ? `${iterator.owner.firstName} ${iterator.owner.lastName}` : "---",
               ownerId: iterator.owner ? iterator.owner._id : "",
               size: formatFileSize(iterator.size),
               originalType: iterator.name.split(".").pop(),
            };
         } else {
            return {
               ...iterator,
               type: "carpeta",
               name: (
                  <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                     <Folder sx={{ color: "#F1B44C", fontSize: 25 }} />
                     <Typography variant="subtitle2">{iterator.name}</Typography>
                  </Box>
               ),
               updatedAt: getDateAndTimeFromISODate(iterator.updatedAt),
               owner: iterator.owner ? `${iterator.owner.firstName} ${iterator.owner.lastName}` : "---",
               ownerId: iterator.owner ? iterator.owner._id : "",
               size: "---",
               originalName: iterator.name,
            };
         }
      });
   }, [files]);

   const processedData = useMemo(() => {
      if (confirmModal && startDate !== null && endDate !== null) {
         return filteredData.filter(
            (file) => dayjs(new Date(file.createdAt)) >= startDate && dayjs(new Date(file.createdAt)) <= endDate
         );
      }
      return filteredData;
   }, [filteredData, confirmModal, startDate, endDate]);

   return { isLoading, processedData, setReload, reload };
};

export const FolderScreen = () => {
   const { folderId } = useParams();
   const { isLoading, processedData, setReload, reload } = useGetFiles();
   const { clicked, setClicked, points, setPoints } = useContextMenu();
   const [openModal, setOpenModal] = useState(false);
   const [openUploadFile, setOpenUploadFile] = useState(false);
   const [create, setCreate] = useState(true);
   const [name, setName] = useState("");
   const [anchorEl, setAnchorEl] = useState(null);
   const buttonRef = useRef(null);
   const [menuWidth, setMenuWidth] = useState("auto");
   // const { isLoading: isLoadingVerified, folders: verifiedFolders } = useVerifiedDocumentsFolder(companyId);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      if (buttonRef.current) {
         setMenuWidth(buttonRef.current.offsetWidth);
      }
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   return (
      <>
         <Stack direction="row" spacing={2} alignItems="center">
            <Box sx={{ flexGrow: 1 }}>
               <Filters />
            </Box>
            <Button variant="contained" onClick={handleClick} sx={{ width: "100%", maxWidth: "200px" }} ref={buttonRef}>
               Nuevo
            </Button>
            <Menu
               anchorEl={anchorEl}
               open={Boolean(anchorEl)}
               onClose={handleClose}
               PaperProps={{ style: { width: menuWidth } }}
            >
               <MenuItem
                  onClick={() => {
                     setOpenModal(true);
                     setCreate(true);
                     setName("");
                     handleClose();
                  }}
               >
                  <ListItemIcon>
                     <FolderSharp />
                  </ListItemIcon>
                  Crear carpeta
               </MenuItem>
               <MenuItem
                  onClick={() => {
                     setOpenUploadFile(true);
                     handleClose();
                  }}
               >
                  <ListItemIcon>
                     <FileUpload />
                  </ListItemIcon>
                  Subir archivo
               </MenuItem>
            </Menu>
         </Stack>
         <Box
            sx={{ py: 1 }}
            onContextMenu={(e) => {
               e.preventDefault();
               setClicked(true);
               setPoints({
                  x: e.pageX,
                  y: e.pageY,
               });
            }}
         >
            <MyFiles files={processedData} isLoading={isLoading} setReload={setReload} reload={reload} />
         </Box>
         <CreateFolderModal
            create={create}
            openModal={openModal}
            setOpenModal={setOpenModal}
            selected={folderId}
            value={name}
            setName={setName}
            reloadFiles={reload}
            setReloadFiles={setReload}
         />
         <ClientTypeModal
            body={
               <UploadFile
                  folder={folderId}
                  setReload={setReload}
                  reload={reload}
                  setOpenModalFile={setOpenUploadFile}
               />
            }
            onConfirm={() => {}}
            open={openUploadFile}
            setOpen={setOpenUploadFile}
            title={"Subir archivo"}
            blue
         />
         {clicked && (
            <Box
               sx={{
                  position: "absolute",
                  top: `${points.y}px`,
                  left: `${points.x}px`,
                  zIndex: 2000,
                  bgcolor: "white",
                  boxShadow: 2,
                  borderRadius: 2,
               }}
            >
               <List dense={true}>
                  <ListItemButton
                     onClick={() => {
                        setOpenModal(true);
                        setCreate(true);
                        setName("");
                     }}
                  >
                     <ListItemIcon>
                        <FolderSharp />
                     </ListItemIcon>
                     <ListItemText primary="Crear carpeta" />
                  </ListItemButton>
                  <ListItemButton onClick={() => setOpenUploadFile(true)}>
                     <ListItemIcon>
                        <FileUpload />
                     </ListItemIcon>
                     <ListItemText primary="Subir archivo" />
                  </ListItemButton>
               </List>
            </Box>
         )}
      </>
   );
};
