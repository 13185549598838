import { useEffect, useState, useCallback } from "react";

const getCategoryIdsByName = async (categoryNames) => {
   try {
      const response = await fetch("https://blog.lecosy.com.mx/index.php/wp-json/wp/v2/categories");
      const categories = await response.json();
      const categoryIds = categories
         .filter((category) => categoryNames.includes(category.name))
         .map((category) => category.id);
      return categoryIds.join(",");
   } catch (error) {
      console.error("Error al obtener las categorías:", error);
      return [];
   }
};

const useGetInsights = (modules) => {
   const [insights, setInsights] = useState([]);
   const [guides, setGuides] = useState([]);

   const getWordpressBlog = useCallback(async () => {
      try {
         const fetchPosts = async (url) => {
            const response = await fetch(url);
            const posts = await response.json();
            return posts.map((post) => {
               const categories =
                  post._embedded?.["wp:term"]
                     ?.flat()
                     .filter((term) => term.taxonomy === "category")
                     .map((term) => term.name) || [];
               return {
                  title: post.title.rendered,
                  image: post.featured_image_src || null,
                  date: new Date(post.date).toLocaleDateString("es-ES", {
                     day: "2-digit",
                     month: "long",
                     year: "numeric",
                  }),
                  link: post.link,
                  category: categories,
               };
            });
         };
         const categoryIds = await getCategoryIdsByName(modules);
         const urls = [
            `https://blog.lecosy.com.mx/index.php/wp-json/wp/v2/posts?_embed&per_page=10&categories=${categoryIds}`, //Categories
            `https://blog.lecosy.com.mx/index.php/wp-json/wp/v2/posts?_embed&per_page=100&categories=56,57`, //Tools
         ];
         const postsPromises = urls.map(fetchPosts);
         const [insights, guides] = await Promise.all(postsPromises);
         setInsights(insights);
         setGuides(guides);
      } catch (error) {
         console.error("Error al obtener los datos del blog:", error);
      }
   }, []);

   useEffect(() => {
      getWordpressBlog();
   }, [getWordpressBlog]);

   return { insights, guides };
};

export default useGetInsights;
