import { Box, CircularProgress, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import useGetGovernmentBodyUsersInfo from "../../hooks/gob-corp/governmentBody/useGetGovernmentBodyUsersInfo";
import AgreementCommitmentSection from "../../components/Gobierno corporativo/ReportsScreen/AgreementCommitmentSection";
import MembersListSection from "../../components/Gobierno corporativo/ReportsScreen/MembersListSection";
import SessionAttendanceSection from "../../components/Gobierno corporativo/ReportsScreen/SessionAttendanceSection";
import SessionInfoSection from "../../components/Gobierno corporativo/ReportsScreen/SessionInfoSection";
import { useContext, useEffect, useState } from "react";
import { GovernanceContext } from "../../context/governanceContext/governanceContext";
import {
   addBasicInfoToUserDetails,
   assignCompanyDetailsToGoverningBodies,
   getAllUsersDetailsByGovernance,
   getClassificationById,
} from "../../lib/gobCorpBEClient";
import { UserContext } from "../../context/userContext";
import { getUsersBasicInfoByIds } from "../../lib/usersBEClient";
import { EvaluationResults } from "../../components/Gobierno corporativo/ReportsScreen/EvaluationResults";
import {
   ReportGenerationSection,
   TrimesterReportsSection,
} from "../../components/Gobierno corporativo/ReportsScreen/ReportGenerationComponent";

const GoverningBodiesReportScreen = () => {
   const { selectedGovernance, companySelected } = useContext(GovernanceContext);
   const { companySelected: companyInfo } = useContext(UserContext);
   const { id } = useParams();
   const { members } = useGetGovernmentBodyUsersInfo(true);
   const selectedBody = members.find((body) => body.bodiesData._id === id);

   const isAssembly = selectedGovernance?.title === "Asamblea de accionistas";

   const [categoriesByCompany, setCategoriesByCompany] = useState(null);

   const fetchClarification = async () => {
      if (!companyInfo) return;
      try {
         const classData = await getClassificationById(companyInfo._id);
         return classData.categories;
      } catch (error) {
         console.error("Error fetching clarification:", error);
      }
   };

   const assignCompanyDetails = async () => {
      if (!companyInfo || !companySelected) return;
      const { person_details, company_details } = companyInfo;
      try {
         await assignCompanyDetailsToGoverningBodies(companySelected, {
            businessName: person_details.businessName,
            primaryColor: company_details.primaryColor,
            secondaryColor: company_details.secondaryColor,
         });
      } catch (error) {
         console.error("Error assigning company details:", error);
      }
   };

   const assignUserDetails = async () => {
      if (!selectedGovernance) return;
      try {
         const governingUsersWithoutEmail = await getAllUsersDetailsByGovernance(selectedGovernance._id);
         if (!governingUsersWithoutEmail?.length) return;
         const usersInfo = await getUsersBasicInfoByIds(governingUsersWithoutEmail);
         if (!usersInfo.length) return;
         await addBasicInfoToUserDetails(usersInfo);
      } catch (error) {
         console.error("Error assigning user details:", error);
      }
   };

   useEffect(() => {
      if (!companyInfo || !selectedGovernance) return;

      const fetchData = async () => {
         try {
            await assignCompanyDetails();
            await assignUserDetails();
            const categories = await fetchClarification();
            if (categories) setCategoriesByCompany(categories);
         } catch (error) {
            console.error("Error in useEffect:", error);
         }
      };
      fetchData();
   }, [companyInfo, selectedGovernance]);

   return (
      <Stack direction={"column"} spacing={3}>
         {selectedBody ? (
            <>
               <MembersListSection selectedBody={selectedBody} />
               {!isAssembly && <EvaluationResults />}
               <AgreementCommitmentSection />
               <SessionAttendanceSection selectedBody={selectedBody} categoriesByCompany={categoriesByCompany} />
               {/* <EffectivenessSection /> */}
               <SessionInfoSection />
               <Stack direction={"row"} spacing={2} width={"100%"} justifyContent={"space-between"}>
                  <TrimesterReportsSection />
                  <ReportGenerationSection selectedBody={selectedBody} />
               </Stack>
            </>
         ) : (
            <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
               <CircularProgress />
            </Box>
         )}
      </Stack>
   );
};

export default GoverningBodiesReportScreen;
