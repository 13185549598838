import { useState, useContext, useMemo } from "react";
import {
   TableContainer,
   Table,
   TableHead,
   TableRow,
   TableCell,
   TableBody,
   IconButton,
   Box,
   Tooltip,
   Button,
   Popover,
   Typography,
   Menu,
   MenuItem,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useNavigate } from "react-router-dom";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmationModal } from "../ConfirmationModal";
import { getUrlS3 } from "../../lib/usersBEClient";

interface DocumentsTableProps {
   replica?: boolean;
   index?: number;
   offenderIndex?: number;
   rows: any;
}

export const DocumentsTableNew = (props: DocumentsTableProps) => {
   const { complaint, setFileName, setHeader, setSubSteps, documentation, deleteDocumentFile } =
      useContext(ComplaintStepperContext);
   const navigate = useNavigate();
   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
   const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
   const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
   const [document, setDocument] = useState("");

   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClick2 = (event: React.MouseEvent<HTMLElement>, name: string) => {
      setAnchorEl2(event.currentTarget);
      setDocument(name);
   };
   const handleClose = () => {
      setAnchorEl(null);
      setAnchorEl2(null);
   };

   const openMenu = useMemo(() => {
      return anchorEl2 ? true : false;
   }, [anchorEl2]);

   const open = Boolean(anchorEl);
   const id = open ? "simple-popover" : undefined;

   const validation = (documents: string[]) => {
      if (documents) {
         const document = documents.map((e) => {
            if (props.offenderIndex) {
               return e + `-${complaint.reportNumber}-${props.offenderIndex}-${props.index}.pdf`;
            }
            if (props.index) {
               return e + `-${complaint.reportNumber}-${props.index}.pdf`;
            }
            return e + `-${complaint.reportNumber}.pdf`;
         });
         const validations = documentation.map((element) => {
            if (document.includes(element.name)) {
               return true;
            } else {
               return false;
            }
         });
         if (validations.some((e) => e === true)) {
            return true;
         } else {
            return false;
         }
      } else {
         return false;
      }
   };

   const secondValidation = (documents: string[]) => {
      const document = documents.map((e) => {
         if (props.offenderIndex) {
            return e + `-${complaint.reportNumber}-${props.offenderIndex}-${props.index}.pdf`;
         }
         if (props.index) {
            return e + `-${complaint.reportNumber}-${props.index}.pdf`;
         }
         return e + `-${complaint.reportNumber}.pdf`;
      });
      const docFound = documentation.map((element) => {
         if (document[0].includes(element.name)) {
            return true;
         } else {
            return false;
         }
      });
      if (docFound.some((e) => e === true)) {
         return true;
      } else {
         return false;
      }
   };

   const getFileName = (docmunetKey: string) => {
      let document;
      if (docmunetKey && docmunetKey.length > 1) {
         document =
            props.index && props.index !== 0
               ? props.offenderIndex
                  ? `${docmunetKey[0]}-${complaint.reportNumber}-${props.offenderIndex}-${props.index}.pdf`
                  : `${docmunetKey[0]}-${complaint.reportNumber}-${props.index}.pdf`
               : `${docmunetKey[0]}-${complaint.reportNumber}.pdf`;
      } else {
         document =
            props.index && props.index !== 0
               ? props.offenderIndex
                  ? `${docmunetKey[0]}-${complaint.reportNumber}-${props.offenderIndex}-${props.index}.pdf`
                  : `${docmunetKey[0]}-${complaint.reportNumber}-${props.index}.pdf`
               : `${docmunetKey}-${complaint.reportNumber}.pdf`;
      }
      return document;
   };

   const getFileSignedUrl = async (docmunetKey: string) => {
      const fileName = getFileName(docmunetKey);
      const newFileKey = fileName.indexOf(".pdf");
      const fileUrlResponse = await getUrlS3(
         "files-lecosy",
         { folder: `docs/${complaint.reportNumber}` },
         fileName.substring(0, newFileKey ? newFileKey : fileName.length)
      );
      window.open(fileUrlResponse, "_blank");
   };

   const getFileSignedUrlNotifications = async (docmunetKey: string) => {
      const newFileKey = docmunetKey.indexOf(".pdf");
      const fileUrlResponse = await getUrlS3(
         "files-lecosy",
         { folder: `docs/${complaint.reportNumber}` },
         docmunetKey.substring(0, newFileKey ? newFileKey : docmunetKey.length)
      );
      window.open(fileUrlResponse, "_blank");
   };

   const getNotificationName = (subject: string) => {
      if (props.rows[0].documentName[0].includes(subject)) {
         if (props.offenderIndex) {
            return `notificacion-entrevista-${subject}-${props.index}-${props.offenderIndex}`;
         }
         if (props.index) {
            return `notificacion-entrevista-${subject}-${props.index}`;
         }
         return `notificacion-entrevista-${subject}`;
      }
      return null;
   };

   const handleNotificationValidation = () => {
      let notification = "";
      if (getNotificationName("testigo") !== null) {
         notification = getNotificationName("testigo");
      }
      if (getNotificationName("denunciado") !== null) {
         notification = getNotificationName("denunciado");
      }
      if (getNotificationName("denunciante") !== null) {
         notification = getNotificationName("denunciante");
      }
      return notification;
   };

   const checkNumberOfNotifications = (name: string) => {
      const documents: string[] = [];
      documentation.forEach((e) => {
         if (e.name.includes(name)) {
            documents.push(e.name);
         }
      });
      return documents;
   };

   return (
      <Box>
         <TableContainer>
            <Table>
               <TableHead>
                  <TableRow>
                     <TableCell> Nombre: </TableCell>
                     <TableCell align="center">Documento</TableCell>
                     <TableCell align="center">Plantilla</TableCell>
                     <TableCell align="center">Añadir</TableCell>
                     <TableCell align="center">Más</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {props.rows.map((e, i) => {
                     return (
                        <TableRow key={`${e}-${i}`}>
                           <TableCell>
                              {e.documentName ? (
                                 validation(e.documentName) ? (
                                    <Box
                                       sx={{
                                          display: "flex",
                                          alignItems: "center",
                                       }}
                                    >
                                       {e.document}
                                       {e.document.includes("Notificación") ? (
                                          checkNumberOfNotifications(handleNotificationValidation()).length > 0 ? (
                                             <Tooltip
                                                title={`La cantidad de notificaciones enviadas es: ${
                                                   checkNumberOfNotifications(handleNotificationValidation()).length
                                                }`}
                                             >
                                                <InfoIcon color="action" sx={{ ml: 0.5, fontSize: "15px" }} />
                                             </Tooltip>
                                          ) : undefined
                                       ) : undefined}
                                       <CheckCircleIcon color="success" sx={{ ml: 2 }} />
                                    </Box>
                                 ) : (
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                       {e.document}
                                       {e.document.includes("Notificación") ? (
                                          checkNumberOfNotifications(handleNotificationValidation()).length > 0 ? (
                                             <Tooltip
                                                title={`La cantidad de notificaciones enviadas es: ${
                                                   checkNumberOfNotifications(handleNotificationValidation()).length
                                                }`}
                                             >
                                                <InfoIcon color="action" sx={{ ml: 0.5, fontSize: "15px" }} />
                                             </Tooltip>
                                          ) : undefined
                                       ) : undefined}
                                    </Box>
                                 )
                              ) : (
                                 e.document
                              )}
                           </TableCell>
                           <TableCell align="center">
                              {e.documentName ? (
                                 validation(e.documentName) ? (
                                    e.document.includes("Notificación") ? (
                                       <IconButton aria-describedby={id} onClick={handleClick}>
                                          <PictureAsPdfIcon />
                                       </IconButton>
                                    ) : secondValidation(e.documentName) ? (
                                       <IconButton onClick={() => getFileSignedUrl(e.documentName)}>
                                          <PictureAsPdfIcon />
                                       </IconButton>
                                    ) : null
                                 ) : null
                              ) : null}
                           </TableCell>
                           {e.templateLink ? (
                              <TableCell align="center">
                                 <IconButton
                                    onClick={() => navigate(e.templateLink)}
                                    disabled={validation(e.documentName)}
                                 >
                                    <DescriptionIcon />
                                 </IconButton>
                              </TableCell>
                           ) : (
                              <TableCell></TableCell>
                           )}
                           {e.documentName && e.document !== "Notificación de entrevista" ? (
                              <TableCell align="center">
                                 <IconButton
                                    disabled={validation(e.documentName)}
                                    onClick={() => {
                                       setFileName(e.documentName[0]);
                                       setHeader(e.fileHeader);
                                       setSubSteps(1);
                                    }}
                                 >
                                    <UploadFileIcon />
                                 </IconButton>
                              </TableCell>
                           ) : (
                              <TableCell></TableCell>
                           )}
                           {e.documentName &&
                           !e.document.includes("Notificación") &&
                           secondValidation(e.documentName) &&
                           !e.document.includes("Réplica") ? (
                              <TableCell align="center">
                                 <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={openMenu ? "long-menu" : undefined}
                                    aria-expanded={openMenu ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={(ev) => handleClick2(ev, getFileName(e.documentName))}
                                 >
                                    <MoreHorizIcon />
                                 </IconButton>
                              </TableCell>
                           ) : (
                              <TableCell></TableCell>
                           )}
                        </TableRow>
                     );
                  })}
               </TableBody>
            </Table>
         </TableContainer>
         <Menu
            id="long-menu"
            MenuListProps={{
               "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl2}
            open={openMenu}
            onClose={handleClose}
            PaperProps={{
               style: {
                  maxHeight: 48 * 4.5,
                  width: "20ch",
               },
            }}
         >
            <MenuItem
               key={"Borrar"}
               onClick={() => {
                  setOpenConfirmationModal(true);
                  handleClose();
               }}
            >
               <Typography variant="body2">Borrar documento</Typography>
               <DeleteIcon sx={{ ml: 0.5, color: "gray" }} />
            </MenuItem>
         </Menu>
         <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "left",
            }}
         >
            <Box>
               <TableContainer sx={{ maxHeight: documentation.length > 5 ? 260 : "auto" }}>
                  <Table size="small">
                     <TableHead>
                        <TableRow>
                           <TableCell sx={{ fontWeight: 600 }}>Folio:</TableCell>
                           <TableCell align="right" sx={{ fontWeight: 600 }}>
                              Ver documento:
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {documentation.map((document, i) => (
                           <>
                              {document.name.includes(handleNotificationValidation()) ? (
                                 <TableRow key={i}>
                                    <TableCell>{document.name}</TableCell>
                                    <TableCell align="center">
                                       <Button
                                          size="small"
                                          onClick={() => getFileSignedUrlNotifications(document.name)}
                                       >
                                          <OpenInNewIcon />
                                       </Button>
                                    </TableCell>
                                 </TableRow>
                              ) : null}
                           </>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
            </Box>
         </Popover>
         <ConfirmationModal
            open={openConfirmationModal}
            setOpen={setOpenConfirmationModal}
            title={"Borrar documento"}
            body={
               <Box>
                  <Typography>¿Está seguro de borrar el documento?</Typography>
                  <Typography variant="body2">Se va a borrar el siguiente documento: </Typography>
                  <Typography variant="body2">{document}</Typography>
               </Box>
            }
            onConfirm={() => deleteDocumentFile(document)}
         />
      </Box>
   );
};
