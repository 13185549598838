import { Box, Checkbox, Grid, Tooltip, Typography } from "@mui/material";
import { useContext } from "react";
import { getContrastYIQ } from "../../../../const/globalConst";
import { ExternalGovernanceSessionContext } from "../../../../context/governanceContext/externalSessionContext";

export const BillSignsExternal = () => {
   const { colors, session, userOnlineSigns, signArray } = useContext(ExternalGovernanceSessionContext);

   return (
      <Box>
         <Typography
            sx={{
               bgcolor: colors.secondary,
               fontSize: 12,
               color: getContrastYIQ(colors.secondary),
               textAlign: "center",
            }}
         >
            {session.assembly && session.type !== "Sesión de acuerdos" ? "FIRMA DE ASISTENCIA" : "FIRMA DE ACUERDOS"}
         </Typography>
         <Grid container rowSpacing={0.2}>
            <Grid item xs={8}>
               <Typography sx={{ fontWeight: 600, textAlign: "center", fontSize: 12, p: 0.4 }}>NOMBRES</Typography>
            </Grid>
            <Grid item xs={4}>
               <Typography sx={{ fontWeight: 600, textAlign: "center", fontSize: 12, p: 0.4 }}>FIRMA</Typography>
            </Grid>
            {userOnlineSigns.map((users, index) => {
               if (!users) return;
               return (
                  <Grid container item spacing={0.2} key={"signs" + index}>
                     <Grid item xs={8} sx={{ mb: 0.2 }}>
                        <Typography sx={{ textAlign: "center", fontSize: 12, p: 0.4, bgcolor: "#EFEFEF" }}>
                           {users.firstName + " " + users.lastName}
                        </Typography>
                     </Grid>
                     <Grid item xs={4} sx={{ mb: 0.2 }}>
                        <Tooltip
                           arrow
                           title="Este campo se rellanara automáticamente cuando se verifique la firma del miembro"
                           slotProps={{
                              popper: {
                                 modifiers: [
                                    {
                                       name: "offset",
                                       options: {
                                          offset: [0, -14],
                                       },
                                    },
                                 ],
                              },
                           }}
                        >
                           <Box sx={{ p: 0.4, bgcolor: "#EFEFEF", display: "flex", justifyContent: "center" }}>
                              <Checkbox
                                 checked={signArray.includes(users._id)}
                                 sx={{ p: 0, "& .MuiSvgIcon-root": { fontSize: 18 } }}
                                 disabled={true}
                              />
                           </Box>
                        </Tooltip>
                     </Grid>
                  </Grid>
               );
            })}
         </Grid>
      </Box>
   );
};
