import { useContext, useEffect, useState } from "react";
import {
   Box,
   Toolbar,
   List,
   CssBaseline,
   IconButton,
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Divider,
   Typography,
} from "@mui/material";
import { ComplaintChannelMenu } from "../drawer/ComplaintChannelMenu";
import { Dashboard, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Outlet, useNavigate } from "react-router-dom";
import { SystemThemeConfig } from "../config/systemTheme.config";
import { AppBarComponent } from "../components/Drawer/AppBarComponent";
import { UserContext } from "../context/userContext";
import { GovernanceMenu } from "../drawer/GovernanceMenu";
import { CustomDrawer } from "../drawer/ConstDrawer";
import SessionEndedModal from "../components/sessionEndedModal";
import { SessionContext } from "../context/sessionContext";
import { ComplaintContext, ComplaintProvider } from "../context/complaintContext";
import { ConfirmationModal } from "../components/ConfirmationModal";
import detectIncognito from "detectincognitojs";

export default function MiniDrawer(props: { user: any }) {
   // useGetUserInfo(props.user);
   const { expiredSession } = useContext(SessionContext);
   const { logoutUser, editingSection, setEditingSection, path, setPath, verifyAccess, companySelected } =
      useContext(UserContext);
   const { logout: logoutComplaint } = useContext(ComplaintContext);
   const [open, setOpen] = useState(false);
   const [exceededLicense, setExceededLicense] = useState(false);
   const [arrowState, setArrowState] = useState(false);
   const [openEditorModal, setOpenEditorModal] = useState(false);
   const navigate = useNavigate();

   // get permissions to display sections in left menu

   //GOVERNANCE
   const gcAccess = verifyAccess("Gobierno corporativo", true);
   const bcAccess = verifyAccess("Beneficiario controlador", true);
   const bpAccess = gcAccess || bcAccess;
   //COMPLAINTS CHANNEL
   const firmasPermission = verifyAccess("Firmas");
   const difusionPermission = verifyAccess("Difusión-Reportes-Banco");
   const denunciasPermission = verifyAccess("Denuncias externas");
   const cdAccess =
      verifyAccess("Canal de denuncias", true) && (denunciasPermission || difusionPermission || firmasPermission);

   const onLogoutButtonPress = () => {
      logoutUser();
      logoutComplaint();
      navigate("/login");
   };

   // useEffect(() => {
   //    const licenceLimitByCompany = () => {
   //       const licenceLimit: boolean[] = [];
   //       for (const company of companies) {
   //          for (const service of avaliableServices) {
   //             licenceLimit.push(licenceValidation(service, company));
   //          }
   //       }
   //       setExceededLicense(licenceLimit.some((licence) => licence === false));
   //    };

   //    licenceLimitByCompany();
   // }, [user]);

   function navigateOnConfirm() {
      if (!path) return;
      if (typeof path === "number") navigate(path as number);
      else navigate(path as string);
      setPath(null);
      setEditingSection([]);
      setOpenEditorModal(false);
   }
   useEffect(() => {
      if (path && editingSection.length > 0) return setOpenEditorModal(true);
      return navigateOnConfirm();
   }, [path]);

   useEffect(() => setEditingSection([]), []);

   window.addEventListener("beforeunload", function () {
      detectIncognito().then((result) => {
         if (result.isPrivate) return logoutUser();
      });
   });

   return (
      <SystemThemeConfig>
         <SessionEndedModal
            open={expiredSession}
            title="Sesion expirada"
            body="Por tu seguridad, es necesario iniciar sesión cierto periodo de tiempo para evitar robo de información."
            onConfirm={() => onLogoutButtonPress}
         />
         <ConfirmationModal
            title="Exceso de licencias"
            body={
               <Typography>
                  Cuenta con un exceso de licencias, revise sus licencias e intentelo de nuevo más tarde.
               </Typography>
            }
            open={exceededLicense}
            setOpen={setExceededLicense}
            onConfirm={() => setExceededLicense(false)}
         />

         <ConfirmationModal
            open={openEditorModal}
            setOpen={setOpenEditorModal}
            title="Salir"
            body={
               <>
                  <Typography>¿Está seguro que desea salir sin guardar los cambios?</Typography>
                  <Typography color={"red"} paddingTop={1} fontStyle={"italic"} fontSize={"14px"}>
                     Una vez confirmado, la información no podrá ser recuperada
                  </Typography>
               </>
            }
            onConfirm={navigateOnConfirm}
            onCancel={(e) => {
               setPath(null);
            }}
         />
         <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBarComponent />
            <CustomDrawer
               variant="permanent"
               open={open}
               onMouseOver={() => setArrowState(true)}
               onMouseLeave={() => setArrowState(false)}
            >
               <Toolbar />
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     mt: 1.4,
                     height: "90%",
                  }}
               >
                  <List>
                     <ListItem key={"inicio"} disablePadding sx={{ display: "block" }} onClick={() => setOpen(false)}>
                        <ListItemButton
                           sx={{
                              height: 35,
                              justifyContent: open ? "initial" : "center",
                              px: 2.5,
                           }}
                           onClick={() => setPath("/inicio")}
                        >
                           <ListItemIcon
                              sx={{
                                 minWidth: 0,
                                 mr: open ? 3 : "auto",
                                 justifyContent: "center",
                              }}
                           >
                              <Dashboard sx={{ color: "#162c44" }} />
                           </ListItemIcon>
                           <ListItemText
                              primary={<Typography variant="subtitle1">Inicio</Typography>}
                              sx={{ opacity: open ? 1 : 0 }}
                           />
                        </ListItemButton>
                     </ListItem>

                     {cdAccess && (
                        <>
                           <Divider sx={{ mx: 2, mt: 1 }} />
                           <ComplaintProvider>
                              <ComplaintChannelMenu open={open} setOpen={setOpen} />
                           </ComplaintProvider>
                        </>
                     )}
                     {bpAccess && (
                        <>
                           <Divider sx={{ mx: 2, mt: 1 }} />
                           <GovernanceMenu open={open} setOpen={setOpen} />
                        </>
                     )}
                  </List>
               </Box>
            </CustomDrawer>
            <Box
               sx={{
                  visibility: arrowState ? "visible" : "hidden",
                  borderRadius: "50px",
                  position: "relative",
                  left: "-20px",
                  top: "80px",
                  height: "20px",
                  zIndex: 1300,
                  width: "0",
                  boxShadow: "none",
               }}
               onMouseOver={() => setArrowState(true)}
               onMouseLeave={() => setArrowState(false)}
            >
               <Box
                  sx={{
                     position: "fixed",
                  }}
               >
                  {open ? (
                     <IconButton onClick={() => setOpen(false)}>
                        <KeyboardArrowLeft sx={IconStyles} />
                     </IconButton>
                  ) : (
                     <IconButton onClick={() => setOpen(true)}>
                        <KeyboardArrowRight sx={IconStyles} />
                     </IconButton>
                  )}
               </Box>
            </Box>
            <Box
               component="main"
               sx={{
                  flex: 1,
                  minHeight: "100vh",
                  bgcolor: "whitesmoke",
                  pt: 2,
               }}
            >
               <Toolbar />
               <Outlet />
            </Box>
         </Box>
      </SystemThemeConfig>
   );
}

const IconStyles = {
   borderRadius: "50px",
   bgcolor: "white",
   borderStyle: "solid",
   borderColor: "gray",
   borderWidth: "1px",
};
