import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";
import { getUrlS3, moveFileToFolder } from "../../../lib/usersBEClient";

const GetSessionVerifyModal = (memberToVerify) => {
   const { session } = useContext(GovernanceSessionContext);
   const [file, setFile] = useState("");
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const fetchDoc = async () => {
         setIsLoading(true);
         const documentUrl: string = await getUrlS3(
            "files-lecosy",
            { folder: `gc/companies/${session.company}/governing-body/sessions/${session._id}` },
            `join-${memberToVerify.userId}`
         );
         setFile(documentUrl);
         setIsLoading(false);
      };
      if (memberToVerify.fileName) fetchDoc();
      else setIsLoading(false);
   }, []);

   return { file, isLoading };
};

export const SessionVerifyModal = ({ setOpenModal, memberToVerify, isFromSign }) => {
   const { membersToVerify, setMembersToVerify, signsArray, setSignsArray, session, socket, fetchSignArrayList } =
      useContext(GovernanceSessionContext);
   // const { file, isLoading } = GetSessionVerifyModal(memberToVerify);

   const handleVerify = async () => {
      if (memberToVerify.external) {
         if (isFromSign === "sign") {
            await moveFileToFolder(
               "files-lecosy",
               `gc/companies/${session.company}/governing-body/sessions/to-verify/${session._id}`,
               `gc/companies/${session.company}/governing-body/sessions/${session._id}`,
               `sign-${memberToVerify.userId}.png`
            );
            const arrayList = await fetchSignArrayList();
            socket.emit("verify-sign", { sessionId: session._id, signArray: arrayList });
         } else socket.emit("verify-request-external", { ...memberToVerify, access: true, sessionId: session._id });
      } else {
         if (isFromSign === "sign") {
            await moveFileToFolder(
               "files-lecosy",
               `gc/companies/${session.company}/governing-body/sessions/to-verify/${session._id}`,
               `gc/companies/${session.company}/governing-body/sessions/${session._id}`,
               `sign-${memberToVerify.userId}.png`
            );
            const arrayList = await fetchSignArrayList();
            socket.emit("verify-sign", { sessionId: session._id, signArray: arrayList });
         } else socket.emit("verify-request", { ...memberToVerify, access: true, sessionId: session._id });
      }

      const memberWithoutVerify = membersToVerify.filter(
         (member: any) => (member.user.id || member.user) !== memberToVerify.userId
      );
      setMembersToVerify(memberWithoutVerify);
      socket.emit("send-members-without-verify", { sessionId: session._id, memberWithoutVerify: memberWithoutVerify });
      setOpenModal(false);
   };

   const handleRefuse = () => {
      if (memberToVerify.external) {
         if (isFromSign === "sign") {
            const membersRefuseSign = signsArray.filter((sign) => sign.userId !== memberToVerify.userId);
            setSignsArray(membersRefuseSign);
         } else socket.emit("verify-request-external", { ...memberToVerify, access: false, sessionId: session._id });
      } else {
         if (isFromSign === "sign") {
            const membersRefuseSign = signsArray.filter((sign) => sign.userId !== memberToVerify.userId);
            setSignsArray(membersRefuseSign);
         } else socket.emit("verify-request", { ...memberToVerify, access: false, sessionId: session._id });
      }

      const memberWithoutVerify = membersToVerify.filter(
         (member: any) => (member.user.id || member.user) !== memberToVerify.userId
      );
      setMembersToVerify(memberWithoutVerify);
      setOpenModal(false);
   };

   // if (isLoading) return <CircularProgress />;
   return (
      <Stack sx={{ width: 680 }}>
         <Box
            sx={{
               py: 1,
               bgcolor: "#152c44",
               borderTopLeftRadius: 15,
               borderTopRightRadius: 15,
               display: "flex",
               justifyContent: "space-between",
               alignItems: "center",
            }}
         >
            <Typography sx={{ color: "white", ml: 4, fontWeight: 600 }}>Verificar archivo del usuario</Typography>
         </Box>
         <Stack
            spacing={3}
            sx={{
               bgcolor: "background.paper",
               py: 3,
               px: 6,
               borderBottomLeftRadius: 15,
               borderBottomRightRadius: 15,
            }}
         >
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
               <Box
                  sx={{
                     width: "50%",
                     height: "50%",
                     border: 1,
                     borderRadius: 2,
                     borderColor: "#C4C4C4",
                     display: "flex",
                     justifyContent: "center",
                     maxWidth: "500px",
                     maxHeight: "500px",
                  }}
               >
                  <img
                     src={memberToVerify.file}
                     alt="Firma escaneada / imagen de firma"
                     style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "cover" }}
                  />
               </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
               <Button variant="contained" onClick={handleVerify}>
                  Verificar
               </Button>
               <Button variant="contained" onClick={handleRefuse}>
                  Rechazar
               </Button>
            </Box>
         </Stack>
      </Stack>
   );
};
