import { Stack, Box, Typography, IconButton, Button } from "@mui/material";
import { Formik, FormikProps } from "formik";
import { Form, useNavigate } from "react-router-dom";
import { InputCheckBox } from "../../Inputs/InputCheckBox";
import { InputTextField } from "../../Inputs/InputTextField";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { getUsersDetailsByUserId } from "../../../lib/usersBEClient";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";
import { GovernanceTheSequelContext } from "../../../context/governanceContext/governanceTheSequelContext";

const useSecurityQuestionData = () => {
   const [userDetails, setUserDetails] = useState(null);
   const [isLoading, setIsLoading] = useState(true);
   const { user } = useContext(UserContext);

   useEffect(() => {
      const fetchUserDetails = async () => {
         setIsLoading(true);
         const userDetailsData = await getUsersDetailsByUserId(user.id);
         setUserDetails(userDetailsData);
         setIsLoading(false);
      };

      fetchUserDetails();
   }, []);

   return { userDetails, isLoading };
};

export const SecurityQuestionModal = () => {
   const navigate = useNavigate();
   const { userDetails, isLoading } = useSecurityQuestionData();
   const [wrongAnswer, setWrongAnswer] = useState(false);
   const [wrongCount, setWrongCount] = useState(0);
   const { user } = useContext(UserContext);
   const { companySelected, gobernanceBody } = useContext(GovernanceContext);
   const { governanceCoordinators } = useContext(GovernanceTheSequelContext);
   const { documentUrl, sessionFound, socket, setOpenSecurityQuestionModal, session } =
      useContext(GovernanceSessionContext);

   const handleSubmit = (values) => {
      // console.log({ gobernanceBody });
      // const foundGovernance = gobernanceBody.find((govBody) => govBody._id === sessionFound.governance);
      // if (!foundGovernance) return;
      // const isCoordinator = governanceCoordinators.some((govCoord) => govCoord.user._id === user.id);
      // const userInGov = foundGovernance.users.find((govUser) => govUser.user === user.id);
      // console.log({ isCoordinator });
      // console.log({ userInGov });

      // if (!isCoordinator && !userInGov ) {
      // }
      socket.emit("request-to-join", {
         user,
         file: documentUrl,
         request: "join",
         sessionId: sessionFound ? sessionFound._id : session?._id,
      });

      navigate(companySelected + `/sesion/${sessionFound ? sessionFound._id : session?._id}`);
      setOpenSecurityQuestionModal(false);
   };

   if (isLoading) return;

   return (
      <Stack sx={{ width: 680 }}>
         <Formik initialValues={{ securityAnswer: "" }} onSubmit={handleSubmit}>
            {(formProps: FormikProps<any>) => (
               <Form>
                  <Box
                     sx={{
                        py: 2,
                        bgcolor: "#152c44",
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                     }}
                  >
                     <Typography sx={{ color: "white", ml: 4, fontWeight: 600 }}>Pregunta de Seguridad</Typography>
                  </Box>
                  <Stack
                     spacing={2}
                     sx={{
                        bgcolor: "background.paper",
                        py: 3,
                        px: 6,
                        borderBottomLeftRadius: 15,
                        borderBottomRightRadius: 15,
                     }}
                  >
                     <Typography sx={{ fontSize: 16 }}>{userDetails.securityQuestion}</Typography>
                     <InputTextField
                        id={"securityAnswer"}
                        name={"securityAnswer"}
                        type={"text"}
                        fullWidth={true}
                        variant="standard"
                        sx={{ fontSize: 12 }}
                     />
                     {wrongCount > 2 && (
                        <Typography sx={{ fontSize: 12, color: "red" }}>
                           ¿Has olvidado la respuesta? Actualice su pregunta
                        </Typography>
                     )}
                     {wrongCount <= 2 && wrongAnswer && (
                        <Typography sx={{ fontSize: 12, color: "red" }}>
                           Respuesta incorrecta, favor de intentar otra vez
                        </Typography>
                     )}
                     <Box sx={{ display: "flex", justifyContent: "center", pt: 1 }}>
                        <Button
                           variant="contained"
                           sx={{ width: "50%" }}
                           onClick={() => {
                              if (
                                 formProps.values.securityAnswer.toLowerCase() ===
                                 userDetails.securityAnswer.toLowerCase()
                              ) {
                                 setWrongAnswer(false);
                                 formProps.submitForm();
                              } else {
                                 setWrongCount((s) => s + 1);
                                 setWrongAnswer(true);
                              }
                           }}
                        >
                           Aceptar
                        </Button>
                     </Box>
                  </Stack>
               </Form>
            )}
         </Formik>
      </Stack>
   );
};
