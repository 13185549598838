import { Typography, Box, Grid, TextField } from "@mui/material";
import { StructureData } from "./GoverningBodiesComponent";

interface StructureDataDisplayProps {
   structureData: StructureData;
   bodyTitle: string;
}

export const StructureDataDisplay = (props: StructureDataDisplayProps) => {
   const { structureData, bodyTitle } = props;
   return (
      <Grid container sx={{ m: 3, rowGap: 3 }}>
         <Grid item xs={4}>
            <Typography variant="subtitle2">Días para mandar convocatoria:</Typography>
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 1,
                  mt: 1,
               }}
            >
               <TextField
                  disabled
                  size="small"
                  value={structureData && structureData.sendAnnouncementDays}
                  sx={{ width: 100 }}
               />
               <Typography variant="caption">Días</Typography>
            </Box>
         </Grid>
         <Grid item xs={4}>
            <Typography variant="subtitle2">Días para confirmar asistencia:</Typography>
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 1,
                  mt: 1,
               }}
            >
               <TextField
                  disabled
                  size="small"
                  value={structureData && structureData.confirmationDays}
                  sx={{ width: 100 }}
               />
               <Typography variant="caption">Días</Typography>
            </Box>
         </Grid>

         {bodyTitle !== "Asamblea de accionistas" && (
            <>
               <Grid item xs={4}>
                  <Typography variant="subtitle2">Parámetro de integración:</Typography>
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        mt: 1,
                     }}
                  >
                     <Typography variant="caption">Mínimo</Typography>
                     <TextField
                        disabled
                        sx={{ width: 70 }}
                        size="small"
                        value={structureData && structureData.integrationMin}
                     />
                     <Typography variant="caption">Máximo</Typography>
                     <TextField
                        disabled
                        sx={{ width: 70 }}
                        size="small"
                        value={structureData && structureData.integrationMax}
                     />
                  </Box>
               </Grid>
               <Grid item xs={4} rowGap={1}>
                  <Typography variant="subtitle2">Duración de cargos:</Typography>
                  <TextField
                     disabled
                     size="small"
                     fullWidth
                     value={structureData && structureData.chargesDuration}
                     sx={{ width: 100, mt: 1 }}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Typography variant="subtitle2">Quórum requerido:</Typography>
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        mt: 1,
                     }}
                  >
                     <TextField disabled size="small" value={structureData?.quorum} sx={{ width: 100, mt: 1 }} />
                     <Typography fontSize={17} variant="caption">
                        %
                     </Typography>
                  </Box>
               </Grid>
               <Grid item xs={4} rowGap={1}>
                  <Typography variant="subtitle2">Porcentaje para resoluciones:</Typography>
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        mt: 1,
                     }}
                  >
                     <TextField
                        disabled
                        size="small"
                        value={structureData?.resolutionVotes}
                        sx={{ width: 100, mt: 1 }}
                     />
                     <Typography fontSize={17} variant="caption">
                        %
                     </Typography>
                  </Box>
               </Grid>
               <Grid item xs={12}>
                  <Typography variant="subtitle2">Composición cualitativa:</Typography>
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        justifyContent: "space-evenly",
                        mt: 1,
                     }}
                  >
                     <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                        <Typography variant="caption">Patrimonial</Typography>
                        <Box
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 1,
                           }}
                        >
                           <TextField
                              disabled
                              sx={{ width: 100 }}
                              size="small"
                              value={structureData && structureData.compositionP}
                           />
                           <Typography fontSize={17} variant="caption">
                              %
                           </Typography>
                        </Box>
                     </Box>
                     <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                        <Typography variant="caption">Relacionado</Typography>
                        <Box
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 1,
                           }}
                        >
                           <TextField
                              disabled
                              sx={{ width: 100 }}
                              size="small"
                              value={structureData && structureData.compositionR}
                           />
                           <Typography fontSize={17} variant="caption">
                              %
                           </Typography>
                        </Box>
                     </Box>
                     <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                        <Typography variant="caption">Independiente</Typography>
                        <Box
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 1,
                           }}
                        >
                           <TextField
                              disabled
                              sx={{ width: 100 }}
                              size="small"
                              value={structureData && structureData.compositionI}
                           />
                           <Typography fontSize={17} variant="caption">
                              %
                           </Typography>
                        </Box>
                     </Box>
                  </Box>
               </Grid>
            </>
         )}
      </Grid>
   );
};
