import { Box, CircularProgress, Grid } from "@mui/material";
import { FilesInformationComponent } from "./SubComponents/FilesInformationComponent";
import { useContext, useEffect, useState } from "react";
import { getFilesByUserId } from "../../../lib/gobCorpBEClient";
import { UserContext } from "../../../context/userContext";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";

const gridRow = {
   display: "flex",
   alignItems: "center",
};

const useGetFiles = (seed: number) => {
   const [files, setFiles] = useState<any>(null);
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const { user } = useContext(UserContext);
   const { companySelected } = useContext(GovernanceContext);

   useEffect(() => {
      setIsLoading(true);
      const fetch = async () => {
         const data = await getFilesByUserId(user.id, companySelected);
         setFiles(data);
         setIsLoading(false);
      };
      fetch();
   }, [seed]);
   return { files, isLoading };
};

export const DocumentationComponent = () => {
   const [seed, setSeed] = useState(0);
   const { files, isLoading } = useGetFiles(seed);
   if (isLoading) return <CircularProgress />;

   return (
      <>
         <Grid container rowSpacing={3} key={seed}>
            <Grid item xs={4} sx={gridRow}>
               Curriculum vitae o semblanza personal
            </Grid>
            <Grid item xs={8}>
               <FilesInformationComponent
                  file={files ? files.cv : null}
                  fileName={"cv"}
                  fileNameToUpload={"cv"}
                  id={files ? files._id : null}
                  setSeed={setSeed}
               />
            </Grid>
            <Grid item xs={4} sx={gridRow}>
               Copia de título profesional
            </Grid>
            <Grid item xs={8}>
               <FilesInformationComponent
                  file={files ? files.tituloProfesional : null}
                  fileName={"tituloProfesional"}
                  fileNameToUpload={"tituloProfesional"}
                  id={files ? files._id : null}
                  setSeed={setSeed}
               />
            </Grid>
            <Grid item xs={4} sx={gridRow}>
               Cédula profesional
            </Grid>
            <Grid item xs={8}>
               <FilesInformationComponent
                  file={files ? files.cedulaProfesional : null}
                  fileNameToUpload={"cedulaProfesional"}
                  fileName={"cedulaProfesional"}
                  id={files ? files._id : null}
                  setSeed={setSeed}
               />
            </Grid>
            <Grid item xs={4} sx={gridRow}>
               Historial en buró de crédito
            </Grid>
            <Grid item xs={8}>
               <FilesInformationComponent
                  file={files ? files.historialCrediticio : null}
                  fileName={"historialCrediticio"}
                  fileNameToUpload={"historialCrediticio"}
                  id={files ? files._id : null}
                  setSeed={setSeed}
               />
            </Grid>
            <Grid item xs={4} sx={gridRow}>
               Carta de recomendación 1
            </Grid>
            <Grid item xs={8}>
               <FilesInformationComponent
                  file={files ? files.cartaRecomendacion1 : null}
                  fileName={"cartaRecomendacion1"}
                  fileNameToUpload={"cartaRecomendacion1"}
                  id={files ? files._id : null}
                  setSeed={setSeed}
               />
            </Grid>
            <Grid item xs={4} sx={gridRow}>
               Carta de recomendación 2
            </Grid>
            <Grid item xs={8}>
               <FilesInformationComponent
                  file={files ? files.cartaRecomendacion2 : null}
                  fileName={"cartaRecomendacion2"}
                  fileNameToUpload={"cartaRecomendacion2"}
                  id={files ? files._id : null}
                  setSeed={setSeed}
               />
            </Grid>
            <Grid item xs={4} sx={gridRow}>
               Carta de recomendación 3
            </Grid>
            <Grid item xs={8}>
               <FilesInformationComponent
                  file={files ? files.cartaRecomendacion3 : null}
                  fileName={"cartaRecomendacion3"}
                  fileNameToUpload={"cartaRecomendacion3"}
                  id={files ? files._id : null}
                  setSeed={setSeed}
               />
            </Grid>
            <Grid item xs={4} sx={gridRow}>
               Acta de nacimiento
            </Grid>
            <Grid item xs={8}>
               <FilesInformationComponent
                  file={files ? files.actaNacimiento : null}
                  fileName={"actaNacimiento"}
                  fileNameToUpload={"actaNacimiento"}
                  id={files ? files._id : null}
                  setSeed={setSeed}
               />
            </Grid>
            <Grid item xs={4} sx={gridRow}>
               Credencial de elector
            </Grid>
            <Grid item xs={8}>
               <FilesInformationComponent
                  file={files ? files.credencialElector : null}
                  fileName={"credencialElector"}
                  fileNameToUpload={"credencialElector"}
                  id={files ? files._id : null}
                  setSeed={setSeed}
               />
            </Grid>
            <Grid item xs={4} sx={gridRow}>
               Firma escaneada / imagen de firma
            </Grid>
            <Grid item xs={8}>
               <FilesInformationComponent
                  file={files ? files.firmaEscaneada : null}
                  fileName={"firmaEscaneada"}
                  fileNameToUpload={"firmaEscaneada"}
                  id={files ? files._id : null}
                  setSeed={setSeed}
               />
            </Grid>
            <Grid item xs={4} sx={gridRow}>
               Comprobante de domicilio
            </Grid>
            <Grid item xs={8}>
               <FilesInformationComponent
                  file={files ? files.comprobanteDomicilio : null}
                  fileName={"comprobanteDomicilio"}
                  fileNameToUpload={"comprobanteDomicilio"}
                  id={files ? files._id : null}
                  setSeed={setSeed}
               />
            </Grid>
            {files
               ? files.tituloAccionario && (
                    <Grid item xs={4} sx={gridRow}>
                       Titulo Accionario
                    </Grid>
                 )
               : null}
            {files
               ? files.tituloAccionario && (
                    <Grid item xs={8}>
                       <FilesInformationComponent
                          file={files ? files.tituloAccionario : null}
                          fileName={"tituloAccionario"}
                          fileNameToUpload={"tituloAccionario"}
                          id={files ? files._id : null}
                          setSeed={setSeed}
                       />
                    </Grid>
                 )
               : null}
         </Grid>
      </>
   );
};
