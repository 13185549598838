import { Box, Divider, Typography } from "@mui/material";
import React, { useContext } from "react";
import Card from "@mui/material/Card";
import InsightsComponent from "./InsightsComponent";
import CarouselComponent from "../../../CarouselComponent";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";

export const InsightsRender = ({ isUserPanel }) => {
   const { savedInsights, insights } = useContext(GovernanceContext);

   return (
      <Card sx={{ borderRadius: 2, py: 2 }}>
         <Box sx={{ px: 3 }}>
            <Typography color={"#162c44"} fontSize={20} fontWeight={700}>
               Latest Insights
            </Typography>
            <Divider sx={{ my: 1 }} />
         </Box>
         <CarouselComponent
            maxElements={isUserPanel ? 1 : 2}
            hideSlide={insights.length < 3}
            rows={isUserPanel ? 2 : 1}
         >
            {insights.length > 0 &&
               savedInsights &&
               insights.map((insight, index) => (
                  <InsightsComponent
                     insight={insight}
                     key={index}
                     saved={savedInsights?.findIndex((s) => s.fileDirection === insight.link) >= 0 ? true : false}
                  />
               ))}
         </CarouselComponent>
      </Card>
   );
};
