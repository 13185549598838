import { Grid, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { ExternalGovernanceSessionContext } from "../../../../../context/governanceContext/externalSessionContext";

export const BillCommitmentExternal = ({ commitment, index }) => {
   const { session } = useContext(ExternalGovernanceSessionContext);

   const gridWidthConst = session.group ? 3 : 4;

   return (
      <Grid
         item
         container
         xs={12}
         columnSpacing={0.2}
         sx={{
            "&:hover": {
               "& .iconButton": {
                  opacity: 1,
               },
            },
         }}
      >
         <Grid
            item
            xs={gridWidthConst}
            sx={{
               display: "flex",
               bgcolor: "#EFEFEF",
               height: "100%",
               alignItems: "center",
               justifyContent: "center",
            }}
         >
            <Typography
               sx={{
                  fontSize: 12,
                  textAlign: "center",
                  whiteSpace: "pre-line",
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
               }}
            >
               {commitment?.users?.map((u) => `${u?.firstName || u?.email} ${u?.lastName || ""}`)?.join(", ")}
            </Typography>
         </Grid>
         <Grid
            item
            xs={gridWidthConst}
            sx={{
               display: "flex",
               bgcolor: "#EFEFEF",
               height: "100%",
               borderLeft: 1,
               borderColor: "white",
               alignItems: "center",
               justifyContent: "center",
            }}
         >
            <Typography sx={{ bgcolor: "#EFEFEF", fontSize: 12, textAlign: "center" }}>
               {commitment?.activity}
            </Typography>
         </Grid>
         {session.group && (
            <Grid
               item
               xs={3}
               sx={{
                  display: "flex",
                  bgcolor: "#EFEFEF",
                  height: "100%",
                  borderLeft: 1,
                  borderColor: "white",
                  alignItems: "center",
                  justifyContent: "center",
               }}
            >
               <Stack>
                  {commitment?.companies?.map((company, index) => (
                     <Typography
                        key={`${index}-${company?.person_details?.comercialName}`}
                        sx={{ fontSize: 12, textAlign: "center" }}
                     >
                        {company?.person_details?.comercialName || ""}
                     </Typography>
                  ))}
               </Stack>
            </Grid>
         )}

         <Grid
            item
            xs={gridWidthConst}
            sx={{
               display: "flex",
               bgcolor: "#EFEFEF",
               height: "100%",
               borderLeft: 1,
               borderColor: "white",
               alignItems: "center",
               justifyContent: "center",
            }}
         >
            <Typography sx={{ bgcolor: "#EFEFEF", fontSize: 12, textAlign: "center" }}>
               {Number(new Date(commitment?.limitDate).toLocaleDateString("es-mx").split("/")[2]) >= 3000
                  ? "Indefinido"
                  : commitment?.limitDate?.split("T")[0] || "Indefinido"}
            </Typography>
         </Grid>
      </Grid>
   );
};
