import { Avatar, AvatarGroup, Badge, Box, Card, CircularProgress, Stack, Tooltip, Typography } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import ChatIcon from "@mui/icons-material/Chat";
import { Draggable } from "react-beautiful-dnd";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../context/userContext";
import { TaskModalComponent } from "./Modals/TaskModalComponent";
import { CommitmentModal } from "./Modals/CommitmentModal";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { GovernanceTheSequelContext } from "../../../../context/governanceContext/governanceTheSequelContext";
import { YoutubeSearchedFor } from "@mui/icons-material";

const styleIcons = { width: 20 };
const formatPriority = (priority: string) => {
   switch (priority) {
      case "HIGH":
         return "Alto";
      case "LOW":
         return "Bajo";
      case "MEDIUM":
         return "Media";
      default:
         break;
   }
};

const useGetUsers = (users, allUsers) => {
   const [usersFiltered, setUsersFiltered] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   useEffect(() => {
      const getUsers = async () => {
         setIsLoading(true);
         const user = allUsers.filter((user) => users.includes(user.id));
         if (!user) return setUsersFiltered(null);
         setUsersFiltered(user);
         setIsLoading(false);
      };
      getUsers();
   }, [users]);
   return { usersFiltered, isLoading };
};

const AssignedUsersAvatar = ({ users, user, owner }) => {
   const [newUsers, setNewUsers] = useState([]);
   const { userProfilePicture } = useContext(UserContext);

   let formattedOwner = [];
   if (user.id === owner) {
      const ownerF = {
         user: user.userName + " " + user.userLastName,
         id: user.id,
         profilePicture: userProfilePicture,
      };
      formattedOwner.push(ownerF);
   }
   useEffect(() => {
      const newUsersE: any = [...users, ...formattedOwner];
      setNewUsers(newUsersE);
   }, [users]);

   const remainingUsers = newUsers.slice(2);
   return (
      <AvatarGroup
         sx={{
            ".MuiAvatarGroup-avatar": { width: 25, height: 25 },
         }}
      >
         {newUsers.slice(0, 2).map((user, index) => (
            <Tooltip title={user.user} key={index}>
               <Avatar src={user.profilePicture}>
                  <Typography fontSize={12}>{user.user.slice(0, 1)}</Typography>
               </Avatar>
            </Tooltip>
         ))}
         {remainingUsers.length > 0 ? (
            <Tooltip
               title={remainingUsers.map((user, index) => (
                  <Stack key={index}>
                     <Typography fontSize={12}>{user.user}</Typography>
                  </Stack>
               ))}
            >
               <Avatar sx={{ backgroundColor: "#868FA0" }}>
                  <Typography fontSize={12}>{`+${remainingUsers.length}`}</Typography>
               </Avatar>
            </Tooltip>
         ) : null}
      </AvatarGroup>
   );
};

export interface KanbanTaskComponentProps {
   governingBody: string;
   index: string;
   allUsers: any[];
   task: any;
}
export const KanbanTaskComponent = (props: KanbanTaskComponentProps) => {
   const priory = formatPriority(props.task.priority);
   const { user } = useContext(UserContext);
   const { usersFiltered, isLoading } = useGetUsers(props.task.users, props.allUsers);
   const { governanceCoordinators } = useContext(GovernanceTheSequelContext);
   const [openTask, setOpenTask] = useState(false);
   const [openCommitment, setOpenCommitment] = useState(false);

   const quitarEtiquetas = (text) => {
      return text?.replace(/<[^>]+>/g, "");
   };

   if (isLoading && !props.task.tags) return <CircularProgress />;

   const isDisabled = !(
      user.id === props.task.owner ||
      props.task.users.includes(user.id) ||
      governanceCoordinators?.some((u) => u?.user?._id === user?.id)
   );

   return (
      <>
         <Draggable draggableId={props.task._id} index={props.index} isDragDisabled={isDisabled}>
            {(provided) => (
               <Card
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                  ref={provided.innerRef}
                  id
                  sx={{
                     borderRadius: 4,
                     p: 2,
                     boxShadow: 2,
                     height: 150,
                     width: 240,
                     flexDirection: "column",
                     display: "flex",
                     justifyContent: "space-between",
                     maxWidth: "100%",
                     ":hover": { bgcolor: "#F9F9F9", cursor: isDisabled ? "pointer" : "grab" },
                  }}
                  onClick={() => {
                     if (props.task.dueDate) setOpenCommitment(true);
                     else setOpenTask(true);
                  }}
               >
                  <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                     <Stack direction={"row"} spacing={1} sx={{ alignItems: "center" }}>
                        <Box
                           sx={{
                              height: 10,
                              width: 10,
                              backgroundColor: props.task.tags.length > 0 ? props.task.tags[0].color : "gray",
                              borderRadius: "50%",
                           }}
                        />
                        <Typography fontSize={12} fontWeight={700}>
                           {props.task.dueDate
                              ? props.task?.session?.billCode
                              : props.task?.tags.length > 0
                              ? props.task.tags[0].title
                              : "Mis Tareas"}
                        </Typography>
                     </Stack>
                  </Stack>
                  <Box>
                     <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                        <Typography fontWeight={700}>
                           {props.task.title.length > 20
                              ? props.task.title.substring(0, 20).concat("...")
                              : props.task.title}
                        </Typography>
                     </Box>

                     <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                        <Typography fontSize={13} sx={{ maxWidth: "100%" }}>
                           {props.task.description?.length > 30
                              ? quitarEtiquetas(props.task.description).substring(0, 30).concat("...")
                              : quitarEtiquetas(props.task.description)}
                        </Typography>
                     </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "end", color: "gray", columnGap: 2 }}>
                     <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                        {!props.task.dueDate && (
                           <Tooltip title={priory}>
                              <FlagIcon
                                 sx={{
                                    ...styleIcons,
                                    color:
                                       priory === "Alto"
                                          ? "#ff0000"
                                          : priory === "Media"
                                          ? "#ffa500"
                                          : priory === "Bajo"
                                          ? "#009929"
                                          : "",
                                 }}
                              />
                           </Tooltip>
                        )}
                        {(props.task.allowedComments || props.task.dueDate) && (
                           <Badge color="primary" badgeContent={props.task.comments?.length || 0}>
                              <ChatIcon sx={styleIcons} />
                           </Badge>
                        )}
                     </Box>
                     <Stack direction={"row"}>
                        <AssignedUsersAvatar user={user} users={usersFiltered} owner={props.task.owner} />
                     </Stack>
                  </Box>
               </Card>
            )}
         </Draggable>

         <TaskModalComponent users={props.allUsers} open={openTask} setOpen={setOpenTask} task={props.task} />
         <CommitmentModal
            users={props.allUsers}
            open={openCommitment}
            setOpen={setOpenCommitment}
            commitment={props.task}
         />
      </>
   );
};
