import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useCallback, useContext, useState } from "react";
import { Button, Card, CardActions, CardContent, CircularProgress, Collapse, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { UseGetPresignedURLUpload, uploadFileToS3 } from "../../../../lib/s3Client";
import { UserContext } from "../../../../context/userContext";
import { updateFilesByUserId } from "../../../../lib/gobCorpBEClient";
import { CustomModalComponent } from "../../../CustomModalComponent";
import { CreateProfileContext } from "../../../../context/beneficiaryContext/createProfileContext";

interface ModalAddStudyComponentProps {
   open: boolean;
   setOpen: Function;
   file: any;
   fileName: string;
   id: string;
   setSeed: Function;
   userId?: string;
}

export const ModalAddFilesComponent = (props: ModalAddStudyComponentProps) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { setFileToDelete } = useContext(CreateProfileContext);
   const [fileToUpload, setFileToUpload] = useState<any>(props.file);
   const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);
   const { user, isCompanyAdmin } = useContext(UserContext);

   const handleUploadFiles = async () => {
      const extension = fileToUpload.name.split(".").pop();
      const newFileName = `${props.fileName}.${extension}`;
      const urlReceipt1 = await UseGetPresignedURLUpload({
         bucket: "files-lecosy",
         folder: `gc/users/${props.userId ? props.userId : user.id}/files`,
         name: newFileName,
      });
      await uploadFileToS3(urlReceipt1.data, fileToUpload);
   };

   const handleSubmit = async () => {
      setIsLoadingUploadFile(true);
      if (props.file) {
         if (props.file.name === fileToUpload.name) {
            setIsLoadingUploadFile(false);
            props.setOpen(false);
            return;
         }
      }
      if (!fileToUpload) {
         showSnackBar("Favor de añadir un archivo", true);
         setIsLoadingUploadFile(false);
         return;
      }
      if (fileToUpload.size > 20971520) {
         showSnackBar("Archivo mayor a 20 MB", true);
         setIsLoadingUploadFile(false);
         return;
      }

      if (
         (fileToUpload.type !== "application/pdf" || props.fileName === "firmaEscaneada") &&
         props.fileName !== "firmaEscaneada" &&
         fileToUpload.type !== "image/jpeg" &&
         fileToUpload.type !== "image/png"
      ) {
         showSnackBar("Favor de añadir solo archivos PDF o formato de imagen en caso de ser la firma", true);
         setIsLoadingUploadFile(false);
         return;
      }
      const extension = fileToUpload.name.split(".").pop();
      const newFileName = `${props.fileName}.${extension}`;

      let file = {};

      if (isCompanyAdmin) {
         file = {
            [props.fileName]: {
               name: newFileName,
               size: `${fileToUpload.size}`,
               verified: true,
            },
         };
      } else {
         file = {
            [props.fileName]: {
               name: newFileName,
               size: `${fileToUpload.size}`,
            },
         };
      }

      try {
         await updateFilesByUserId(props.userId ? props.userId : user.id, file);
         handleUploadFiles();
         props.setSeed(Math.random());
         showSnackBar("Archivo modificado con éxito", false);
         props.setOpen(false);
      } catch (error) {
         console.error(error);
         showSnackBar("Error al actualizar los datos", true);
      }
   };

   const onDrop = useCallback(
      (acceptedFiles) => {
         if (acceptedFiles.length < 1) {
            showSnackBar("Favor de seleccionar un archivo valido.", true);
         }
         if (acceptedFiles[0].type.includes("pdf") && acceptedFiles.size > 5000000) {
            showSnackBar("Archivo mayor a 5 MB", true);
            return;
         }
         setFileToUpload(acceptedFiles[0]);
      },
      [showSnackBar, setFileToUpload]
   );

   const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept:
         props.fileName === "firmaEscaneada"
            ? { "application/jpg": [".jpg"], "application/png": [".png"] }
            : {
                 "application/pdf": [".pdf"],
              },
      maxFiles: 1,
   });

   return (
      <CustomModalComponent title={"Agregar nuevo archivo"} setOpen={props.setOpen} open={props.open} timeStamp>
         <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", p: 2, minWidth: 450 }}>
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  flex: 2,
               }}
            >
               <Box
                  sx={{
                     flex: 1,
                     minWidth: "80%",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     mb: 2,
                  }}
               >
                  {fileToUpload ? (
                     <></>
                  ) : (
                     <Box
                        sx={{
                           flex: 1,
                           border: 1,
                           borderStyle: "dashed",
                           borderRadius: 2,
                           minHeight: 150,
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                        {...getRootProps()}
                     >
                        <Typography variant="body2" color={"grey"}>
                           Arrastra y suelta dentro del recuadro los archivos
                        </Typography>
                        <input {...getInputProps()} />
                     </Box>
                  )}
               </Box>
               {!fileToUpload && (
                  <Box
                     sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 1,
                        mb: 2,
                     }}
                  >
                     <Box sx={{ bgcolor: "whitesmoke", borderRadius: 2, p: 1 }}>
                        <Typography fontSize={12}>Solo archivos PDF, no mayores a 20 MB.</Typography>
                     </Box>
                  </Box>
               )}
               <Collapse in={fileToUpload ? true : false}>
                  <Typography align="center">Archivo adjunto:</Typography>
                  <Box sx={{ pb: 1, bx: 1 }}>
                     {fileToUpload && (
                        <Card
                           key={fileToUpload.name}
                           sx={{
                              display: "flex",
                              bgcolor: "#F9F9F9",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              mt: 0.5,
                              boxShadow: 0,
                              border: 1,
                              borderColor: "#E2E2E2",
                           }}
                        >
                           <CardContent>
                              <Typography sx={{ fontSize: 14, flexWrap: "nowrap" }}>{fileToUpload.name}</Typography>
                           </CardContent>
                           <CardActions>
                              <IconButton
                                 onClick={() => {
                                    setFileToUpload(null);
                                    setFileToDelete(props.file);
                                 }}
                              >
                                 <DeleteIcon sx={{ color: "red" }} />
                              </IconButton>
                           </CardActions>
                        </Card>
                     )}
                  </Box>
               </Collapse>
            </Box>
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flex: 1,
                  py: 2,
                  px: 4,
               }}
            >
               <Button
                  onClick={() => {
                     setFileToUpload(null);
                     props.setOpen(false);
                  }}
                  variant="outlined"
                  sx={{ maxHeight: 40 }}
               >
                  Cancelar
               </Button>
               <Button onClick={handleSubmit} disabled={isLoadingUploadFile} variant="contained" sx={{ maxHeight: 40 }}>
                  {isLoadingUploadFile ? <CircularProgress size={24} /> : "Subir"}
               </Button>
            </Box>
         </Box>
      </CustomModalComponent>
   );
};
