import { Box, CircularProgress, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { getAdviceByGoberningBodyId } from "../../../lib/gobCorpBEClient";
import { UserContext } from "../../../context/userContext";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { AdviceCardComponent } from "./AdviceCardComponent";
import { ModalAddAdviceComponent } from "./AddAdviceModal";
import { CustomModalComponent } from "../../CustomModalComponent";
import { Info, AddCircle } from "@mui/icons-material";
import { GovernanceTheSequelContext } from "../../../context/governanceContext/governanceTheSequelContext";
import { getNameUserData } from "../../../lib/usersBEClient";

const useGetAdvices = (goberningBodyId, members) => {
   const { refetchTasks } = useContext(GovernanceContext);
   const { isLoadingUser } = useContext(UserContext);
   const [tasks, setTasks] = useState([]);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      const fetch = async () => {
         setIsLoading(true);
         try {
            const advices = await getAdviceByGoberningBodyId(goberningBodyId);
            const advicesOwners = advices.map((a) => a.owner);
            const uniqueUsers = advicesOwners.filter((value, index, self) => self.indexOf(value) === index);
            const userData = await getNameUserData(uniqueUsers);
            for (const advice of advices) advice.owner = userData.find((user) => user._id === advice.owner);
            setTasks(advices);
         } catch (error) {
            console.log(error);
         }
         setIsLoading(false);
      };
      if (!isLoadingUser && members?.length > 0) fetch();
   }, [refetchTasks, isLoadingUser, members, goberningBodyId]);

   return { tasks, isLoading };
};

const useGetFilteredTasks = (textFiltered, tasks) => {
   const filteredTasks = useMemo(() => {
      if (textFiltered === "") return tasks;
      const tasksFiltered = tasks.filter((task) => {
         if (task?.title) {
            return (
               task?.title?.toLowerCase()?.includes(textFiltered.toLowerCase()) ||
               task?.description?.toLowerCase()?.includes(textFiltered.toLowerCase()) ||
               task?.owner?.firstName?.toLowerCase()?.includes(textFiltered.toLowerCase())
            );
         }
      });
      return tasksFiltered;
   }, [textFiltered, tasks]);
   return { filteredTasks };
};

export const AdvicesScreenComponent = () => {
   const { user } = useContext(UserContext);
   const { members } = useContext(GovernanceTheSequelContext);
   const { selectedGovernance, resourceAccessGob } = useContext(GovernanceContext);
   const { tasks, isLoading: loadingUserTask } = useGetAdvices(selectedGovernance._id, members);
   const [filterText, setFilterText] = useState("");
   const { filteredTasks } = useGetFilteredTasks(filterText, tasks);
   const [permission, setPermission] = useState(0);
   const [open, setOpen] = useState(false);
   const [expandedAdviceId, setExpandedAdviceId] = useState(null);

   useEffect(() => {
      const access = resourceAccessGob.find((r) => r.name === "Tablero de avisos");
      setPermission(access?.permission);
   }, [resourceAccessGob]);

   useEffect(() => {
      if (tasks.length > 0 && !expandedAdviceId) {
         setExpandedAdviceId(tasks[0]._id);
      }
   }, [tasks, expandedAdviceId]);

   if (loadingUserTask) {
      return (
         <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
         </Box>
      );
   }

   return (
      <>
         <Box sx={{ marginTop: 1 }}>
            {selectedGovernance.title !== "PANEL DE USUARIO" && (
               <>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mx: 2,
                     }}
                  >
                     <Typography fontSize={18} fontWeight={700} color={"#152C44"}>
                        Tablero de avisos
                     </Typography>
                     {(permission >= 2 || user.role.find((role) => role.includes("Coordinador"))) && (
                        <IconButton onClick={() => setOpen(true)}>
                           <AddCircle
                              sx={{
                                 color: "primary.main",
                              }}
                           />
                        </IconButton>
                     )}
                  </Box>
               </>
            )}
         </Box>
         <Divider />
         {filteredTasks.length > 0 ? (
            <Box
               sx={{
                  my: 2,
                  maxHeight: 450,
                  overflowX: "hidden",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                     width: 0,
                  },
               }}
            >
               <Stack direction="row" flexWrap="wrap">
                  {filteredTasks.map((advice) => (
                     <AdviceCardComponent
                        key={advice._id}
                        advice={advice}
                        author={advice.owner}
                        expanded={expandedAdviceId === advice._id}
                        onHover={() => setExpandedAdviceId(advice._id)}
                     />
                  ))}
               </Stack>
            </Box>
         ) : (
            <Stack direction="row" width="80%" justifyContent="center" my={0} marginBottom={0}>
               <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ opacity: 0.2 }}
                  rowGap={1}
                  marginBottom={0}
                  marginLeft={2}
                  marginRight={2}
                  height={"100%"}
               >
                  <Info sx={{ fontSize: 40 }} />
                  <Typography fontSize={16} textAlign="center" maxWidth={270} my={2}>
                     No se encontraron avisos
                  </Typography>
               </Box>
            </Stack>
         )}
         <CustomModalComponent open={open} setOpen={setOpen} title="Crear aviso" timeStamp>
            <ModalAddAdviceComponent open={open} setOpen={setOpen} />
         </CustomModalComponent>
      </>
   );
};
