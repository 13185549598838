import ChartDataLabels from "chartjs-plugin-datalabels";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
   Filler,
   ArcElement,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(
   ArcElement,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   ChartDataLabels,
   Title,
   Tooltip,
   Legend,
   Filler
);

interface IPropsDoughnutChart {
   scores: number[];
   labels: string[];
   colors: string[];
   chartRef: React.Ref<any>;
   cutout?: number;
   maintainAspectRatio?: boolean;
   textCenterProp?: any;
   removeBorder?: boolean;
   borderRadius?: boolean;
}

export const DoughnutChart = (props) => {
   function complaintPercentage(recieve) {
      const total = props.scores.reduce((a, b) => a + b, 0);
      const value = (recieve * 100) / total;
      return isNaN(value) ? 0 : value.toFixed(2);
   }
   const textCenter = () => {
      return {
         id: "textCenter",
         afterDraw(chart, args, pluginOptiona) {
            const { ctx } = chart;
            ctx.save();
            ctx.font = props.totalNumberDonut ? `bolder 14px sans-serif` : `bolder 10px sans-serif`;
            ctx.fillStyle = "#162c44";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(
               props.totalNumberDonut
                  ? `Total ${props.totalNumberDonut}`
                  : `${complaintPercentage(chart.data.datasets[0].data[1])}%`,
               chart.getDatasetMeta(0).data[0].x,
               chart.getDatasetMeta(0).data[0].y
            );
            ctx.restore();
         },
      };
   };

   return (
      <Doughnut
         ref={props.chartRef}
         data={{
            datasets: [
               {
                  label: "Mis datos",
                  data: props.scores,
                  backgroundColor: props.colors,
                  borderColor: props.removeBorder ? "transparent" : "#ffffff",
                  borderRadius: props.borderRadius ? 5 : 0,
                  // borderWidth: props.borderRadius ? [0, 5] : [0, 0],
                  hoverOffset: 3,
                  normalized: true,
                  circular: true,
               },
            ],
            labels: props.labels,
         }}
         plugins={[props.textCenterProp ? props.textCenterProp : textCenter()]}
         options={{
            plugins: {
               datalabels: {
                  display: false,
                  textStrokeWidth: 1,
                  color: "white",
               },
               tooltip: { position: "average" },
               legend: { display: false },
            },
            responsive: true,
            maintainAspectRatio: props.maintainAspectRatio ? props.maintainAspectRatio : false,
            cutout: props.cutout ? props.cutout : 60,
         }}
      />
   );
};
