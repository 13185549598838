import { getChargeByGovernUsers, getUserProfData } from "../../lib/gobCorpBEClient";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IMembersWithCharges } from "../../types/governance.types";
import { getUserByRoleAndEntity, getUserDataGB } from "../../lib/usersBEClient";
import { GovernanceContext } from "./governanceContext";
import { UserContext } from "../userContext";
import _ from "lodash";
import { useParams } from "react-router-dom";

interface IGovernanceTheSequelContext {
   members: IMembersWithCharges[];
   setTextValue: Function;
   textValue: string;
   loadingFormat: boolean;
   myGovBody: any[];
   governanceCoordinators: any[];
   fetchCords: Function;
   finalMembers: any[];
}

export const GovernanceTheSequelContext = createContext<IGovernanceTheSequelContext>({
   members: [],
   loadingFormat: false,
   myGovBody: [],
   setTextValue: () => {},
   textValue: "",
   governanceCoordinators: [],
   fetchCords: () => {},
   finalMembers: [],
});

export const GovernanceTheSequelProvider = ({ children }) => {
   const { companyId } = useParams();
   const [loadingFormat, setLoadingFormat] = useState(false);
   const { gobernanceBody, consultiveGroup } = useContext(GovernanceContext);
   const [loadingBodies, setLoadingBodies] = useState(true);
   const { user } = useContext(UserContext);
   const [textValue, setTextValue] = useState("");
   const [members, setMembers] = useState([]);
   const [governanceCoordinators, setGovernanceCoordinators] = useState([]);
   // const [bodies, setBodies] = useState<any[]>([]);

   const processData = useCallback(async () => {
      if (gobernanceBody.length === 0) return;
      let bodies = gobernanceBody.filter((e) => e.title !== "PANEL DE USUARIO");
      setLoadingFormat(true);
      const users = [];
      for (const body of bodies) {
         users.push(...body.users);
      }
      const ids = users.map((e) => e.user);
      //array con formato de govern bodies
      const chargesIds = users.map((e) => e.charge);
      const cleanArray = ids.filter((a, b) => ids.indexOf(a) === b); //string array sin duplicados para mandar al backend
      const chargeData = await getChargeByGovernUsers(chargesIds);
      const userData = await getUserDataGB(cleanArray);
      const userProfData = await getUserProfData(cleanArray);
      //FORMATEO DE USER DATA
      const formatUserIds = userProfData.map((e) => e.ids);
      const formatUserProf = userProfData.map((e) => e.data);

      const theUsers = [];
      for (const user of users) {
         theUsers.push({
            profesionalData: formatUserIds.find((e) => e.user === user.user)?.profesionalData,
            ...user,
         });
      }

      const completePorfile = [];
      for (const theUser of theUsers) {
         completePorfile.push({
            ...chargeData?.find((e) => e._id === theUser.charge),
            ...formatUserProf?.find((e) => e._id === theUser.profesionalData),
            ...userData?.find((e) => e._id === theUser.user),
            ...theUser,
         });
      }

      // ASIGNACION DE DATA A ORGANOS DE GOBIERNO
      const mergedFinalBodies = [];
      for (const body of bodies) {
         let bodiesData = (({ _id, title, company }) => ({ _id, title, company }))(body);
         let usersData = [];
         for (const userBody of body.users) {
            if (
               // onlyShareholdersCheck &&
               body.title === "Asamblea de accionistas"
            ) {
               let userObject = {
                  ...completePorfile.find((e) => e._id === userBody._id && e.chargeName === "Accionista"),
               };
               if (Object.entries(userObject).length !== 0) {
                  usersData.push({ ...userObject });
               }
            } else {
               usersData.push({ ...completePorfile.find((e) => e._id === userBody._id) });
            }
         }
         mergedFinalBodies.push({ bodiesData, usersData });
      }
      setMembers(mergedFinalBodies);
      setLoadingFormat(false);
   }, [gobernanceBody]);

   useEffect(() => {
      processData();
   }, [processData]);

   const myGovBody = useMemo(() => {
      let comiteesInfo = [];
      if (
         ["Usuario de implementación", "Coordinador de gobierno corporativo"].some((role) => user.role.includes(role))
      ) {
         comiteesInfo = members.map((body) => ({
            bodiesData: body.bodiesData,
         }));
         return comiteesInfo;
      }
      const adminCharges = [
         "Accionista",
         "Presidente del consejo de administración",
         "Secretario del consejo de administración",
         "Vicepresidente del consejo de administración",
      ];
      for (const gBody of members) {
         const userExists = gBody.usersData.find((member) => {
            return member.user === user.id;
         });
         if (!userExists) continue;
         if (adminCharges.includes(userExists.chargeName)) {
            for (const body of members) {
               comiteesInfo.push({
                  bodiesData: body.bodiesData,
                  userData: userExists,
               });
            }
            break;
         }
         comiteesInfo.push({
            bodiesData: gBody.bodiesData,
            userData: userExists,
         });
      }
      return comiteesInfo;
   }, [members, user.id, companyId]);

   const finalMembers: any[] = useMemo(() => {
      const comiteeMembers = members.map((gBody) => {
         return {
            bodiesData: gBody.bodiesData,
            usersData: gBody.usersData.filter(
               (member) => !member.chargeName?.includes("implementacion") && !member.chargeName?.includes("Coordinador")
            ),
         };
      });
      if (textValue.trim().length === 0) return comiteeMembers;
      const tempMembers = _.cloneDeep(comiteeMembers);
      const filteredMembers = tempMembers.filter((body) => {
         if (body.bodiesData.title.toLowerCase().includes(textValue.toLowerCase())) return true;
         const memberArray = body.usersData.filter(
            (member) =>
               member.firstName.toLowerCase().includes(textValue.toLowerCase()) ||
               member.lastName.toLowerCase().includes(textValue.toLowerCase())
         );
         if (memberArray.length === 0) return false;
         body.usersData = memberArray;
         return true;
      });

      return filteredMembers;
   }, [textValue, members]);

   const fetchCords = useCallback(async () => {
      try {
         const cordinatorsPromises = [getUserByRoleAndEntity("6596fa5e357d26053b9ec0f4", companyId)];
         if (consultiveGroup?._id === companyId)
            cordinatorsPromises.push(getUserByRoleAndEntity("6596fa5e357d26053b9ec0f4", consultiveGroup._id));
         const results = await Promise.all(cordinatorsPromises);
         const cords = _.uniqBy(results.flat(), "user._id");
         setGovernanceCoordinators(cords);
         return cords;
      } catch (error) {
         console.error("Error fetching coordinators:", error);
      }
   }, [companyId, consultiveGroup]);

   useEffect(() => {
      if (companyId) fetchCords();
   }, [fetchCords]);

   return (
      <GovernanceTheSequelContext.Provider
         value={{
            loadingFormat,
            setTextValue,
            textValue,
            myGovBody,
            members,
            governanceCoordinators,
            finalMembers,
            fetchCords,
         }}
      >
         {children}
      </GovernanceTheSequelContext.Provider>
   );
};
