import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { UserContext } from "../context/userContext";
import { IAffairVotation, IEvent, ISession } from "../types/governance.types";
import { useMutation } from "@tanstack/react-query";
import { AffairsInterface } from "../context/governanceContext/corporateDataContext";

const base_url =
   window.location.hostname === "test.web.lecosy.com.mx" || window.location.hostname === "www.test.web.lecosy.com.mx"
      ? "https://test.server.lecosy.com.mx/gc"
      : process.env.NODE_ENV === "production"
      ? "https://server.lecosy.com.mx/gc"
      : "http://localhost:8003";

const host = base_url;
const PERSONAL_FILES = "/personal-files";
const NOTES = "/notes";
const TAG = "/tag";
const GOVERNING_BODY = "/governing-body";
const USER_DETAILS = "/user-details";
const PROFESIONAL_DATA = "/profesional-data";
const TASK = "/task";
const ADVICE = "/advice";
const COMMENT = "/comment";
const COMMITMENT = "/commitment";
const CONVERSATION = "/conversation";
const MESSAGE = "/message";
const NOTIFICATION = "/notification";
const CHARGES = "/charges";
const AFFAIR = "/affair";
const CLASSIFICATION = "/classification";
const VOTE = "/vote";
const GROUP = "/consultive-group";
const SESSION = "/session";

export const useGetResourceSelected = () => {
   const { selectedResources } = useContext(UserContext);
   return selectedResources?._id;
};

export const handleInvalidRequest = (error) => {
   const details: AxiosError = error as AxiosError;
   if (details.response && details.response.data === "expired") {
      console.log("request session expired");
      return null;
   }
   if (details.response && details.response.data === "no user") {
      console.log("request session expired");
      return null;
   }
};

export const createTag = async (id: string, tagData: any) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.post(`${host}${TAG}/${id}`, tagData);
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getGoverningBodyById = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${GOVERNING_BODY}/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getPersonalSpaceByUserId = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/personal-space/user/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getTagsByUser = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${TAG}/user/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getCommentsByNote = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${NOTES}/comment/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getEventsByCompany = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/event/all/${companyId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getNoteById = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${NOTES}/user/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getGoberningBodiesByUserId = async (id: string, companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/governing-body/all/${id}/${companyId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getCommitmentsInCompaniesByUserId = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/governing-body/commitments/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getNotesByUser = async (uid: string, companySelected: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${NOTES}/user/${uid}/${companySelected}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getNotesBySession = async (sessionId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${NOTES}/session/${sessionId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};
export const getNotesAndCommentsBySession = async (sessionId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(`${host}${NOTES}/session/${sessionId}/comments`);
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createNote = async (noteData: any, Resourceid: string, companySelected: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.post(`${host}${NOTES}`, noteData, {
         headers: {
            r1: companySelected,
            r2: Resourceid,
         },
      });
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const CreateSession = (Resourceid: string, companySelected: string) => {
   return useMutation({
      mutationFn: async (dataN: { sessionData: ISession & { coordMail?: any[] }; governanceId: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}/session/${dataN.governanceId}`, dataN.sessionData, {
            headers: { r1: companySelected, r2: Resourceid },
         });
         return data;
      },
   });
};

export const CreateEventB = (Resourceid: string, companySelected: string) => {
   return useMutation({
      mutationFn: async (eventData: IEvent) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}/event`, eventData, {
            headers: { r1: companySelected, r2: Resourceid },
         });
         return data;
      },
   });
};

export const updateEventById = async (id: string, eventData, Resourceid: string, companySelected: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}/event/${id}`, eventData, {
         headers: {
            r1: companySelected,
            r2: Resourceid,
         },
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateNoteById = async (id: string, noteData: any, Resourceid: string, companySelected: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${NOTES}/update/${id}`, noteData, {
         headers: {
            r1: companySelected,
            r2: Resourceid,
         },
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const deleteNote = async (id: string, Resourceid: string, companySelected: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.delete(`${host}${NOTES}/${id}`, {
         headers: {
            r1: companySelected,
            r2: Resourceid,
         },
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getFilesByUserId = async (id: string, companyId: string, isShareholder?: boolean) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${PERSONAL_FILES}/user/${id}/${companyId}/${isShareholder}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getFilesByCompanyId = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${PERSONAL_FILES}/company/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getPersonalFilesById = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${PERSONAL_FILES}/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getAllPersonalFiles = async (companySelected: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${PERSONAL_FILES}/withoutVerified/${companySelected}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getAllPersonalFilesByFiles = async (userId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${PERSONAL_FILES}/withoutVerified/user/${userId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const UpdateFilesByUserId = (id: string) => {
   return useMutation({
      mutationFn: async (personalSpaceData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(`${host}/personal-space/update/${id}`, personalSpaceData);
         return data;
      },
   });
};

export const UpdatePersonalFilesByUserId = (id: string) => {
   return useMutation({
      mutationFn: async (personalSpaceData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(`${host}/personal-files/updateAndVerified/${id}`, personalSpaceData);
         return data;
      },
   });
};

export const UpdatePersonalSpaceByUserId = () => {
   return useMutation({
      mutationFn: async (personalSpaceData: { files: any; userId: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(`${host}/personal-space/updateByUser/${personalSpaceData.userId}`, {
            files: personalSpaceData.files,
         });
         return data;
      },
   });
};

export const updateFilesByUserId = async (id: string, file: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${PERSONAL_FILES}/update/files/user/${id}`, file);
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const DeleteAFilesByUserId = (id: string) => {
   return useMutation({
      mutationFn: async (personalFiles: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(`${host}/personal-files/${id}`, personalFiles);
         return data;
      },
   });
};

export const getUserDetailsByUserId = async (userId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${USER_DETAILS}/user/${userId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getAllUsersDetailsByGovernance = async (govId) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${USER_DETAILS}/all/governance/${govId}`);
      return data.data;
   } catch (err) {
      console.log(err);
      handleInvalidRequest(err);
   }
};

export const addBasicInfoToUserDetails = async (userInfo: {
   _id: string;
   email: string;
   firstName: string;
   lastName: string;
}) => {
   try {
      const body = { ...userInfo };
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${USER_DETAILS}/all/update/basic`, body);
      return data.data;
   } catch (err) {
      console.log(err);
      handleInvalidRequest(err);
   }
};

export const checkIfExistUserDetailsByUserId = async (userId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${USER_DETAILS}/checkUser/${userId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateUserBasicInformation = async (id: string, userInfo: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${USER_DETAILS}/update/details/user/${id}`, userInfo);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createUserBasicInformation = async (id: string, userInfo: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${USER_DETAILS}/createUserData/${id}`, userInfo);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getProfesionalDataById = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${PROFESIONAL_DATA}/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateProfesionalDataById = async (id: string, values: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${PROFESIONAL_DATA}/${id}`, values);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateRecordsProfesionalDataById = async (id: string, values: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${PROFESIONAL_DATA}/update/data/${id}`, values);
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const deleteProfesionalDataRecord = async (id: string, recordId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.delete(`${host}${PROFESIONAL_DATA}/delete/${id}/${recordId}`);
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getTasksByUserId = async (userId: string, companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${TASK}/user/${userId}/${companyId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createNewTask = async (task: any, Resourceid) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${TASK}`, task, {
         headers: { r1: "", r2: Resourceid },
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
      throw new Error("Error creating task");
   }
};

export const updateTaskStatus = async (id: string, status: string, companyId: string, Resourceid) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(
         `${host}${TASK}/update-status/${id}`,
         { status },
         {
            headers: { r1: companyId, r2: Resourceid },
         }
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
      throw new Error("Error updating task");
   }
};

export const updateCommitmentStatus = async (id: string, status: string, companyId: string, Resourceid) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(
         `${host}${COMMITMENT}/update-status/${id}`,
         { status },
         {
            headers: { r1: companyId, r2: Resourceid },
         }
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
      throw new Error("Error updating task");
   }
};

export const getTagById = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${TAG}/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
      throw new Error("Error tag not found");
   }
};

export const deleteTaskById = async (id: string, Resourceid) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.delete(`${host}${TASK}/${id}`, {
         headers: { r1: "", r2: Resourceid },
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
      throw new Error("Error tag not found");
   }
};

export const getCalendarById = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/calendar/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getAllCalendarByUserId = async (id: string, companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/calendar/all/${id}/${companyId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getAllCalendarByCompanyId = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/calendar/all/byCompany/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getManyCalendarsByIds = async (ids: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}/calendar/many`, ids);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const deleteTag = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.delete(`${host}${TAG}/${id}`);
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getUsersByGovernBody = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${GOVERNING_BODY}/users/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getSessionById = async (id: string, userId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/session/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getSessionByIdNotPopulate = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/session/notPopulated/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getSessionByIdVerify = async (id: string, userId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/session/verify/${userId}/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getSessionByIdExternal = async (id: string, userId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/session/getSessionExternal/${userId}/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createExternalSessionToken = async (id: string, userId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/session/external/createToken/${userId}/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getNumberSessionById = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/session/number/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getSessionByDate = async (userId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/session/getByTodayDate/${userId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const UpdateCompletedSession = () => {
   return useMutation({
      mutationFn: async (dataN: {
         completed?: boolean;
         sessionId: string;
         canceled?: boolean;
         userNotified?: string[];
      }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(`${host}/session/complete/${dataN.sessionId}`, {
            completed: dataN.completed,
            canceled: dataN.canceled,
            userNotified: dataN.userNotified,
         });
         return data;
      },
   });
};

export const UpdateUserAllUserDetails = () => {
   return useMutation({
      mutationFn: async (data: { userId: string; userDetailsData }) => {
         axios.defaults.withCredentials = true;
         await axios.put(`${host}${USER_DETAILS}/allDetails/user/${data.userId}`, data.userDetailsData);
      },
   });
};

export const UpdateUserAllUserDetailsByEmail = () => {
   return useMutation({
      mutationFn: async (data: { email: string; userDetailsData }) => {
         axios.defaults.withCredentials = true;
         await axios.put(`${host}${USER_DETAILS}/allDetails/userByEmail`, data);
      },
   });
};

export const UpdateFileSession = () => {
   return useMutation({
      mutationFn: async (dataN: { file; sessionId: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(`${host}/session/files/${dataN.sessionId}`, {
            file: dataN.file,
         });
         return data;
      },
   });
};

export const UptateStatusUserByIdPublic = (userId: string, sessionId: string) => {
   return useMutation({
      mutationFn: async (dataN: { status: string; token: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(`${host}/session/status/public/${userId}/${sessionId}`, {
            status: dataN.status,
            token: dataN.token,
         });
         return data;
      },
   });
};

export const UpdateStatusUserById = (userId: string, sessionId: string) => {
   return useMutation({
      mutationFn: async (dataN: { status: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(`${host}/session/status/${userId}/${sessionId}`, {
            status: dataN.status,
         });
         return data;
      },
   });
};

export const UptateSuggestUserByIdPublic = (userId: string, sessionId: string) => {
   return useMutation({
      mutationFn: async (dataN: { date: string; startTime: string; finishTime: string; token: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(`${host}/session/suggest/${userId}/${sessionId}`, {
            date: dataN.date,
            startTime: dataN.startTime,
            finishTime: dataN.finishTime,
            token: dataN.token,
         });
         return data;
      },
   });
};

export const ModifySession = (Resourceid: string, companySelected: string) => {
   return useMutation({
      mutationFn: async (dataN: { sessionData: ISession; sessionId: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(`${host}/session/${dataN.sessionId}`, dataN.sessionData, {
            headers: { r1: companySelected, r2: Resourceid },
         });
         return data;
      },
   });
};

export const UpdateUserAttendance = async (companyId, sessionId, userId) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}/session/attendance/${companyId}/${sessionId}/${userId}`);
      return data;
   } catch (err) {
      handleInvalidRequest(err);
   }
};

export const UpdateExternalUserName = async (sessionId, userId, name: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}/session/externalName/${sessionId}/${userId}`, { name });
      return data;
   } catch (err) {
      handleInvalidRequest(err);
   }
};

export const getTaskById = async (taskId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${TASK}/${taskId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateTask = async (taskId: string, task, resourceId: string, companySelected: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${TASK}/${taskId}`, task, {
         headers: { r1: companySelected, r2: resourceId },
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getManyComments = async (commentIds: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${COMMENT}/find-many`, commentIds);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createComment = async (comment) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${COMMENT}`, comment);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createCommentCommitment = async (comment) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${COMMITMENT}/comment`, comment);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createCommentNote = async (comment) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${NOTES}/comment`, comment);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const modifyRecord = async (id: string, values: any, recordId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${PROFESIONAL_DATA}/update/data/${id}/record/${recordId}`, values);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getCommitmentsByUserId = async (userId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${COMMITMENT}/user/${userId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getCommitmentsByUserIdAndCompanyId = async (companyId: string, userId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${COMMITMENT}/company/${companyId}/${userId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getCommitmentsByManyGovernancedAndUserId = async (governanceIds: string[], userId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${COMMITMENT}/governance/many/${userId}`, governanceIds);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getCommitmentsBySessionId = async (sessionId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${COMMITMENT}/session/${sessionId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getCommitmentsByGovernanceBody = async (governanceId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${COMMITMENT}/governance/${governanceId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getCommitmentsByGovernance = async (governanceId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/session/governance/${governanceId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getCommitmentsByUserIdAndGovernance = async (governanceId: string, userId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/session/governance/${governanceId}/${userId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const CreateCommitment = (companySelected, Resourceid) => {
   return useMutation({
      mutationFn: async (dataN: { users: string[]; title: string; dueDate: Date; session: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${COMMITMENT}`, dataN, {
            headers: { r1: companySelected, r2: Resourceid },
         });
         return data;
      },
   });
};

export const CreateMultipleCommitments = (companySelected, Resourceid) => {
   return useMutation({
      mutationFn: async (dataN: { users: string[]; title: string; dueDate: Date; session: string }[]) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${COMMITMENT}/multiple`, dataN, {
            headers: { r1: companySelected, r2: Resourceid },
         });
         return data;
      },
   });
};

export const UpdateCommitment = (companySelected, Resourceid, commitmentsId) => {
   return useMutation({
      mutationFn: async (dataN: { users: string[]; title: string; dueDate: Date; session: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(`${host}${COMMITMENT}/${commitmentsId}`, dataN, {
            headers: { r1: companySelected, r2: Resourceid },
         });
         return data;
      },
   });
};

export const getUserProfData = async (arrayIds: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${USER_DETAILS}/userProfData`, arrayIds);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getSelectedMemberData = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${USER_DETAILS}/selectedMember/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getConversationsByUserId = async (userId: string, companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${CONVERSATION}/user/${userId}/${companyId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getConversationsByCompanyId = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${CONVERSATION}/company/${companyId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createConversation = async (conversation) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${CONVERSATION}`, conversation);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getMessagesByConversationId = async (id: string, page: number) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${MESSAGE}/conversation/${id}?page=${page}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getAllMessagesByConversationId = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${MESSAGE}/conversation/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateUserReadMessageById = async (conversationId: string, userId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${MESSAGE}/applyseen/${conversationId}`, {
         userId: userId,
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getMessages = async () => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${MESSAGE}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getMessagesById = async (id) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${MESSAGE}/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createMessage = async (message) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${MESSAGE}`, message);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getConversationById = async (id) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${CONVERSATION}/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getNotifications = async (userId, conversationId) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${NOTIFICATION}/${userId}/${conversationId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const deleteNotifications = async (userId, conversationId) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.delete(`${host}${NOTIFICATION}/${userId}/${conversationId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createNotification = async (notification) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${NOTIFICATION}`, notification);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getNumberOfUnseenMessages = async (userId: string, conversation: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${MESSAGE}/unseenmessages/${userId}`, conversation);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getMessagesByContent = async (conversationId: string[], content: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${MESSAGE}/content`, {
         conversationId: conversationId,
         content: content,
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getMessagesUntilSearchedMessage = async (conversationId: string, messageId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${MESSAGE}/searchmessage/${conversationId}`, {
         messageId: messageId,
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getMessagesWithFiles = async (conversationId) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${MESSAGE}/withfiles/${conversationId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getMessagesWithLink = async (conversationId, page) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${MESSAGE}/withlink/${conversationId}?page=${page}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getChargeByGovernUsers = async (arrayIds: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${CHARGES}/members`, arrayIds);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const checkIfChargeHaveAccess = async (arrayIds: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${CHARGES}/accessCheck`, arrayIds);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getAllCharges = async () => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${CHARGES}/all`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const filterCharges = async (users: any[], chargesIds: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const body = { users, chargesIds };
      const data = await axios.post(`${host}${CHARGES}/filtered`, body);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createGovernBody = async (governingData: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${GOVERNING_BODY}`, governingData);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const addUserGovernBody = async (id: string, userData: any, companyId: string, resourceId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/addUser/${id}`, userData, {
         headers: { r1: companyId, r2: resourceId },
      });

      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const replaceUserOnGovernBody = async (bodyId: string, userData: any, oldUser: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/replaceUser/${bodyId}/${oldUser}`, userData);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const modifyGovernBodyUser = async (bodyId: string, userData: any, companyId: string, resourceId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/modifyUser/${bodyId}`, userData, {
         headers: { r1: companyId, r2: resourceId },
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const deleteUserGovernBody = async (governId: string, userId: string, companyId: string, resourceId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/deleteUser/${governId}/${userId}`, userId, {
         headers: { r1: companyId, r2: resourceId },
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getGovernTitlesByUser = async (id: string, entitiesIds: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/bodiesByMember/${id}`, { entitiesIds });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getGoberningBodiesByCompany = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${GOVERNING_BODY}/all/company/${companyId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getGoberningBodiesByCompanyResponses = async (companyId: string, evaluationId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${GOVERNING_BODY}/all/company/responses/${companyId}/${evaluationId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getGoberningBodiesByCompanyAndUser = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${GOVERNING_BODY}/all/company/${companyId}/byUser`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getStructureByGoverningBodyId = async (govId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${GOVERNING_BODY}/quorum/${govId}`);
      return data.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const assignCompanyDetailsToGoverningBodies = async (companyId, companyDetailsInfo) => {
   try {
      axios.defaults.withCredentials = true;
      const body = {
         businessName: companyDetailsInfo.businessName,
         primaryColor: companyDetailsInfo.primaryColor,
         secondaryColor: companyDetailsInfo.secondaryColor,
      };
      const data = await axios.put(`${host}${GOVERNING_BODY}/all/company/companyDetails/${companyId}`, body);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getEvaluationQuestions = async () => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/question`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getLastEvaluation = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/performance-evaluation/last/${companyId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getFirstEvaluation = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/performance-evaluation/first/${companyId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getUserEvaluationProgress = async (evaluationId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/user-evaluation-progress/${evaluationId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const modifyUserEvaluationProgress = async (
   evaluationId: string,
   lastSave: string,
   council: string,
   finished = false
) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}/user-evaluation-progress/modify/${evaluationId}`, {
         lastSave,
         council,
         finished,
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const AddEvaluationResponses = () => {
   return useMutation({
      mutationFn: async (dataN: { responses: any[] }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}/response/many`, dataN.responses);
         return data;
      },
   });
};

export const getAnswersByUser = async (evaluation: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/response/evaluation/${evaluation}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getAnswersOfUser = async (evaluation: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/response/evaluation/ofUser/${evaluation}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createNewAdvice = async (advice: any, Resourceid: string, companySelected: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${ADVICE}`, advice, {
         headers: { r1: companySelected, r2: Resourceid },
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
      throw new Error("Error creating advice");
   }
};

export const updateAdvice = async (adviceId: string, advice: any, Resourceid: string, companySelected: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${ADVICE}/${adviceId}`, advice, {
         headers: { r1: companySelected, r2: Resourceid },
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
      throw new Error("Error creating advice");
   }
};

export const deleteAdviceById = async (adviceId: string, Resourceid: string, companySelected: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.delete(`${host}${ADVICE}/${adviceId}`, {
         headers: { r1: companySelected, r2: Resourceid },
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
      throw new Error("Error creating advice");
   }
};

export const getAdviceByGoberningBodyId = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${ADVICE}/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
      throw new Error("Error advices not found");
   }
};

export const getChargeById = async (arrayIds: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${CHARGES}/${arrayIds}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getAffairsByCompany = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.get(`${host}${AFFAIR}/company/${companyId}`);
      return response;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getAffairsByIdsSession = async (ids: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.post(`${host}${AFFAIR}/byIds`, ids);
      return response.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const handleAffairs = async (affairList: AffairsInterface[]) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.put(`${host}${AFFAIR}`, affairList);
      return response.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const updateVotesById = async (id: string, voteData: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${VOTE}/${id}`, voteData);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getClassificationById = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${CLASSIFICATION}/${companyId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateClassificationData = async (id: string, classificationData: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${CLASSIFICATION}/${id}`, classificationData);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateSeriesByGovernBody = async (governId: string, seriesData: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/seriesUpdate/${governId}`, seriesData);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const CreateVotes = (isAffairVotation: boolean) => {
   return useMutation({
      mutationFn: async (voteData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${VOTE}/${isAffairVotation}`, voteData);
         return data;
      },
   });
};

export const getVotesBySession = async (sessionId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(`${host}${VOTE}/session/${sessionId}`);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const UpdateVotes = () => {
   return useMutation({
      mutationFn: async (voteData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(`${host}${VOTE}/${voteData.votationId}`, voteData.voteData);
         return data;
      },
   });
};

export const updateVotesVotation = async (voteData) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.put(`${host}${VOTE}/votation`, voteData);
   return data;
};

export const updateAffairVotation = async (sessionId: string, orderInfo: IAffairVotation) => {
   try {
      axios.defaults.withCredentials = true;
      const body = {
         ...orderInfo,
      };
      const { data } = await axios.put(`${host}${SESSION}/affairVotation/${sessionId}`, body);
      return data;
   } catch (err) {
      handleInvalidRequest(err);
   }
};

export const updateGovernBody = async (governId: string, governingData: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/${governId}`, governingData);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getAffairsByClassification = async (companyId: string, classification: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/affair/company/${companyId}/${classification}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateGovernBodyFile = async (governId: string, fileData: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/updateFile/${governId}`, fileData);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};
export const getAffairsByIds = async (ids: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}/affair/allById`, ids);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getMemberChargesByCompany = async (arrayIds: string[], companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${CHARGES}/members/${companyId}`, arrayIds);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getMemberChargesByManyCompanies = async (usersArray: string[], companiesArray: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${CHARGES}/members/companies`, { usersArray, companiesArray });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const addGovernBodyUser = async (entityId: string, userId: any, chargeId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/council/${entityId}/${userId}/${chargeId}`, {});
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getClassificationByIdAndCategoryName = async (companyId: string, title: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${CLASSIFICATION}/${companyId}/${title}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getGoverningBodyAdmin = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${GOVERNING_BODY}/admin/${companyId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createChartPdf = async (
   isShareHolder: boolean,
   selectedGovernance: string,
   monthsValue: {
      startDate?: Date | null;
      finishDate?: Date | null;
      monthValue?: Date | null;
   },
   groupDetails?: { groupName: string; primaryColor: string; secondaryColor: string }
) => {
   try {
      const body = {
         ...monthsValue,
         ...(groupDetails && { groupDetails }),
         ...(groupDetails && { groupDetails }),
      };
      axios.defaults.withCredentials = true;
      const { data } = isShareHolder
         ? await axios.put(`${host}${GOVERNING_BODY}/reports/shareHolder/${selectedGovernance}`, body)
         : await axios.put(`${host}${GOVERNING_BODY}/reports/governingBody/${selectedGovernance}`, body);
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};
export const createChartPdfTest = async (
   monthsValue: {
      startDate?: Date | null;
      finishDate?: Date | null;
      monthValue?: Date | null;
   },
   userId: string,
   selectedCompany: string
) => {
   try {
      // Solo enviar los parámetros necesarios
      const body = {
         userId,
         ...monthsValue,
         companyId: selectedCompany, // Cambiamos "selectedCompany" a "companyId" si el backend espera este nombre
      };

      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${GOVERNING_BODY}/reports/userPanel`, body);
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getGBCommitmentsByCompany = async (companyId: string, userId: string, privilegedUser: boolean) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${GOVERNING_BODY}/all/commitments/${companyId}/${userId}/${privilegedUser}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateUserCharges = async (userId, chargeInfo) => {
   try {
      const body = {
         ...chargeInfo,
      };
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/update/userCharge/${userId}`, body);
      return data.data;
   } catch (err) {
      handleInvalidRequest(err);
   }
};

export const verifyAndCreateEvaluationConfig = async (evaluationConfig: {
   company: string;
   manualActive?: boolean;
   automaticDate?: Date;
   automatic?: boolean;
   cycle?: string;
   period?: any[];
   startDate?: Date;
   endDate?: Date;
}) => {
   try {
      const body = {
         ...evaluationConfig,
      };
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}/evaluation-config`, body);
      return data.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const createConsultiveGroup = async (body: { _id: string; companies: string[] }) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${GROUP}`, body);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getEvaluationConfig = async (evaluationConfig: { company: string }) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/evaluation-config/${evaluationConfig.company}`);
      return data.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getConsultiveGroupById = async (groupId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${GROUP}/${groupId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getManyGovernanceByName = async (entitiesIds: string[], name: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/many/byName`, { entitiesIds, name });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getManyGovernanceByManyCompanies = async (companiesId: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/many/companies`, companiesId);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getAllEvaluationNumber = async (evaluationConfig: { company: string }) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/performance-evaluation/all/number/${evaluationConfig.company}`);
      return data.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getManualEvaluation = async (evaluationConfig: { company: string }) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/performance-evaluation/manual/${evaluationConfig.company}`);
      return data.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getLastCompletedEvaluation = async (evaluationConfig: { company: string }) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/performance-evaluation/lastCompleted/${evaluationConfig.company}`);
      return data.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getLastCurrentEvaluation = async (evaluationConfig: { company: string }) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/performance-evaluation/current/${evaluationConfig.company}`);
      return data.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const completeEvaluation = async (companyId) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}/performance-evaluation/complete/${companyId}`);
      return data.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getScoresByMember = async (memberId: string, lastEvaluationId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/response/score/${memberId}/${lastEvaluationId}`);
      return data.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getCommentariesByMember = async (memberId: string, lastEvaluationId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/response/commentary/member/${memberId}/${lastEvaluationId}`);
      return data.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getCommentariesByGov = async (govId: string, lastEvaluationId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/response/commentary/gov/${govId}/${lastEvaluationId}`);
      return data.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getProgressByUser = async (lastEvaluationId: string, users: any[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}/user-evaluation-progress/users-progress/${lastEvaluationId}`, {
         users,
      });
      return data.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};
export const getNumberOfEvaluations = async (companyId) => {
   try {
      axios.defaults.withCredentials = true;

      // Use the companyId passed to the function in the API request
      const data = await axios.get(`${host}/user-evaluation-progress/all/number/${companyId}`);

      return data.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getMultipleResponsesByIds = async (govId: string, lastEvaluationId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}/response/severalQuestions/gov/${govId}/${lastEvaluationId}`);
      return data.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const updateConsultiveGroup = async (groupId: string, companies: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GROUP}/${groupId}`, { companies });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getManyAssemblysByIds = async (companyIds: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/assembly/many`, companyIds);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getGoverningBodyNewCharges = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${GOVERNING_BODY}/all/charges/notAccepted/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const changeStatusChargeInGoverningBody = async (id: string, status: boolean) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/changeCharge/status/${id}`, { status });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const addDocumentChargeInGoverningBody = async (id: string, documentId: string, signerId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/putDocumentToSign/${id}/${documentId}/${signerId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const sendExternalUsersCommitmentsEmails = async (sessionId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${COMMITMENT}/external/sendMails`, { sessionId });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};
