import { Box, Typography } from "@mui/material";
import { SessionMemberToVerify } from "./SubComponents/SessionMemberToVerify";
import { useContext } from "react";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";

export const SessionVerifyMembers = () => {
   const { membersToVerify, membersWithCharge } = useContext(GovernanceSessionContext);
   return (
      <Box sx={{ height: "41%" }}>
         <Box sx={{ bgcolor: "#F8F8F9", border: 1, borderColor: "#E0E0E0", p: 1 }}>
            <Typography sx={{ fontWeight: 600 }}>Usuarios por verificar</Typography>
            <Typography sx={{ fontSize: 9, color: "#64748B" }}>
               Verifica las identificaciones de los usuarios para que se puedan unir a la sesión.
            </Typography>
         </Box>
         <Box
            sx={{
               bgcolor: "white",
               border: 0.65,
               borderTop: 0,
               borderColor: "#CBD5E0",
               height: "84%",
               overflowY: "auto",
            }}
         >
            {membersToVerify?.map((member: any) => {
               return (
                  <SessionMemberToVerify
                     key={`member-to-verify-${member.user.id}`}
                     member={{
                        memberCharge: member.memberCharge || `Usuario Externo - ${member.specialty}`,
                        firstName: member.user.firstName || member.name,
                        lastName: member.user.lastName || "",
                        userId: member.user.id || member.user,
                        file: member.file,
                        request: member.request,
                        external: member.external,
                        folder: member.folder,
                        fileName: member.fileName,
                        email: member.email,
                        profilePic: membersWithCharge.find((m) => m._id === member.user.id)?.profilePic,
                     }}
                  />
               );
            })}
         </Box>
      </Box>
   );
};
