import { Box, Button, Stack, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useContext, useEffect, useMemo, useState } from "react";
import { FilterTabsComponent } from "./SubComponents/FilterTabsComponent";
import { AssignedTaskComponent } from "./AssignedTaskComponent";
import { TaskModalComponent } from "./SubComponents/Modals/TaskModalComponent";
import { KanbanBoardComponent } from "./KanbanBoardComponent";
import {
   getCommitmentsByGovernanceBody,
   getCommitmentsByManyGovernancedAndUserId,
   getCommitmentsByUserIdAndCompanyId,
   getTagById,
   getTasksByUserId,
} from "../../../lib/gobCorpBEClient";
import { UserContext } from "../../../context/userContext";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import { TextFilterComponent } from "./SubComponents/TextFilterComponent";
import { getNameUserData, getUrlS3, getUserProfilePics } from "../../../lib/usersBEClient";
import { useParams } from "react-router-dom";
import { GovernanceTheSequelContext } from "../../../context/governanceContext/governanceTheSequelContext";

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

function CustomTabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         key={index}
         {...other}
      >
         {value === index && children}
      </div>
   );
}

const useGetTasksByUser = () => {
   const { refetchTasks, companySelected, verifyAndUpdateTags } = useContext(GovernanceContext);
   const { myGovBody } = useContext(GovernanceTheSequelContext);
   const { isLoadingUser, user } = useContext(UserContext);
   const [tasks, setTasks] = useState([]);
   const [commitments, setCommitments] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const { id: governanceId } = useParams();

   useEffect(() => {
      const fetch = async () => {
         try {
            let tasks = [];
            let commitments = [];
            if (governanceId) {
               commitments = await getCommitmentsByGovernanceBody(governanceId);
            } else {
               tasks = await getTasksByUserId(user.id, companySelected);
               //await verifyAndUpdateTags(tasks, "tasks");
               commitments = await getCommitmentsByManyGovernancedAndUserId(
                  myGovBody.map((g) => g.bodiesData._id),
                  user.id
               );
            }
            setCommitments(commitments);
            setTasks(tasks);
            setIsLoading(false);
         } catch (error) {
            console.log(error);
         }
      };
      if (!isLoadingUser && companySelected && user) fetch();
   }, [refetchTasks, isLoadingUser, companySelected, myGovBody]);

   return { tasks, commitments, isLoading };
};

const useGetFilteredTasks = (textFiltered, tasks) => {
   const filteredTasks = useMemo(() => {
      if (textFiltered === "") return tasks;
      const tasksFiltered = tasks.filter((task) => {
         if (task?.title)
            return (
               task?.title?.toLowerCase()?.includes(textFiltered.toLowerCase()) ||
               task?.description?.toLowerCase()?.includes(textFiltered.toLowerCase())
            );
      });
      return tasksFiltered;
   }, [textFiltered, tasks]);
   return { filteredTasks };
};

const useGetFilteredTasksByCommitment = (governanceSelected, allCommitments, alltasks) => {
   const { id: governanceId, companyId } = useParams();
   const { consultiveGroup, selectedGovernance } = useContext(GovernanceContext);
   const isConsultiveGroup =
      governanceId && consultiveGroup?._id === companyId && selectedGovernance?.title !== "Asamblea de accionistas";

   const filteredTaskValue = useMemo(() => {
      if (!isConsultiveGroup) {
         if (governanceSelected.title === "Mis actividades") return alltasks;
         let tasks = [];
         if (selectedGovernance?.title === "PANEL DE USUARIO") {
            tasks = allCommitments.filter((c) => {
               if (c.session) return c.session.governance._id.includes(governanceSelected?._id);
            });
         } else return allCommitments;
         return tasks;
      } else {
         const tasks = allCommitments.filter((c) => {
            if (c.session) return c.session.governance._id.includes(governanceId);
         });
         const companyFilteredTasks = tasks.filter((c) => c?.companies?.includes(governanceSelected?._id));
         return companyFilteredTasks;
      }
   }, [governanceSelected, allCommitments, alltasks]);

   return filteredTaskValue;
};

const useUserData = (tasks) => {
   const [processedData, setPorcessedUsers] = useState([]);
   const { companySelected, user } = useContext(UserContext);
   const [isLoadingUsers, setIsLoadingUsers] = useState(true);

   useEffect(() => {
      const fetchUserData = async () => {
         if (!tasks || tasks.length === 0) return setIsLoadingUsers(false);
         setIsLoadingUsers(true);
         const userList = [];
         for (const task of tasks) {
            userList.push(...task.users);
            for (const comment of task.comments) userList.push(comment.user);
            if (task.owner) userList.push(task.owner);
         }
         const uniqueUsers = userList.filter((value, index, self) => {
            return self.indexOf(value) === index;
         });
         if (!uniqueUsers.includes(user.id)) uniqueUsers.push(user.id);
         const userData = await getNameUserData(uniqueUsers.filter((str) => !str.includes("-")));
         const profilePicsResponse = await getUserProfilePics(uniqueUsers);
         const newUserList = [];
         for (const user of userData)
            newUserList.push({
               user: `${user.firstName} ${user.lastName}`,
               id: user._id,
               profilePicture: profilePicsResponse.find((u) => u.user === user._id)?.url,
            });
         setPorcessedUsers(newUserList);
         setIsLoadingUsers(false);
      };

      fetchUserData();
   }, [companySelected, tasks.length]);
   return { processedData, isLoadingUsers };
};

export const AgreementsAndCommitmentsComponent = () => {
   const [value, setValue] = useState(0);
   const [open, setOpen] = useState<boolean>(false);
   const [filterValue, setFilterValue] = useState("");
   const [filterText, setFilterText] = useState("");
   const { tasks, commitments } = useGetTasksByUser();
   const filteredTaskByGovernance = useGetFilteredTasksByCommitment(filterValue, commitments, tasks);
   const { filteredTasks } = useGetFilteredTasks(filterText, filteredTaskByGovernance);
   const { processedData: allUsers, isLoadingUsers } = useUserData([...tasks, ...commitments]);
   const { id: governanceId, companyId } = useParams();
   const { consultiveGroup, selectedGovernance } = useContext(GovernanceContext);

   const isConsultiveGroup =
      governanceId &&
      consultiveGroup?._id === companyId &&
      selectedGovernance?.title !== "Asamblea de accionistas" &&
      selectedGovernance?.company === consultiveGroup?._id;

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   return (
      <>
         <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
            <TextFilterComponent setFilterText={setFilterText} />
            <Tabs value={value} onChange={handleChange}>
               <Tab
                  label={
                     <Stack direction={"row"} sx={{ alignItems: "center", columnGap: 0.5, color: "#64748B" }}>
                        <FormatListBulletedIcon sx={{ fontSize: 15 }} />
                        <Typography>LISTA</Typography>
                     </Stack>
                  }
               />
               <Tab
                  label={
                     <Stack direction={"row"} sx={{ alignItems: "center", columnGap: 0.5, color: "#64748B" }}>
                        <ViewKanbanIcon sx={{ fontSize: 15 }} />
                        <Typography>TABLERO</Typography>
                     </Stack>
                  }
               />
            </Tabs>
            {!governanceId && (
               <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                     setOpen(true);
                  }}
                  sx={{ flex: 1 }}
               >
                  Agregar tarea
               </Button>
            )}
         </Box>
         <Box
            sx={{
               display: "flex",
               my: 2,
               columnGap: 2,
            }}
         >
            {(!governanceId || isConsultiveGroup) && <FilterTabsComponent setFilterValue={setFilterValue} />}
            <Box sx={{ flex: 1 }}>
               <CustomTabPanel value={value} index={0}>
                  <AssignedTaskComponent tasks={filteredTasks} allUsers={allUsers} isLoadingUsers={isLoadingUsers} />
               </CustomTabPanel>
               <CustomTabPanel value={value} index={1}>
                  <KanbanBoardComponent tasks={filteredTasks} allUsers={allUsers} isLoadingUsers={isLoadingUsers} />
               </CustomTabPanel>
            </Box>
         </Box>
         <TaskModalComponent open={open} setOpen={setOpen} />
      </>
   );
};
