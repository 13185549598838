import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

interface InputLoadingButtonProps {
   disabled?: boolean;
   label?: string;
   isLoading: boolean;
   icon?: React.ReactNode;
   onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
   fullWidth?: boolean;
   size?: string;
   type?: string;
   href?: string;
   download?: string;
   disableSaveIcon?: boolean;
   datacy?: string;
   sx?: any;
   children?: React.ReactElement[];
   variant?: "text" | "contained" | "outlined";
}

export const LoadingButton = (props: InputLoadingButtonProps) => {
   return (
      <Button
         data-cy={props.datacy}
         type="submit"
         variant={props.variant || "contained"}
         sx={{
            ...props.sx,
            bgcolor: props.sx?.bgcolor || "#142c44",
            mb: 2,
            // "&:hover": {
            //    boxShadow: props.sx?.hoverBgColor ? 0 : "inherit",
            //    bgcolor: props.sx?.hoverBgColor || "#142c4490",
            // },
         }}
         fullWidth={props.fullWidth}
         disabled={props.isLoading || props.disabled}
         onClick={props.onClick}
         style={{ textTransform: "none" }}
      >
         {props.isLoading ? (
            props.disableSaveIcon ? (
               <></>
            ) : (
               <SaveIcon />
            )
         ) : (
            props.children || (
               <>
                  {props.icon}
                  {props.label}
               </>
            )
         )}
         {props.isLoading && <CircularProgress size={24} />}
      </Button>
   );
};

export const LoadingDownloadButton = (props: InputLoadingButtonProps) => (
   <Button
      type="submit"
      variant="contained"
      sx={{
         bgcolor: "#142c44",
         mb: 2,
         "&:hover": {
            bgcolor: "#142c4490",
         },
      }}
      href={props.href}
      download={props.download}
      fullWidth={props.fullWidth}
      disabled={props.isLoading || props.disabled}
      style={{ textTransform: "none" }}
   >
      {props.isLoading ? (
         <SaveIcon />
      ) : (
         <>
            {props.icon}
            {props.label}
         </>
      )}
      {props.isLoading && <CircularProgress size={24} />}
   </Button>
);
