import { useState } from "react";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

interface SuggestionProps {
   suggestionList: string[];
   suggestion: string;
   onModify: () => void;
   onDelete: () => void;
   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
   onSave: () => void;
   hiddenButtons: Function;
   setHiddenButtonsAddCategorie: Function;
   index: number;
}

export const Suggestion = (props: SuggestionProps) => {
   const {
      onChange,
      onDelete,
      onModify,
      onSave,
      suggestion,
      hiddenButtons,
      setHiddenButtonsAddCategorie,
      index,
      suggestionList,
   } = props;
   const [error, setError] = useState<string>("");
   const [isEditing, setIsEditing] = useState<number>(null);

   const handleCheckClick = () => {
      if (suggestion.trim() === "") {
         setError("El campo no puede estar vacío");
         return false;
      }
      const splicedSuggestionList = suggestionList.filter((v, i) => i !== index);
      if (splicedSuggestionList.includes(suggestion)) {
         setError("La sugerencia ya existe");
         return false;
      }
      setIsEditing(null);
      return true;
   };

   return (
      <Box
         display={"flex"}
         justifyContent={"space-between"}
         flexWrap={"wrap"}
         sx={{
            borderBottom: 1,
            borderColor: "lightGray",
            py: 0.5,
            display: "flex",
            pointerEvents: isEditing !== null && isEditing !== index ? "none" : "auto",
            opacity: isEditing !== null && isEditing !== index ? 0.5 : 1,
         }}
      >
         {isEditing !== null && isEditing === index ? (
            <TextField
               value={suggestion}
               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(e);
                  if (e.target.value.trim() !== "") {
                     setError("");
                  }
               }}
               autoFocus
               size="small"
               sx={{ pl: 3 }}
               error={!!error}
               helperText={error}
               inputProps={{ maxLength: 200 }}
               onClick={onModify}
            />
         ) : (
            <Typography
               variant="h6"
               sx={{
                  pl: 3,
                  pr: 1,
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  wordBreak: "break-word",
                  whiteSpace: "pre-wrap",
                  textAlign: "left",
               }}
               align="center"
               maxWidth={"400px"}
            >
               {suggestion}
            </Typography>
         )}
         <Box>
            {isEditing !== null && isEditing === index ? (
               <IconButton
                  onClick={() => {
                     const validate = handleCheckClick();
                     if (!validate) return;
                     onSave();
                     setHiddenButtonsAddCategorie(false);
                     hiddenButtons(false);
                  }}
                  sx={{ mt: 0.5, mr: 8.4 }}
               >
                  <CheckIcon sx={{ color: "#162c44", fontSize: 25, stroke: "#162c44", strokeWidth: 2 }} />
               </IconButton>
            ) : (
               <IconButton
                  sx={{ mt: 0.5, mr: 8.4 }}
                  onClick={() => {
                     setIsEditing(index);
                     onModify();
                     setHiddenButtonsAddCategorie(true);
                     if (isEditing === null) hiddenButtons(true);
                  }}
               >
                  <BorderColorIcon sx={{ color: "#162c44" }} />
               </IconButton>
            )}
            <IconButton
               sx={{ mt: 0.5, mr: 3.2 }}
               onClick={() => {
                  onDelete();
                  hiddenButtons(false);
                  handleCheckClick();
                  isEditing !== null && setIsEditing(null);
                  setHiddenButtonsAddCategorie(false);
               }}
            >
               <DeleteForeverIcon sx={{ color: "#162c44" }} />
            </IconButton>
         </Box>
      </Box>
   );
};
