import { Formik, useFormikContext } from "formik";
import React, { useContext, useEffect } from "react";
import { Form } from "react-router-dom";
import { ShareholderCommitteeExternal } from "./Assembly/ShareholderCommitteeExternal";
import { ShareholderDeliberationsExternal } from "./Assembly/ShareholderDeliberationsExternal";
import { ShareholderResolutionsExternal } from "./Assembly/ShareholderResolutionsExternal";
import { ShareholderUrlRecordingExternal } from "./Assembly/ShareholderUrlRecordingExternal";
import { BillCommentExternal } from "./Bill/BillCommentExternal";
import { BillCommitmentsExternal } from "./Bill/BillCommitmentsExternal";
import { BillNotesExternal } from "./Bill/BillNotesExternal";
import { BillSignsExternal } from "./Bill/BillSignsExternal";
import { BillOtherSubjectsExternal } from "./Bill/Subcomponents/BillOtherSubjectsExternal";
import { BillHeaderExternal } from "./BillHeaderExternal";
import { ExternalGovernanceSessionContext } from "../../../context/governanceContext/externalSessionContext";
import _ from "lodash";
import { BillCommitteeExternal } from "./Bill/BillCommitteeExternal";
import { BillOrderDayExternal } from "./Bill/BillOrderDayExternal";
import { Box, Stack } from "@mui/material";
import { BillGroupNotesExternal } from "./Bill/BillGroupNotesExternal";
import { BillGroupCommentExternal } from "./Bill/BillGroupCommentExternal";

const GetBillExternalData = () => {
   const { socket, valuesFromBill, setValuesFromBill, setSignArray } = useContext(ExternalGovernanceSessionContext);

   const FormObserver: React.FC = () => {
      const { values, setFieldValue, setValues } = useFormikContext();

      useEffect(() => {
         const handler = async ({ values: valuesFromSocket }) => {
            const valuesFromSocketKeys = Object.keys(valuesFromSocket);
            valuesFromSocketKeys.forEach((key) => {
               if (Array.isArray(values[key]) && Array.isArray(valuesFromSocket[key])) {
                  if (values[key].length !== valuesFromSocket[key].length) {
                     setFieldValue(key, valuesFromSocket[key]);
                  }
               } else if (valuesFromSocket[key] !== values[key] || values[key] === "") {
                  if (!key.includes("sign")) setFieldValue(key, valuesFromSocket[key]);
               }
            });
            if (!_.isEqual(valuesFromSocket, values)) {
               const valuesChanged = _.reduce(
                  valuesFromSocket,
                  function (result, value, key) {
                     return _.isEqual(value, values[key]) ? result : result.concat(key);
                  },
                  []
               );
               if (valuesChanged[0]?.includes("officialID")) setFieldValue(valuesChanged[0], true);
               else {
                  setValuesFromBill(valuesFromSocket);
                  setValues(valuesFromSocket);
               }
            }
         };

         const handlerSignForm = (valuesFromSocket) => {
            setSignArray(valuesFromSocket.signArray);
         };

         socket.on("receive-changes", handler);
         socket.on("before-verify-sign", handlerSignForm);
         return () => {
            socket.off("receive-changes", handler);
            socket.off("before-verify-sign", handlerSignForm);
         };
      }, [socket, values]);

      useEffect(() => {
         setValues(valuesFromBill);
      }, []);

      return null;
   };
   return { FormObserver };
};

export const ExternalBill = () => {
   const { initialValues, session } = useContext(ExternalGovernanceSessionContext);
   const { FormObserver } = GetBillExternalData();

   return (
      <Box
         sx={{
            border: 1,
            borderColor: "#F0f0f0",
            bgcolor: "white",
            height: { xs: "inherit", lg: 880 },
            overflowY: session.completed === false ? "hidden" : "auto",
         }}
      >
         <Formik initialValues={initialValues} onSubmit={() => {}}>
            <Form>
               <Stack
                  sx={{
                     py: 2,
                     px: { xs: 2, lg: 4 },
                     gap: 2,
                     overflowY: session.completed === false ? "hidden" : "auto",
                  }}
               >
                  <FormObserver />
                  <BillHeaderExternal />
                  {session.assembly ? (
                     <>
                        <ShareholderCommitteeExternal />
                        <BillOrderDayExternal />
                        <ShareholderDeliberationsExternal />
                        <ShareholderResolutionsExternal />
                        <BillCommitmentsExternal />
                        <BillSignsExternal />
                        <BillOtherSubjectsExternal />
                        <BillNotesExternal />
                        <BillCommentExternal />
                        <ShareholderUrlRecordingExternal />
                     </>
                  ) : (
                     <>
                        <BillCommitteeExternal />
                        <BillOrderDayExternal />
                        <ShareholderDeliberationsExternal />
                        {!session.group && <ShareholderResolutionsExternal />}
                        <BillCommitmentsExternal />
                        <BillSignsExternal />
                        <BillOtherSubjectsExternal />
                        {session.group ? <BillGroupNotesExternal /> : <BillNotesExternal />}
                        {session.group ? <BillGroupCommentExternal /> : <BillCommentExternal />}
                        <ShareholderUrlRecordingExternal />
                     </>
                  )}
               </Stack>
            </Form>
         </Formik>
      </Box>
   );
};
