import { Box, Typography } from "@mui/material";
import { GovernanceContext } from "../../context/governanceContext/governanceContext";
import { useContext, useState, useEffect } from "react";
import { subMenuMyAccountOptions, generalOptions } from "../../utils/Gobierno corporativo/subMenuOptions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IGovernanceBody } from "../../types/governance.types";
import { MenuComponent } from "./MenuComponent";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { UserContext } from "../../context/userContext";
import { menuOptionsMap } from "../../hooks/gob-corp/governmentBody/useCreateResourcesMap";
import { GovernanceTheSequelContext } from "../../context/governanceContext/governanceTheSequelContext";

interface ISubMenu {
   index: number;
   data: IGovernanceBody;
   myGovBodyInfo: any;
}

export interface ISubMenuOptions {
   label: string;
   path: string;
   action?: Function;
}

const useGetMenuOptions = (myGBInfo, index) => {
   const { user, setSelectedResources } = useContext(UserContext);
   const [newOptions, setNewOptions] = useState([]);

   useEffect(() => {
      const subMenuProcess = () => {
         const userResourceRelation = [];
         let resourceList = [];
         if (
            user.role.includes("Usuario de implementación") ||
            user.role.includes("Coordinador de gobierno corporativo") ||
            user.role.includes("Administrador")
         ) {
            resourceList = [
               {
                  name: "Calendar",
                  permission: 4,
                  _id: "656f7b3fbff460f702e21976",
               },
               {
                  name: "Board",
                  permission: 4,
                  _id: "656f7c0fbff460f702e2197b",
               },
               {
                  name: "Notes",
                  permission: 4,
                  _id: "656f7b53bff460f702e21977",
               },
               {
                  name: "Files",
                  permission: 4,
                  _id: "656f7b78bff460f702e21978",
               },
               {
                  name: "Home",
                  permission: 4,
                  _id: "656f7ab9bff460f702e21974",
               },
               {
                  name: "Profile",
                  permission: 4,
                  _id: "656f7b2cbff460f702e21975",
               },
               {
                  name: "Comunication",
                  permission: 4,
                  _id: "656f7ba6bff460f702e21979",
               },
               {
                  name: "Notifications",
                  permission: 4,
                  _id: "656f7bdcbff460f702e2197a",
               },
               {
                  name: "Reports",
                  permission: 4,
                  _id: "65bd1264ec00d1e3dc3e2e81",
               },
               // {
               //    name: "Tablero de avisos",
               //    permission: 4,
               //    _id: "659c76dc4fdb3761c2ea3ad1",
               // },
               {
                  name: "Gestion documental",
                  permission: 4,
                  _id: "656f7c36bff460f702e2197c",
               },
            ];
         } else {
            if (index > 0) {
               for (const resource of user.resources) {
                  if (resource.charges.length === 0) continue;
                  if (resource.charges.includes(myGBInfo.userData.charge)) {
                     const exists = userResourceRelation.some((userResource) => userResource._id === resource._id);
                     if (!exists) userResourceRelation.push(resource);
                  }
               }
            } else {
               for (const resource of user.resources) {
                  const exists = userResourceRelation.some((userResource) => userResource._id === resource._id);
                  if (!exists) userResourceRelation.push(resource);
               }
            }
            resourceList =
               userResourceRelation.length > 0 &&
               userResourceRelation.filter((resource) =>
                  index > 0 ? resource.charges.includes(myGBInfo.userData.charge) : resource
               );
         }
         const resourceMap = menuOptionsMap(resourceList);

         const newOptions = [];
         const options = index === 0 ? subMenuMyAccountOptions : generalOptions;
         for (const iterator of options) {
            const validation = resourceMap.get(iterator.label);
            if (validation) {
               const newDate = {
                  ...iterator,
                  res: validation,
                  action: () => setSelectedResources(validation),
               };
               newOptions.push(newDate);
            }
         }
         setNewOptions(newOptions);
      };
      if (user.resources && user.resources.length > 0) subMenuProcess();
   }, [user]);

   return newOptions;
};

const getNameFromRoute = (name: string) => {
   let sectionString = "";
   switch (name) {
      case "calendario":
         sectionString = "Calendario";
         break;
      case "notas":
         sectionString = "Mis notas";
         break;
      case "archivos":
         sectionString = "Mis archivos";
         break;
      case "acuerdos":
         sectionString = "Acuerdos y compromisos";
         break;
      case "reportes":
         sectionString = "Reportes";
         break;
      // case "tablero":
      //    sectionString = "Tablero de avisos";
      //    break;
      case "documentos":
         sectionString = "Gestión documental";
         break;
      case "miembros":
         sectionString = "Miembros";
         break;
      case "chat":
         sectionString = "Chat";
         break;
      case "perfil":
         sectionString = "Mi perfil";
         break;

      default:
         sectionString = "Dashboard";
         break;
   }
   return sectionString;
};

export const SubMenu = (props: ISubMenu) => {
   const {
      setSelectedGovernance,
      selectedGovernance,
      companySelected,
      setSelectedSection,
      consultiveGroup,
      setOpenGovernanceSelection,
   } = useContext(GovernanceContext);
   const { companySelected: companyContext, getCompanyDetails, setCompanySelected } = useContext(UserContext);
   const { myGovBody } = useContext(GovernanceTheSequelContext);
   const newOptions = useGetMenuOptions(props.myGovBodyInfo, props.index);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const navigate = useNavigate();
   const location = useLocation();
   const open = Boolean(anchorEl);
   const { id } = useParams();

   useEffect(() => {
      //on refresh
      if (id && id === props.data._id) setSelectedGovernance(props.data);
      //eslint-disable-next-line
   }, [id]);

   const handleSelectGovernance = (event: React.MouseEvent<HTMLDivElement>) => {
      if (selectedGovernance._id === props.data._id) setAnchorEl(event.currentTarget);
      //Selector de gobierno en GRUPO
      else if (
         myGovBody.reduce((acc, item) => (item.bodiesData.title === props.data.title ? acc + 1 : acc), 0) > 1 && //Verificar si existen duplicados del mismo organo por nombre
         consultiveGroup &&
         companySelected === consultiveGroup._id
      ) {
         return setOpenGovernanceSelection({ open: true, title: props.data.title });
      } else {
         if (consultiveGroup && companySelected === consultiveGroup._id && companyContext?._id !== props.data.company) {
            if (props.data.company && props.data.company !== consultiveGroup._id) getCompanyDetails(props.data.company);
            else setCompanySelected(null);
         }
         setSelectedGovernance(props.data);
         setSelectedSection("Dashboard");
         navigate(
            `${
               props.data._id === "0"
                  ? `${companySelected}/mi-cuenta/dashboard`
                  : `${companySelected}/${props.data._id}/dashboard`
            }`
         );
      }
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   useEffect(() => {
      const validation = location.pathname.split("/").pop();
      if (validation !== "evaluation") {
         setSelectedSection(getNameFromRoute(location.pathname.split("/")[4]));
      }
      //eslint-disable-next-line
   }, []);

   const handleChangeSection = (section: string, myaccount: boolean, id?: string) => {
      setSelectedGovernance(props.data);
      setSelectedSection(getNameFromRoute(section));
      navigate(
         myaccount
            ? `${companySelected}/mi-cuenta/${section}`
            : id
            ? `${companySelected}/${id}/${section}`
            : `${companySelected}/${props.data._id}/${section}`
      );
   };

   const isSelected = selectedGovernance && selectedGovernance._id === props.data._id;
   return (
      <>
         <Box
            sx={[
               isSelected ? highlighted : normalState,
               { display: "flex", alignItems: "center", justifyContent: "center" },
            ]}
            onClick={handleSelectGovernance}
         >
            <Typography fontWeight={600} fontSize={14} sx={{ py: 1 }} noWrap>
               {props.data?.title.toUpperCase()}
            </Typography>
            {isSelected &&
               (open ? <ArrowDropUp sx={{ color: "#486685" }} /> : <ArrowDropDown sx={{ color: "#486685" }} />)}
         </Box>
         <MenuComponent
            anchorEl={anchorEl}
            open={open}
            menuOptions={props.data.subComites ? props.data.subComites : newOptions}
            onClick={handleChangeSection}
            handleClose={handleClose}
            index={props.index}
            dual={!!props.data.subComites}
            secondOptions={newOptions}
         />
      </>
   );
};

export const highlighted = {
   color: "#486685",
   cursor: "pointer",
   borderBottom: 2.5,
   borderColor: "#486685",
};
export const normalState = {
   color: "#868FA0",
   "&:hover": {
      cursor: "pointer",
      color: "#486685",
   },
};
