import "react-quill/dist/quill.snow.css";
import { Delete, PeopleAltOutlined, Sell } from "@mui/icons-material";
import { Box, Button, CircularProgress, Skeleton, Typography } from "@mui/material";
import { Formik, Form, FormikProps } from "formik";
import { InputTextField } from "../../Inputs/InputTextField";
import { TagsSelector } from "../Inputs/TagsSelector";
import { useContext, useEffect, useState } from "react";
import TextEditor from "../../Inputs/TextEditor/TextEditor";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import StringToHTMLOutput from "../../Inputs/TextEditor/StringToHTMLOutput";
import { SnackBarContext } from "../../../context/snackBarContext";
import {
   createCommentNote,
   createNote,
   deleteNote,
   getTagsByUser,
   updateNoteById,
   useGetResourceSelected,
} from "../../../lib/gobCorpBEClient";
import { noteSchema } from "../../../lib/validations/inputSchemas";
import { ConfirmationModal } from "../../ConfirmationModal";
import { UserSearchComponent } from "./UserSearchComponent";
import { IGovernanceBody } from "../../../types/governance.types";
import { InputCheckBox } from "../../Inputs/InputCheckBox";
import { CommentsComponent } from "../AgreementsAndCommitmentsScreen/SubComponents/Modals/Components/CommentsComponent";
import { UserContext } from "../../../context/userContext";
import { removeHtmlTags } from "../../../const/globalConst";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";

interface NoteModalProps {
   state: number;
   setState: Function;
   data?: any;
   session?: any;
   setSeed?: Function;
   users: any[];
   commentsCallbackFunction?: Function;
}

export const useFetchTags = () => {
   const { userDetails, isLoading: isLoadingDetails, notesTags } = useContext(GovernanceContext);
   const [tags, setTags] = useState(isLoadingDetails ? [] : userDetails.tags);
   const [isLoading, setIsLoading] = useState(true);
   useEffect(() => {
      const fetchTags = async () => {
         setIsLoading(true);
         const tagsData = await getTagsByUser(userDetails._id);
         setTags(tagsData);
         setIsLoading(false);
      };
      if (userDetails) fetchTags();
   }, [userDetails, notesTags]);
   return { tags, isLoading };
};

const useFilterGovernBodies = (gobernanceBody) => {
   const [bodies, setBodies] = useState<IGovernanceBody[]>();
   const [loader, setLoader] = useState(true);
   const { user, isImplementationUser } = useContext(UserContext);

   useEffect(() => {
      const filterBodies = async () => {
         const array = gobernanceBody.filter((e) => e.title !== "PANEL DE USUARIO");
         if (isImplementationUser()) setBodies(array);
         else {
            const userBodies = array.filter((body) => body.users.some((u) => u.user === user.id));
            setBodies(userBodies);
         }
         setLoader(false);
      };
      filterBodies();
   }, [gobernanceBody]);

   return { bodies, loader };
};

export const NoteModal = (props: NoteModalProps) => {
   const resourceId = useGetResourceSelected();
   const { userDetails, gobernanceBody, getUserDetailsData, companySelected, refetch, setRefetch } =
      useContext(GovernanceContext);
   const { user } = useContext(UserContext);
   const { bodies, loader } = useFilterGovernBodies(gobernanceBody);
   const [modify, setModify] = useState(false);
   const [isLoadingDelete, setIsLoadingDelete] = useState(false);
   const { tags } = useFetchTags();
   const [text, setText] = useState(props.data ? (modify ? props.data.description : "") : "");
   const [formloading, setFormloading] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const [comments, setComments] = useState(props.data?.comments || []);
   const { socket } = useContext(GovernanceSessionContext);

   const handleAddCommentToNote = (values) => {
      setComments(values.comments);
   };

   useEffect(() => {
      socket.on("add-comment-to-note", handleAddCommentToNote);

      return () => {
         socket.off("add-comment-to-note", handleAddCommentToNote);
      };
   }, [socket]);

   const handleSubmitComment = async (comment) => {
      let Obj: any = {};
      if (comment === "") return;
      try {
         Obj = {
            commentBody: { user: user.id, userName: `${user.firstName} ${user.lastName}`, content: comment },
            noteId: props.data._id,
         };
         await createCommentNote(Obj);
         setRefetch(!refetch);
         if (props.session) props.commentsCallbackFunction?.({ comments: [...comments, Obj.commentBody] });
      } catch (error) {
         console.log(error);
      }
   };

   const handleSubmit = async (values) => {
      try {
         if (!removeHtmlTags(values.description)) return showSnackBar("Favor de agregar descripción", true);
         setFormloading(true);
         if (modify) {
            const noteId = props.data._id;
            await updateNoteById(
               noteId,
               {
                  title: values.title,
                  description: values.description,
                  tags: values.tags,
                  users: values.users,
                  owner: values.owner,
                  company: props.session ? props.session.company : companySelected,
                  allowedComments: values.allowedComments,
               },
               resourceId,
               props.session ? props.session.company : companySelected
            );
            if (props.session) props.setSeed((s) => s + 1);
            props.setState(0);
            getUserDetailsData();
            setRefetch(!refetch);
            showSnackBar("Nota actualizada correctamente", false);
         } else {
            if (props.session) {
               await createNote(
                  {
                     title: values.title,
                     description: values.description,
                     tags: values.tags,
                     users: values.users,
                     owner: user.id,
                     company: props.session.company,
                     session: props.session._id,
                     sharedInSession: values.shareWithSession,
                     allowedComments: values.allowedComments,
                  },
                  resourceId,
                  props.session.company
               );
               props.setSeed((s) => s + 1);
            } else {
               await createNote(
                  {
                     title: values.title,
                     description: values.description,
                     tags: values.tags,
                     users: values.users,
                     owner: values.owner,
                     company: companySelected,
                     allowedComments: values.allowedComments,
                  },
                  resourceId,
                  companySelected
               );
            }
            props.setState(0);
            getUserDetailsData();
            setRefetch(!refetch);
            showSnackBar("Nota creada correctamente", false);
         }
         setFormloading(false);
      } catch (error) {
         console.log(error);
         showSnackBar("Error al crear/actualizar la nota", true);
      }
   };

   const deleteNoteConfirm = async () => {
      try {
         const noteId = props.data._id;
         await deleteNote(noteId, resourceId, companySelected);
         props.setState(0);
         getUserDetailsData();
         showSnackBar("Nota eliminada correctamente", false);
      } catch (error) {
         console.log(error);
         showSnackBar("Error al eliminar la nota", true);
      }
   };

   return (
      <>
         {props.state === 1 ? (
            <>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     width: 650,
                     p: 4,
                     maxHeight: 500,
                     overflowY: "auto",
                     rowGap: 2,
                  }}
               >
                  <Typography variant="body2" fontWeight={600}>
                     {props.data.title}
                  </Typography>
                  <Typography variant="body2" fontWeight={600}>
                     Descripción
                  </Typography>
                  <Box>
                     <StringToHTMLOutput
                        text={props.data.description}
                        sx={{
                           p: 0,
                           fontSize: 14,
                           textAlign: "justify",
                        }}
                     />
                  </Box>
                  <Box sx={{ display: "flex" }}>
                     <Sell sx={{ fontSize: 15, mr: 1, mt: 1.5 }} />
                     {props.data.tags.length > 0 ? (
                        props.data.tags.map((e, i) => (
                           <Typography key={i} m={1} fontSize={"14px"} color={e.color}>
                              {e.title}
                           </Typography>
                        ))
                     ) : (
                        <Typography m={1} fontSize={"14px"}>
                           No tiene etiquetas
                        </Typography>
                     )}
                  </Box>
                  {props.data?.owner === user.id ? (
                     <>
                        {props.users && props.users.length > 0 ? (
                           <>
                              <Typography variant="body2" fontWeight={600}>
                                 Compartida
                              </Typography>
                              <Box
                                 sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: 2,
                                    flexWrap: "wrap",
                                    rowGap: 1,
                                 }}
                              >
                                 <PeopleAltOutlined sx={{ fontSize: 15 }} />
                                 {props.users
                                    .filter((u) => props.data?.users.includes(u.id) || props.data?.owner === u.id)
                                    .map((e, i) => (
                                       <Typography key={i} fontSize={"14px"}>
                                          {e.user}
                                       </Typography>
                                    ))}
                              </Box>
                           </>
                        ) : props.session ? (
                           <Typography variant="body2" fontWeight={600}>
                              Compartida en sesión
                           </Typography>
                        ) : (
                           <Typography variant="body2" fontWeight={600}>
                              Sin compartir
                           </Typography>
                        )}
                     </>
                  ) : (
                     <>
                        <Typography variant="body2" fontWeight={600}>
                           Compartida por
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                           <PeopleAltOutlined sx={{ fontSize: 15 }} />
                           <Typography fontSize={"14px"}>
                              {(props.users && props.users.find((u) => props.data?.owner === u.id)?.user) ??
                                 props.data?.ownerName}
                           </Typography>
                        </Box>
                     </>
                  )}

                  <Formik
                     initialValues={{
                        comment: "",
                     }}
                     onSubmit={handleSubmitComment}
                  >
                     <Form>
                        {props.data.allowedComments && (
                           <CommentsComponent
                              users={props.users}
                              comment={comments}
                              submitComment={handleSubmitComment}
                           />
                        )}
                     </Form>
                  </Formik>
               </Box>
               {props.data?.owner === user.id && (
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        bgcolor: "#F3F3F3",
                        p: 2,
                        borderBottomRightRadius: 5,
                        borderBottomLeftRadius: 5,
                     }}
                  >
                     <Button
                        onClick={() => {
                           setModify(true);
                           props.setState(2);
                           setText(props.data.description);
                        }}
                        sx={{ maxWidth: "200", height: 30 }}
                     >
                        Modificar información
                     </Button>
                  </Box>
               )}
            </>
         ) : (
            <Formik
               initialValues={{
                  title: props.data ? (modify ? props.data.title : "") : "",
                  description: text,
                  tags: props.data ? (modify ? props.data.tags : []) : [],
                  users: props.data ? (modify ? props.data.users : []) : [],
                  owner: props.data ? props.data.owner : user.id,
                  allowedComments: props.data ? (modify ? props.data.allowedComments : false) : false,
                  shareWithSession: false,
               }}
               onSubmit={handleSubmit}
               validationSchema={noteSchema}
            >
               {(formProps: FormikProps<any>) => (
                  <Form>
                     <Box sx={{ width: 650, p: 4, maxHeight: 500, overflowY: "scroll" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2, width: "100%" }}>
                           <InputTextField fullWidth id="title" name="title" type="text" placeholder="Agregar titulo" />
                           <Typography variant="body2" fontWeight={600}>
                              Descripción
                           </Typography>
                           <TextEditor
                              text={text}
                              setText={(e) => {
                                 setText(e);
                                 formProps.setFieldValue("description", e);
                              }}
                           />
                           <Typography variant="body2" fontWeight={600}>
                              Asignar etiqueta
                           </Typography>
                           <TagsSelector
                              tags={tags}
                              valuesTags={formProps.values.tags}
                              setFieldValue={(e) => {
                                 const newValues = formProps.values.tags;
                                 if (newValues.filter((tag) => tag._id === e._id).length > 0) return;
                                 newValues.push(e);
                                 formProps.setFieldValue("tags", newValues);
                              }}
                              deleteValue={(e) => {
                                 const newValues = formProps.values.tags.filter((tag) => tag._id !== e);
                                 formProps.setFieldValue("tags", newValues);
                              }}
                           />
                           {loader ? (
                              <Skeleton variant="rectangular" />
                           ) : (
                              <UserSearchComponent
                                 governBodies={bodies}
                                 label={"Compartir nota"}
                                 selectedUsers={formProps.values.users}
                                 setSelectedUser={(users) => {
                                    const filterT = users.map((user) => user._id);
                                    formProps.setFieldValue("users", filterT);
                                 }}
                              />
                           )}
                           <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                              <InputCheckBox
                                 id="allowedComments"
                                 label="Permitir comentarios"
                                 name="allowedComments"
                                 size="medium"
                              />
                              {props.session && (
                                 <InputCheckBox
                                    id="shareWithSession"
                                    label="Compartir con toda la sesión"
                                    name="shareWithSession"
                                    size="medium"
                                 />
                              )}
                           </Box>
                        </Box>
                     </Box>
                     {modify && props.data.owner === userDetails._id ? (
                        <>
                           <Box
                              sx={{
                                 display: "flex",
                                 justifyContent: "space-between",
                                 bgcolor: "#F3F3F3",
                                 p: 2,
                                 borderBottomRightRadius: 5,
                                 borderBottomLeftRadius: 5,
                              }}
                           >
                              <Box>
                                 <Button
                                    sx={{ height: 30 }}
                                    onClick={() => {
                                       setIsLoadingDelete(true);
                                    }}
                                 >
                                    <Delete sx={{ fontSize: 20 }} />
                                    Eliminar
                                 </Button>
                              </Box>
                              <Box>
                                 <Button
                                    onClick={() => {
                                       props.setState(0);
                                    }}
                                    sx={{ color: "grey", height: 30 }}
                                 >
                                    Cancelar
                                 </Button>
                                 <Button type="submit" sx={{ height: 30 }}>
                                    {!formloading ? "Guardar" : <CircularProgress size={24} color="success" />}
                                 </Button>
                              </Box>
                           </Box>
                        </>
                     ) : (
                        <>
                           <Box
                              sx={{
                                 display: "flex",
                                 justifyContent: "flex-end",
                                 bgcolor: "#F3F3F3",
                                 p: 2,
                                 borderBottomRightRadius: 5,
                                 borderBottomLeftRadius: 5,
                              }}
                           >
                              <Button
                                 onClick={() => {
                                    props.setState(0);
                                 }}
                                 sx={{ color: "grey", height: 30 }}
                              >
                                 Cancelar
                              </Button>
                              <Button type="submit" sx={{ height: 30 }}>
                                 {!formloading ? "Guardar" : <CircularProgress size={24} color="success" />}
                              </Button>
                           </Box>
                        </>
                     )}
                  </Form>
               )}
            </Formik>
         )}
         <ConfirmationModal
            open={isLoadingDelete}
            setOpen={setIsLoadingDelete}
            title="Eliminar"
            body={<Typography>¿Está seguro que desea eliminar la nota?</Typography>}
            nota={<Typography>Una vez confirmado, la información no podrá ser recuperada.</Typography>}
            onConfirm={() => {
               deleteNoteConfirm();
            }}
            cancelButton={false}
         />
      </>
   );
};
