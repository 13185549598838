import { Box, Grid, IconButton, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DescriptionIcon from "@mui/icons-material/Description";
import { ModalAddFilesComponent } from "./ModalAddFilesComponent";
import { useContext, useState } from "react";
import { formatFileSize, getDateFromISODate } from "../../../../const/globalConst";
import { getUrlS3 } from "../../../../lib/usersBEClient";
import { UserContext } from "../../../../context/userContext";

type File = {
   name: string;
   size: string;
   createdAt: string;
};

interface FilesInformationComponentProps {
   file: File | null;
   fileName: string;
   fileNameToUpload: string;
   id: string;
   setSeed: Function;
   notDisplaySize?: boolean;
   isVerfied?: boolean;
   userId?: string;
}

export const FilesInformationComponent = (props: FilesInformationComponentProps) => {
   const [open, setOpen] = useState<boolean>(false);
   const { user, isCompanyAdmin } = useContext(UserContext);

   const getFile = async () => {
      const fileData1: string = await getUrlS3(
         "files-lecosy",
         { folder: `gc/users/${props.userId ? props.userId : user.id}/files` },
         props.file.name
      );
      window.open(fileData1, "_blank", "noopener,noreferrer");
   };

   return (
      <>
         <Box
            rowGap={2}
            sx={{
               display: "flex",
               alignItems: "center",
               flexDirection: "row",
               border: 0.5,
               borderRadius: 2,
               borderColor: "#E0E0E0",
               p: 0.5,
               flex: 4,
            }}
         >
            {props.file ? (
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "space-between",
                     width: "100%",
                     px: 1,
                  }}
               >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                     <IconButton onClick={getFile}>
                        <DescriptionIcon />
                     </IconButton>
                     <Typography fontSize={12}>{props.fileName}</Typography>
                  </Box>
                  {!props.notDisplaySize && (
                     <Typography fontSize={12}>{formatFileSize(Number(props.file.size))}</Typography>
                  )}
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                     {props.notDisplaySize ? (
                        (!props.isVerfied || isCompanyAdmin) && (
                           <Box
                              sx={{
                                 display: "flex",
                                 bgcolor: "#F0F0F1",
                                 py: 0.6,
                                 px: 2,
                                 borderRadius: 50,
                                 border: 1,
                                 borderColor: "#E4E6E8",
                                 gap: 0.5,
                                 "&:hover": { bgcolor: "#E4E6E8", borderColor: "#F0F0F1" },
                                 cursor: "pointer",
                              }}
                              onClick={() => setOpen(true)}
                           >
                              <Typography noWrap fontSize={10} color={"#868FA0"}>
                                 Subir archivo
                              </Typography>
                              <AttachFileIcon sx={{ transform: "rotate(45deg)", color: "#868FA0", fontSize: 15 }} />
                           </Box>
                        )
                     ) : (
                        <>
                           <Typography fontSize={12}>{getDateFromISODate(props.file.createdAt)}</Typography>
                           <IconButton onClick={() => setOpen(true)}>
                              <AttachFileIcon />
                           </IconButton>
                        </>
                     )}
                  </Box>
               </Box>
            ) : (
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "space-between",
                     width: "100%",
                     px: 1,
                  }}
               >
                  <Typography fontSize={12}>{props.fileName}</Typography>
                  {props.notDisplaySize ? (
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           columnGap: 2,
                           minHeight: "40px",
                        }}
                     >
                        <Box
                           sx={{
                              display: "flex",
                              bgcolor: "#F0F0F1",
                              py: 0.6,
                              px: 2,
                              borderRadius: 50,
                              border: 1,
                              borderColor: "#E4E6E8",
                              gap: 0.5,
                              "&:hover": { bgcolor: "#E4E6E8", borderColor: "#F0F0F1" },
                              cursor: "pointer",
                           }}
                           onClick={() => setOpen(true)}
                        >
                           <Typography noWrap fontSize={10} color={"#868FA0"}>
                              Subir archivo
                           </Typography>
                           <AttachFileIcon sx={{ transform: "rotate(45deg)", color: "#868FA0", fontSize: 15 }} />
                        </Box>
                     </Box>
                  ) : (
                     <Grid
                        item
                        xs={3}
                        sx={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: 2 }}
                     >
                        <Typography fontSize={12}>subir nuevo</Typography>
                        <IconButton onClick={() => setOpen(true)}>
                           <AttachFileIcon />
                        </IconButton>
                     </Grid>
                  )}
               </Box>
            )}
         </Box>

         <ModalAddFilesComponent
            open={open}
            setOpen={setOpen}
            file={props.file ?? null}
            fileName={props.fileNameToUpload}
            id={props.id}
            setSeed={props.setSeed}
            userId={props.userId}
         />
      </>
   );
};
