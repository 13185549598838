import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { NotificationDocumentsBar } from "../../components/notificationsDocumentsBar";
// MUI Imports
import {
   Box,
   Typography,
   Grid,
   Button,
   Container,
   Accordion,
   AccordionSummary,
   AccordionDetails,
   CircularProgress,
   Skeleton,
   Stack,
} from "@mui/material";
// MUI Icons
import { ComplainerDetailsModal } from "../../components/ComplainerDetailsModal";
import { ComplaintProccessStepper } from "../../components/stepper/ComplaintProccessStepper";
import { ComplainantAndWitnessDetailsModal } from "../../components/ComplainantAndWitnessDetailsModal";
import { ComplaintFileRecord } from "../../components/ComplaintInfo/ComplaintFileRecord";
import { ComplaintRelevantInfo } from "../../components/ComplaintInfo/ComplaintRelevantInfo";
import { capitalizeFirstLetter, getDateFromISODate } from "../../const/globalConst";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";
import { UserContext } from "../../context/userContext";
import VerifyUserSession from "../../hooks/verifyUserSession";
import { ClosingFormComplaint } from "../../components/ClosingFormComplaint/ClosingFormComplaint";

interface ComplaintInfoScreenProps {
   includeStepper: boolean;
}

export const ComplaintInfoScreen = (props: ComplaintInfoScreenProps) => {
   VerifyUserSession();
   const { reportNumber } = useParams();
   const { getComplaint, complaint, isLoading } = useContext(ComplaintStepperContext);
   const { user } = useContext(UserContext);
   const [openComplainerModal, setOpenComplainerModal] = useState(false);
   const [openOffenderModal, setOpenOffenderModal] = useState(false);
   const [openWitnessModal, setOpenWitnessModal] = useState(false);

   useEffect(() => {
      getComplaint(reportNumber);
      // eslint-disable-next-line
   }, [reportNumber]);

   function checkComplaintPermission() {
      if (user.role.includes("Auditor") || user.role.includes("Director")) {
         if (!complaint.competence) return false;
         if (complaint.competence.competent !== user.id) return false;
      }
      return true;
   }
   const AditionalInformationGrid = () => {
      return (
         <Grid container item xs={12} spacing={1}>
            <Grid item xs={4}>
               <Box fontWeight={600}>Fecha del incidente</Box>
               {isLoading ? (
                  <Box sx={{ pr: 2 }}>
                     <Skeleton variant="rectangular" />
                  </Box>
               ) : (
                  <Box display="flex">{getDateFromISODate(complaint.date)}</Box>
               )}
            </Grid>
            <Grid item xs={4}>
               <Box fontWeight={600}>{complaint.sentDate ? "Fecha de envío" : "Fecha de rechazo"}</Box>
               {isLoading ? (
                  <Box sx={{ pr: 2 }}>
                     <Skeleton variant="rectangular" />
                  </Box>
               ) : (
                  <Box>
                     {complaint.status === "improcedente"
                        ? getDateFromISODate(complaint.rejectedDate)
                        : complaint.sentDate
                        ? getDateFromISODate(complaint.sentDate)
                        : ""}
                  </Box>
               )}
            </Grid>
            <Grid item xs={4}>
               <Box fontWeight={600}>Lugar del incidente</Box>
               {isLoading ? (
                  <Box sx={{ pr: 2 }}>
                     <Skeleton variant="rectangular" />
                  </Box>
               ) : (
                  <Box>{complaint.specificPlace}</Box>
               )}
            </Grid>
            <Grid item xs={4}>
               <Box fontWeight={600}>
                  {(complaint.type || "denuncia") !== "denuncia" ? "Interpositor" : "Denunciante"}
               </Box>
               {isLoading ? (
                  <Box sx={{ pr: 2 }}>
                     <Skeleton variant="rectangular" />
                  </Box>
               ) : (
                  <Box>{complaint.complainerType}</Box>
               )}
            </Grid>
            <Grid item xs={4}>
               <Typography fontWeight={600}>
                  {`${capitalizeFirstLetter(complaint.type || "denuncia")} Anónima`}
               </Typography>
               {isLoading ? (
                  <Box sx={{ pr: 2 }}>
                     <Skeleton variant="rectangular" />
                  </Box>
               ) : (
                  <Box display="flex" alignItems="center">
                     {complaint.complaintType === "anonima" ? (
                        <Typography>Si</Typography>
                     ) : (
                        <Button
                           variant="outlined"
                           size="small"
                           endIcon={<VisibilityIcon />}
                           onClick={() => setOpenComplainerModal(true)}
                        >
                           Ver
                        </Button>
                     )}
                  </Box>
               )}
            </Grid>
            <Grid item xs={4}>
               <Box fontWeight={600}>Resuelto</Box>
               {isLoading ? (
                  <Box sx={{ pr: 2 }}>
                     <Skeleton variant="rectangular" />
                  </Box>
               ) : (
                  <Box>{complaint.resolution ? "Si" : "No"}</Box>
               )}
            </Grid>
            <Grid item xs={12}>
               <Box fontWeight={600}>Medidas impuestas</Box>
               {isLoading ? (
                  <Box sx={{ pr: 2 }}>
                     <Skeleton variant="rectangular" />
                  </Box>
               ) : (
                  <Box>{complaint.resolution ? complaint.resolution : "No aplica"}</Box>
               )}
            </Grid>
         </Grid>
      );
   };

   return (
      <Box p={2}>
         <NotificationDocumentsBar
            head={
               <>
                  {isLoading ? (
                     <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h6">Denuncia</Typography>
                        <CircularProgress size={20} sx={{ ml: 2 }} />
                     </Box>
                  ) : (
                     <Typography variant="h6">
                        {complaint
                           ? `${capitalizeFirstLetter(complaint.type || "denuncia")} ${complaint.reportNumber}`
                           : "Error"}
                     </Typography>
                  )}
               </>
            }
            isScheduleAppointment={false}
            main={true}
         />
         {!isLoading ? (
            (complaint && (
               <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Container maxWidth="xl">
                     <Grid container spacing={2} sx={{ py: 2 }}>
                        {!complaint.intern &&
                        (complaint.status === "cerrada" || complaint.status === "improcedente") ? (
                           <Grid item xs={12}>
                              <Box sx={infoBox}>
                                 {complaint.status === "cerrada" ? (
                                    <Typography variant="h6">{`Esta ${
                                       complaint.type || "denuncia"
                                    } se encuentra cerrada.`}</Typography>
                                 ) : complaint.status === "improcedente" ? (
                                    <Typography variant="h6">{`Esta ${
                                       complaint.type || "denuncia"
                                    } fue rechazada.`}</Typography>
                                 ) : null}
                              </Box>
                           </Grid>
                        ) : complaint.intern ? (
                           <Grid item xs={12}>
                              <Box sx={infoBox}>
                                 {complaint.status === "cerrada" ? (
                                    <Typography variant="h6">{`Esta ${
                                       complaint.type || "denuncia"
                                    } se encuentra cerrada.`}</Typography>
                                 ) : complaint.status === "improcedente" ? (
                                    <Typography variant="h6">{`Esta ${
                                       complaint.type || "denuncia"
                                    } fue rechazada.`}</Typography>
                                 ) : (
                                    <Typography variant="h6">{`Esta ${
                                       complaint.type || "denuncia"
                                    } es gestionada por Lecosy.`}</Typography>
                                 )}
                              </Box>
                           </Grid>
                        ) : !checkComplaintPermission() ? (
                           <Grid item xs={12}>
                              <Box sx={infoBox}>
                                 <Typography variant="h6">{`No tiene el permiso de gestionar la ${
                                    complaint.type || "denuncia"
                                 }.`}</Typography>
                              </Box>
                           </Grid>
                        ) : null}
                        {props.includeStepper && !complaint.intern && checkComplaintPermission() ? (
                           <>
                              <Grid item xs={12} md={12} lg={12}>
                                 {isLoading ? (
                                    <Box sx={infoBox}>
                                       <CircularProgress size={"1.5em"} sx={{ mr: 2 }} />
                                       <Typography>Espere un momento, por favor.</Typography>
                                    </Box>
                                 ) : (
                                    <ClosingFormComplaint />
                                 )}
                              </Grid>
                              <Grid item xs={12} md={12} lg={8}>
                                 {isLoading ? (
                                    <Box sx={infoBox}>
                                       <CircularProgress size={"1.5em"} sx={{ mr: 2 }} />
                                       <Typography>Espere un momento, por favor.</Typography>
                                    </Box>
                                 ) : (
                                    <ComplaintProccessStepper />
                                 )}
                              </Grid>
                              <Grid item xs={12} lg={props.includeStepper ? 4 : 12}>
                                 <ComplaintRelevantInfo
                                    setOpenWitnessModal={setOpenWitnessModal}
                                    setOpenOffenderModal={setOpenOffenderModal}
                                    includeStepper={complaint.intern ? false : props.includeStepper}
                                 />
                              </Grid>
                           </>
                        ) : (
                           <></>
                        )}
                        <Grid item xs={12} sx={{ display: "grid", gridAutoColumns: "1fr", gridAutoFlow: "column" }}>
                           <Stack direction={{ xs: "column", lg: "row" }} spacing={2} sx={{ display: "flex" }}>
                              <Box flex={1}>
                                 <Accordion square sx={{ boxShadow: 2 }}>
                                    <AccordionSummary
                                       expandIcon={<KeyboardArrowDownIcon />}
                                       aria-controls="panel1a-content"
                                       id="panel1a-header"
                                    >
                                       <Typography variant="h6">Documentación del expediente</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ minHeight: "100%" }}>
                                       {isLoading ? (
                                          <Box sx={{ display: "flex", justifyContent: "center" }}>
                                             <CircularProgress />
                                          </Box>
                                       ) : (
                                          <ComplaintFileRecord includeStepper={props.includeStepper} clousure={false} />
                                       )}
                                    </AccordionDetails>
                                 </Accordion>
                              </Box>
                              <Box flex={1}>
                                 <Accordion square sx={{ boxShadow: 2 }}>
                                    <AccordionSummary
                                       expandIcon={<KeyboardArrowDownIcon />}
                                       aria-controls="panel2a-content"
                                       id="panel2a-header"
                                       sx={{ minHeight: "100%" }}
                                    >
                                       <Typography variant="h6">Información adicional</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ minHeight: "100%" }}>
                                       <AditionalInformationGrid />
                                    </AccordionDetails>
                                 </Accordion>
                              </Box>
                           </Stack>
                        </Grid>
                     </Grid>
                  </Container>
                  {complaint.complaintType === "anonima" ? (
                     <></>
                  ) : (
                     <ComplainerDetailsModal open={openComplainerModal} setOpen={setOpenComplainerModal} />
                  )}
                  <ComplainantAndWitnessDetailsModal
                     open={openOffenderModal}
                     setOpen={setOpenOffenderModal}
                     info={complaint.offenderDetails}
                     isWitness={false}
                     onConfirm={() => {}}
                  />
                  <ComplainantAndWitnessDetailsModal
                     open={openWitnessModal}
                     setOpen={setOpenWitnessModal}
                     info={complaint.witnesses}
                     isWitness={true}
                     onConfirm={() => {}}
                  />
               </Box>
            )) || (
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     width: "100%",
                  }}
               >
                  <Typography textAlign={"center"} sx={{ color: "#162c44", fontWeight: "bold" }}>
                     Error al cargar la denuncia
                  </Typography>
               </Box>
            )
         ) : (
            <></>
         )}
      </Box>
   );
};

const infoBox = {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   bgcolor: "white",
   boxShadow: 2,
   p: 2,
};
